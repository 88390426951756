import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import ApiClient from '../../util/ApiClient';
import update from 'immutability-helper';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import '../../css/userHome.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { getParameterByName } from '../../util/queryString';
import Dropdown from 'react-bootstrap/Dropdown'
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Formik } from "formik";
import config from '../../config';
import historyScroll from '../../assets/62867-scroll-icon.png';

function AddGetValueFromOtherElementModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Get Value From Other Element</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Other Element Xpath</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.GetValueFromOtherElementOtherElementXpath)}
                                    value={props.tempGetValueFromOtherElementData.getValueFromOtherElement.otherElementXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Alternative Other Element Xpath</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.GetValueFromOtherElementAlternativeElementXpath)}
                                    value={props.tempGetValueFromOtherElementData.getValueFromOtherElement.alternativeElementXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Increase Value By</Form.Label>
                                <Form.Control type="number" onChange={props.onDataChange(props.GetValueFromOtherElementIncreaseValueBy)}
                                    value={props.tempGetValueFromOtherElementData.getValueFromOtherElement.increaseValueBy} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Increase Value By Alternative</Form.Label>
                                <Form.Control type="number" onChange={props.onDataChange(props.GetValueFromOtherElementIncreaseValueByAlternative)}
                                    value={props.tempGetValueFromOtherElementData.getValueFromOtherElement.increaseValueByAlternative} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Element Type</Form.Label>
                                <Form.Select aria-label="Source Element Type" onChange={props.onDataChange(props.GetValueFromOtherElementElementType)} value={props.tempGetValueFromOtherElementData.getValueFromOtherElement.elementType}>
                                    <option>Select a Element Type</option>
                                    <option value={0}>Label</option>
                                    <option value={1}>Text Field</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="isTimeValue">
                                <Form.Check type="checkbox" label="Time Value" onChange={props.onDataChange(props.GetValueFromOtherElementIsTimeValue)} checked={props.tempGetValueFromOtherElementData.getValueFromOtherElement.isTimeValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="isAmPmFormat">
                                <Form.Check type="checkbox" label="24 Hour Format" onChange={props.onDataChange(props.GetValueFromOtherElementIsAmPmFormat)} checked={props.tempGetValueFromOtherElementData.getValueFromOtherElement.isAmPmFormat} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function AddModalCloserModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Modal Closer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>ID</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.ModalCloserElementId)} value={props.tempModalCloserData.modalCloser.elementId} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlClass">
                                <Form.Label>Class</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.ModalCloserClass)} value={props.tempModalCloserData.modalCloser.class} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlXpath">
                                <Form.Label>Xpath</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.ModalCloserXpath)} value={props.tempModalCloserData.modalCloser.xPath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="closeBefore">
                                <Form.Check type="checkbox" label="Close Before" onChange={props.onDataChange(props.ModalCloserCloseBefore)} checked={props.tempModalCloserData.modalCloser.closeBefore} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="closeAfter">
                                <Form.Check type="checkbox" label="Close After" onChange={props.onDataChange(props.ModalCloserCloseAfter)} checked={props.tempModalCloserData.modalCloser.closeAfter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="clickDismiss">
                                <Form.Check type="checkbox" label="Click to Dismiss" onChange={props.onDataChange(props.ModalCloserClickDismiss)} checked={props.tempModalCloserData.modalCloser.clickDismiss} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="isPresent">
                                <Form.Check type="checkbox" label="Is Currently Showing (Add Then Script if showing)" onChange={props.onDataChange(props.ModalCloserIsPresent)} checked={props.tempModalCloserData.modalCloser.isPresent} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitUntilNotVisible">
                                <Form.Check type="checkbox" label="Wait Until Not Visible" onChange={props.onDataChange(props.ModalCloserWaitUntilNotVisible)} checked={props.tempModalCloserData.modalCloser.waitUntilNotVisible} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitUntilNotVisibleTimeLength">
                                <Form.Control type="number" placeholder="Wait Until Not Visible Timeout (Default 60 seconds if turned on)" onChange={props.onDataChange(props.ModalCloserWaitUntilNotVisibleTimeLength)} value={props.tempModalCloserData.modalCloser.waitUntilNotVisibleTimeLength} block />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="removeWaitTimeLength">
                                <Form.Control type="number" placeholder="Suspend After Modal Removal" onChange={props.onDataChange(props.ModalCloserRemoveWaitTimeLength)} value={props.tempModalCloserData.modalCloser.removeWaitTimeLength} block />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="thenScript">
                                <Form.Control type="text" placeholder="Post Script" onChange={props.onDataChange(props.ModalCloserThenScript)} value={props.tempModalCloserData.modalCloser.thenScript} block />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}

function AddCaptchaSolverModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Captcha Solver</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Label>Captcha Version:</Form.Label>
                            <select onChange={props.onDataChange(props.CaptchaSolverVersion)}>
                                <option selected={props.tempCaptchaSolverData.captchaSolver.version == 0} value="V1">V1</option>
                                <option selected={props.tempCaptchaSolverData.captchaSolver.version == 1} value="V2">V2</option>
                                <option selected={props.tempCaptchaSolverData.captchaSolver.version == 2} value="V3">V3</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>Captcha Type:</Form.Label>
                            <select aria-label="Captcha Type" onChange={props.onDataChange(props.CaptchaSolverType)}>
                                <option selected={props.tempCaptchaSolverData.captchaSolver.captchaType == 4} value="4">4</option>
                                <option selected={props.tempCaptchaSolverData.captchaSolver.captchaType == 5} value="5">5</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="runPostCallback">
                                <Form.Check type="checkbox" label="Run Post Callback" onChange={props.onDataChange(props.CaptchaSolverRunPoseCallback)} checked={props.tempCaptchaSolverData.captchaSolver.callBack} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="hCaptcha">
                                <Form.Check type="checkbox" label="H Captcha" onChange={props.onDataChange(props.CaptchaSolverIsHCaptcha)} checked={props.tempCaptchaSolverData.captchaSolver.isHCaptcha} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="useCaptchaExtension">
                                <Form.Check type="checkbox" label="Use Captcha Extension" onChange={props.onDataChange(props.CaptchaSolverUseCaptchaExtension)} checked={props.tempCaptchaSolverData.captchaSolver.useCaptchaExtension} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="useCaptchaExtensionThenClickButton">
                                <Form.Check type="checkbox" label="Use Captcha Extension Then Click Button" onChange={props.onDataChange(props.CaptchaSolverUseCaptchaExtensionThenClickButton)} checked={props.tempCaptchaSolverData.captchaSolver.useCaptchaExtensionThenClickButton} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="foreteesCaptchaSolver">
                                <Form.Check type="checkbox" label="Solve Foretees Captcha" onChange={props.onDataChange(props.CaptchaSolverForeteesCaptchaSolver)} checked={props.tempCaptchaSolverData.captchaSolver.foreteesCaptchaSolver} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="scriptKey">
                                <Form.Check type="checkbox" label="Script Key" onChange={props.onDataChange(props.CaptchaSolverScriptKey)} checked={props.tempCaptchaSolverData.captchaSolver.isScriptKey} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="geeTest">
                                <Form.Check type="checkbox" label="Gee Test" onChange={props.onDataChange(props.CaptchaSolverGeeTest)} checked={props.tempCaptchaSolverData.captchaSolver.geeTest} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="runHardPostCallback">
                                <Form.Check type="checkbox" label="Run Hard Post Callback" onChange={props.onDataChange(props.CaptchaSolverRunHardPostCallback)} checked={props.tempCaptchaSolverData.captchaSolver.hardCallBack} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkIfCaptchaExist">
                                <Form.Check type="checkbox" label="Check If Captcha Exist" onChange={props.onDataChange(props.CaptchaSolverCheckIfCaptchaExist)} checked={props.tempCaptchaSolverData.captchaSolver.captchaExistCheck} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkExtractKeyFromSrcUrl">
                                <Form.Check type="checkbox" label="Extract Key From Src URL" onChange={props.onDataChange(props.CaptchaSolverExtractKeyFromSrcUrl)} checked={props.tempCaptchaSolverData.captchaSolver.extractKeyFromSrcUrl} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkOverrideCallback">
                                <Form.Check type="checkbox" label="Override Callback" onChange={props.onDataChange(props.CaptchaSolverOverrideCallback)} checked={props.tempCaptchaSolverData.captchaSolver.overrideCallback} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkV1Base64Image">
                                <Form.Check type="checkbox" label="Base 64 Image (Don't check if src is URL)" onChange={props.onDataChange(props.CaptchaSolverV1Base64Image)} checked={props.tempCaptchaSolverData.captchaSolver.v1Base64Image} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkVerifyCaptcha">
                                <Form.Check type="checkbox" label="Verify Captcha" onChange={props.onDataChange(props.CaptchaSolverVerifyCaptcha)} checked={props.tempCaptchaSolverData.captchaSolver.verifyCaptcha} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="refreshBeforeSolving">
                                <Form.Check type="checkbox" label="Refresh Page Before Solving" onChange={props.onDataChange(props.CaptchaSolverRefreshBeforeSolving)} checked={props.tempCaptchaSolverData.captchaSolver.refreshBeforeSolving} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="captchaTokenFunction">
                                <Form.Label>Custom Callback Function Name</Form.Label>
                                <Form.Control type="text" placeholder="Function Name" onChange={props.onDataChange(props.CaptchaSolverTokenFunction)} value={props.tempCaptchaSolverData.captchaSolver.tokenFunction} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="captchaExtensionXpath">
                                    <Form.Label>Captcha Extension Xpath</Form.Label>
                                    <Form.Control type="text" placeholder="Xpath to Extension" onChange={props.onDataChange(props.CaptchaSolverCaptchaExtensionXpath)} value={props.tempCaptchaSolverData.captchaSolver.captchaExtensionXpath} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="xpathSiteKey">
                                <Form.Label>Custom Xpath Site Key</Form.Label>
                                <Form.Control type="text" placeholder="Site Key Xpath" onChange={props.onDataChange(props.CaptchaSolverXpathSiteKey)} value={props.tempCaptchaSolverData.captchaSolver.xPathCaptchaSiteKey} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="captchaResponseXpath">
                                <Form.Label>Custom Captcha Response Xpath</Form.Label>
                                <Form.Control type="text" placeholder="Captcha Response Xpath" onChange={props.onDataChange(props.CaptchaSolverResponseXpath)} value={props.tempCaptchaSolverData.captchaSolver.captchaResponseXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="captchaV1ElementId">
                                <Form.Label>V1 Image Element Xpath</Form.Label>
                                <Form.Control type="text" placeholder="V1 Image Element (must contain src)" onChange={props.onDataChange(props.CaptchaSolverV1ElementId)} value={props.tempCaptchaSolverData.captchaSolver.v1CaptchaElementId} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="captchaV1ElementInputFieldXpath">
                                <Form.Label>V1 Input Field Xpath</Form.Label>
                                <Form.Control type="text" placeholder="Text Field Where Image Code Goes" onChange={props.onDataChange(props.CaptchaSolverV1ElementInputFieldXpath)} value={props.tempCaptchaSolverData.captchaSolver.v1ElementInputFieldXPath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="captchaV1VerifySkipCount">
                                <Form.Label>Verify Captcha Code Worked</Form.Label>
                                <Form.Control type="number" placeholder="Number of controls to skip to verify" onChange={props.onDataChange(props.CaptchaSolverV1VerifySkipCount)} value={props.tempCaptchaSolverData.captchaSolver.v1VerifySkipCount} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="captchaExtensionLoadTimeDelay">
                                <Form.Label>Captcha Extension Load Delay (Milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Delay in Milliseconds" onChange={props.onDataChange(props.CaptchaSolverCaptchaExtensionLoadTimeDelay)} value={props.tempCaptchaSolverData.captchaSolver.captchaExtensionLoadTimeDelay} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function IFrameSwitcherModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>IFrame Switcher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>ID</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.IFrameSwitcherElementId)} value={props.tempIframeSwitcher.frameSwitch.elementId} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlClass">
                                <Form.Label>Class</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.IFrameSwitcherClass)} value={props.tempIframeSwitcher.frameSwitch.class} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlXpath">
                                <Form.Label>Xpath</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.IFrameSwitcherXpath)} value={props.tempIframeSwitcher.frameSwitch.xPath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="windowClickAndSwitch">
                                <Form.Label>Which Window (Integer)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 1, 2, 3" onChange={props.onDataChange(props.IFrameSwitcherWhichWindow)} value={props.tempIframeSwitcher.frameSwitch.whichWindow} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="pauseBeforeSwitch">
                                <Form.Label>Pause Before Switch (In Milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 3500" onChange={props.onDataChange(props.IFrameSwitcherPauseBeforeSwitch)} value={props.tempIframeSwitcher.frameSwitch.pauseBeforeSwitch} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="clickAndSwitch">
                                <Form.Check type="checkbox" label="Click & Switch" onChange={props.onDataChange(props.IFrameSwitcherClickAndSwitch)} checked={props.tempIframeSwitcher.frameSwitch.clickSwitch} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipIFrameSwitchBack">
                                <Form.Check type="checkbox" label="Skip IFrame Switch Back" onChange={props.onDataChange(props.IFrameSwitcherSkipSwitchBack)} checked={props.tempIframeSwitcher.frameSwitch.skipIFrameSwitchBack} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="includeDynamicStepCount">
                                <Form.Check type="checkbox" label="Include Dynamic Step Count" onChange={props.onDataChange(props.IFrameSwitcherIncludeDynamicStepCount)} checked={props.tempIframeSwitcher.frameSwitch.includeDynamicStepCount} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="excludeExtraWindowCount">
                                <Form.Check type="checkbox" label="Exclude Extra Count" onChange={props.onDataChange(props.IFrameSwitcherExcludeExtraWindowCount)} checked={props.tempIframeSwitcher.frameSwitch.excludeExtraWindowCount} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="ignoreFail">
                                <Form.Check type="checkbox" label="Ignore Fail" onChange={props.onDataChange(props.IFrameSwitcherIgnoreFail)} checked={props.tempIframeSwitcher.frameSwitch.ignoreFail} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function InfiniteScrollModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Infinite Scroll</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="sectionHeightScript">
                                <Form.Label>Section Height Script</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.InfiniteScrollSectionHeightScript)} value={props.tempInfiniteScroll.infiniteScroll.sectionHeightScript} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="scrollScript">
                                <Form.Label>Scroll Script</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.InfiniteScrollScrollScript)} value={props.tempInfiniteScroll.infiniteScroll.scrollScript} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="scrollSection">
                                <Form.Label>Scroll Section</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.InfiniteScrollScrollSection)} value={props.tempInfiniteScroll.infiniteScroll.scrollSection} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="scrollToTopScript">
                                <Form.Label>Scroll Back to Top Script</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.InfiniteScrollScrollToTopScript)} value={props.tempInfiniteScroll.infiniteScroll.scrollToTopScript} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="itemsToHandlePerPage">
                                <Form.Label>Items to Handle Per Page</Form.Label>
                                <Form.Control type="number" placeholder="Example: 50" onChange={props.onDataChange(props.InfiniteScrollItemsToHandlePerPage)} value={props.tempInfiniteScroll.infiniteScroll.itemsToHandlePerPage} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitTimeBetweenScroll">
                                <Form.Label>Wait Between Scroll (Milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.InfiniteScrollWaitTimeBetweenScroll)} value={props.tempInfiniteScroll.infiniteScroll.waitTimeBetweenScroll} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitUntilNotVisible">
                                <Form.Label>Wait Until Element Not Visible (Xpath)</Form.Label>
                                <Form.Control type="text" placeholder="//*[@role='loading-dialog'] will wait 60 seconds" onChange={props.onDataChange(props.InfiniteScrollWaitUntilNotVisible)} value={props.tempInfiniteScroll.infiniteScroll.waitUntilNotVisible} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipFirstItems">
                                <Form.Label>Skip First</Form.Label>
                                <Form.Control type="number" placeholder="Example: 20" onChange={props.onDataChange(props.InfiniteScrollSkipFirstItems)} value={props.tempInfiniteScroll.infiniteScroll.skipFirstItems} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="openItemsInTab">
                                <Form.Check type="checkbox" label="Open Each Item in New Tab" onChange={props.onDataChange(props.InfiniteScrollOpenItemsInTab)} checked={props.tempInfiniteScroll.infiniteScroll.openItemsInTab} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="preFillNewTabUrl">
                                <Form.Label>Pre Fill URL</Form.Label>
                                <Form.Control type="text" placeholder="Example: https://instagram.com" onChange={props.onDataChange(props.InfiniteScrollPrefillNewTabUrl)} value={props.tempInfiniteScroll.infiniteScroll.preFillNewTabUrl} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function ListSelectorConditionModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Conditional List Selector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="itemCounterGreaterThan">
                                <Form.Label>If List Selector Item Count Greater Than</Form.Label>
                                <Form.Control type="number" placeholder="Example: 1" onChange={props.onDataChange(props.ListSelectorConditionItemCounterGreaterThan)} value={props.tempListSelectorCondition.listSelectorCondition.itemCounterGreaterThan} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="textContentsContains">
                                <Form.Label>Text Content Contains</Form.Label>
                                <Form.Control type="text" placeholder="Example: No longer available%OR%Mentioned you in their story" onChange={props.onDataChange(props.ListSelectorConditionTextContentsContains)} value={props.tempListSelectorCondition.listSelectorCondition.textContentsContains} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="textContentsDoesNotContains">
                                <Form.Label>Text Content Does Not Contains</Form.Label>
                                <Form.Control type="text" placeholder="Example: Wild Berry%OR%Nature's" onChange={props.onDataChange(props.ListSelectorConditionTextContentsDoesNotContains)} value={props.tempListSelectorCondition.listSelectorCondition.textContentsDoesNotContains} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitUntilElementNotPresentXpath">
                                <Form.Label>Wait Until Element Not Visible After Refresh Clicked (Xpath)</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.ListSelectorConditionWaitUntilElementNotPresentXpath)} value={props.tempListSelectorCondition.listSelectorCondition.waitUntilElementNotPresentXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitUntilElementNotPresentTimeout">
                                <Form.Label>Wait Until Element Not Visible After Refresh Timeout (Seconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 4" onChange={props.onDataChange(props.ListSelectorConditionWaitUntilElementNotPresentTimeout)} value={props.tempListSelectorCondition.listSelectorCondition.waitUntilElementNotPresentTimeout} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="goRepeatStep">
                                <Form.Label>Repeat Step Number (If True)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 12" onChange={props.onDataChange(props.ListSelectorConditionGoRepeatStep)} value={props.tempListSelectorCondition.listSelectorCondition.goRepeatStep} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="userListedItemsMatchXpath">
                                <Form.Check type="checkbox" label="User Listed Items Match by Text" onChange={props.onDataChange(props.ListSelectorConditionUserListedItemsMatchXpath)} checked={props.tempListSelectorCondition.listSelectorCondition.userListedItemsMatchXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="combineMultipleTextElement">
                                <Form.Check type="checkbox" label="Combine All Elements Into One Text" onChange={props.onDataChange(props.ListSelectorConditionCombineMultipleTextElement)} checked={props.tempListSelectorCondition.listSelectorCondition.combineMultipleTextElement} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function ElementConditionModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Conditional Element Selector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="urlContains">
                                <Form.Label>If URL Contains</Form.Label>
                                <Form.Control type="text" placeholder="Example: /building" onChange={props.onDataChange(props.ElementConditionUrlContains)} value={props.tempElementCondition.elementCondition.urlContains} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="xpathElementExist">
                                <Form.Label>If Xpath Exist</Form.Label>
                                <Form.Control type="text" placeholder="Example: //*[@class='button']" onChange={props.onDataChange(props.ElementConditionXpathElementExist)} value={props.tempElementCondition.elementCondition.xpathElementExist} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="valueDoesNotMatch">
                                <Form.Label>Current Value Does Not Match</Form.Label>
                                <Form.Control type="text" placeholder="Value to Compare" onChange={props.onDataChange(props.ElementConditionValueDoesNotMatch)} value={props.tempElementCondition.elementCondition.valueDoesNotMatch} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="previousValueDoesNotMatch">
                                <Form.Label>Previous Value Does Not Match Current</Form.Label>
                                <Form.Control type="text" placeholder="Value to Compare" onChange={props.onDataChange(props.ElementConditionPreviousValueDoesNotMatch)} value={props.tempElementCondition.elementCondition.previousValueDoesNotMatch} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="apiDataValues">
                                <Form.Label>Replace %USERVALUE% Key with API Values (Optional)</Form.Label>
                                <Form.Control type="text" placeholder="Example: Last_Name%Address" onChange={props.onDataChange(props.ElementConditionAPIDataValues)} value={props.tempElementCondition.elementCondition.apiDataValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="scripts">
                                <Form.Label>Run Script (If True)</Form.Label>
                                <Form.Control as="textarea" placeholder="Example: window.location = '/template'%THEN%alert('test')" rows={2} onChange={props.onDataChange(props.ElementConditionScripts)} value={props.tempElementCondition.elementCondition.scripts} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipSteps">
                                <Form.Label>Skip Steps (If True)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 6" onChange={props.onDataChange(props.ElementConditionSkipSteps)} value={props.tempElementCondition.elementCondition.skipSteps} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="repeatStepIfFail">
                                <Form.Label>Repeat Step (If Fail)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 2" onChange={props.onDataChange(props.ElementConditionRepeatStepIfFail)} value={props.tempElementCondition.elementCondition.repeatStepIfFail} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipDataLibraryItem">
                                <Form.Check type="checkbox" label="Skip Data Library Item (If True)" onChange={props.onDataChange(props.ElementConditionSkipDataLibraryItem)} checked={props.tempElementCondition.elementCondition.skipDataLibraryItem} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="removeDynamicStep">
                                <Form.Check type="checkbox" label="Remove Dynamic Step Counter (If True)" onChange={props.onDataChange(props.ElementConditionRemoveDynamicStep)} checked={props.tempElementCondition.elementCondition.removeDynamicStep} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="continueIfElementChanged">
                                <Form.Check type="checkbox" label="Continue If Element Text Value Changed (Track ID)" onChange={props.onDataChange(props.ElementConditionContinueIfElementChanged)} checked={props.tempElementCondition.elementCondition.continueIfElementChanged} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function AddTimeoutWaitTimeModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Timeout Wait Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Time (Seconds)</Form.Label>
                                <Form.Control type="number" onChange={props.onDataChange(props.AddTimeoutWaitTime)} value={props.tempTimeoutWaitTime} placeholder="Example: 14, 30, 60" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function AddDaysToCalendarModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Add Days to Calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Days to Add</Form.Label>
                                <Form.Control type="number" onChange={props.onDataChange(props.AddDaysToCalendar)} value={props.tempAddDaysToCalendar} placeholder="Example: -2, 3, 60" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}

function StepTargetUTCStartTimeModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Step Target Start Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>UTC Start Time</Form.Label>
                                <Form.Control type="text" placeholder="Example: 11:00:03" onChange={props.onDataChange(props.StepTargetUTCStartTime)} value={props.tempStepTargetStartTime} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}

function LurkBeforeModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Lurk Before Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Lurk Timeout (In milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.LurkBefore)} value={props.tempLurkBefore} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function LurkUntilModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Lurk Until Step</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Number of Steps To Manually Complete</Form.Label>
                                <Form.Control type="number" placeholder="Example: 3" onChange={props.onDataChange(props.LurkUntil)} value={props.tempLurkUntil} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function ClickAndLurkModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Lurk and Click Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Click and Lurk Timeout (In milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.ClickAndLurk)} value={props.tempClickAndLurk} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function GoRepeatStepModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Navigate to Step</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Which Step To Repeat?</Form.Label>
                                <Form.Control type="number" placeholder="Example: 12 or 3" onChange={props.onDataChange(props.GoRepeatStep)} value={props.tempGoRepeatStep} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}

function PauseBeforeActionModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Pause Before Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Pause Time (In milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.PauseBeforeAction)} value={props.tempPauseBeforeAction} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}

function PauseAfterActionModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Pause After Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Pause Time (In milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.PauseAfterAction)} value={props.tempPauseAfterAction} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function ScrollToBottomScriptModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Scroll To Bottom Script</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>XPath</Form.Label>
                                <Form.Control type="text" placeholder="Example: //*[@class='scroll-area']" onChange={props.onDataChange(props.ScrollToBottomScript)} value={props.tempScrollToBottomScript} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function SetDataDateOnCalendarModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Set Data Date on Calendar Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="setDataDateOnCalendar">
                                <Form.Label>Which Calendar (Must match label)</Form.Label>
                                <Form.Control type="text" placeholder="Example: Booking Date" onChange={props.onDataChange(props.SetDataDateOnCalendar)} value={props.tempSetDataDateOnCalendar} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function EmptyListLabelXpathModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Empty List Xpath Checker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Empty List Label Xpath</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.EmptyListLabelXpath)} value={props.tempEmptyListLabelXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function RedirectAfterUrlModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Redirect After Url</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Redirect After Url</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.RedirectAfterUrl)} value={props.tempRedirectAfterUrl} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function TwoClickSelectorModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Two Click Selector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Xpath:</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={props.onDataChange(props.TwoClickSelector)} value={props.tempTwoClickSelector.twoClickSelector.xPath} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function TabSplitModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Split & Open Tabs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Xpath to Pagination Max Number:</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={props.onDataChange(props.TabSplit)} value={props.tempTabSplit.tabSplit.numberOfTabsTextXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function TwoFormAuthenticationModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Two Form Authentication:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Label>Type:</Form.Label>
                            <select onChange={props.onDataChange(props.TwoFormAuthenticationType)}>
                                <option selected={props.tempTwoFormAuthentication.twoFormAuthentication.authenticationType == 0} value="ALLOWACCESS">Allow Access</option>
                                <option selected={props.tempTwoFormAuthentication.twoFormAuthentication.authenticationType == 1} value="CODE">Verification Code</option>
                                <option selected={props.tempTwoFormAuthentication.twoFormAuthentication.authenticationType == 2} value="REDIRECTURL">Redirect URL</option>
                                <option selected={props.tempTwoFormAuthentication.twoFormAuthentication.authenticationType == 3} value="CODE_SPLIT">Code Split</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Accept Xpath (Optional):</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={props.onDataChange(props.TwoFormAuthenticationAcceptPath)} value={props.tempTwoFormAuthentication.twoFormAuthentication.acceptXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Email Verify Instructions:</Form.Label>
                                <Form.Control type="email" placeholder="email@domain.com" onChange={props.onDataChange(props.TwoFormAuthenticationEmailConfirmationInstruction)} value={props.tempTwoFormAuthentication.twoFormAuthentication.emailConfirmationInstruction} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="requireAcceptClick">
                                <Form.Check type="checkbox" label="Require Accept Click" onChange={props.onDataChange(props.TwoFormAuthenticationRequireAcceptClick)} checked={props.tempTwoFormAuthentication.twoFormAuthentication.requireAcceptClick} />
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function ClickLabelHelperModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Click Label Helper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Xpath:</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={props.onDataChange(props.ClickLabelHelper)} value={props.tempClickLabelHelper.clickLabelHelper} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function ClickActionMultiplierCountModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Click Action Multiplier Count</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Multiplier:</Form.Label>
                                <Form.Control type="text" placeholder="Example: 6" onChange={props.onDataChange(props.ClickActionMultiplierCount)} value={props.tempClickActionMultiplierCount.clickActionMultiplierCount} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function APIDataLabelClickModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>API Data Label Click</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Data Keys (Seperate with %):</Form.Label>
                                <Form.Control type="text" placeholder="MONTH%YEAR" onChange={props.onDataChange(props.APIDataLabelClick)} value={props.tempAPIDataLabelClick.apiDataLabelClick} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function CreateDuplicateBot(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Copy Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Formik
                            initialValues={{
                                botName: "",
                                oGId: props.oGId,
                            }}
                            onSubmit={(values, actions) => {
                                ApiClient.post('bot/create/duplicate/' + props.userId, {
                                    oGId: props.oGId,
                                    newBotName: values.botName,
                                }).then((response) => {
                                    actions.setSubmitting(false);
                                    actions.setStatus('Success!');
                                    setTimeout(() => { props.onHide(); }, 1000);
                                    values.botName = '';
                                    if (response.status == 200) {

                                    }
                                }).catch((error) => {
                                    actions.setSubmitting(false);
                                    values.botName = '';
                                })


                            }}
                        >
                            {formik => (
                                <div>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group controlId="botName">
                                            <Form.Label>New Bot Name</Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.botName} type="text" placeholder="Bot Name" required />
                                            <span className={"text-muted"}>Copy '{props.mainBotDuplicateName}'</span>

                                        </Form.Group>

                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={12}>
                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Create Duplicate"}</Button>
                                                <span style={{ color: 'green' }}>{formik.status}</span>
                                            </Col>
                                        </Row>

                                    </Form>

                                </div>


                            )}
                        </Formik>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function ShareBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Send Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Formik
                            initialValues={{
                                emailAddress: "",
                                botId: props.masterBotId,
                            }}
                            onSubmit={(values, actions) => {
                                ApiClient.post('bot/share', {
                                    emailAddress: values.emailAddress,
                                    botId: values.botId,
                                }).then((response) => {
                                    actions.setSubmitting(false);
                                    if (response.status == 200) {
                                        values.emailAddress = '';
                                        actions.setStatus('Success!');
                                        setTimeout(() => { props.onHide(); }, 1000);

                                    }

                                }).catch((error) => {
                                    actions.setSubmitting(false);
                                    values.botName = '';
                                    actions.setErrors({ emailAddress: 'Email Address not found or invalid. Please try again later.' })
                                })
                            }}
                        >
                            {formik => (
                                <div>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Form.Group controlId="emailAddress">
                                            <Form.Label>Email Address (email must be registered on Bot-It):</Form.Label>
                                            <Form.Control autoComplete={"off"} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.emailAddress} type="email" placeholder="Email Address" required />
                                            <span className={"text-muted"}>You are sending '{props.mainBotDuplicateName}'</span><br />
                                            <span style={{ color: 'green' }}>{formik.status}</span>
                                            <span style={{ color: 'red' }}>{formik.errors.emailAddress}</span>
                                        </Form.Group>

                                        <Row style={{ textAlign: 'center' }}>
                                            <Col lg={12}>
                                                <Button variant="primary" type="submit" disabled={formik.isSubmitting}> {formik.isSubmitting ? "Please wait..." : "Send"}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function PublicShareBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Share Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Label>Share Url:</Form.Label>
                        <Form.Control autoComplete={"off"} disabled={true} type="email" placeholder="Share URL" value={props.shareUrl} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Button variant='secondary' onClick={props.onCopyButtonClick}>{props.copyButtonText} </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function AssignBotModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Assign Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID" style={{ textAlign: "center" }}>
                            <Form.Label>Assign To:</Form.Label>
                            <Dropdown onSelect={props.assignToClick}>
                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                    {props.selectedEngineer ? props.selectedEngineer : "--Select--"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.engineers && props.engineers.map((item, i) => {
                                        return (<Dropdown.Item onClick={props.onAssignItemClick} eventKey={item.id}>{item.name}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID" style={{ textAlign: "center" }}>
                            <Form.Label>Bot Priority:</Form.Label>
                            <Dropdown onSelect={props.botTierClick}>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    {props.selectedTier ? props.selectedTier : "Options"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.tiers && props.tiers.map((item, i) => {
                                        return (<Dropdown.Item eventKey={item}>{item}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID">
                            <Form.Label>Due Date:</Form.Label>
                            <Form.Control type="date" onChange={props.onAssignBotDueDate} value={props.assignDueDate} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID">
                            <Form.Label>Message:</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={props.onAssignBotMessageChange} value={props.assignMessage} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={props.disableSaveButton} variant="primary" onClick={props.onSaveAssignTask}>
                    {"Assign"}
                </Button>
                <span hidden={props.hideSuccessLabel} style={{ color: "green" }}>Saved Successfully</span>
            </Modal.Footer>
        </Modal>
    );
}
function CopyConfigModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Copy Config From Another Bot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID">
                            <Form.Label>Enter Other Bot ID</Form.Label>
                            <Form.Control type="text" disabled={props.isMigrating} placeholder="ID of Bot to Copy Config" onChange={props.copyConfigOriginalBotIdChange} value={props.configOriginalBotId} />
                            <span className={"text-muted"}>*THIS WILL REPLACE STEPS OF CURRENT BOT. LOGIN & CALENDER WILL MIGRATE IF APPLICABLE.</span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="addMiniBot">
                            <Form.Check type="checkbox" label="Create MiniBot" onChange={props.createMiniBotNameChange} checked={props.createMiniBot} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={!props.createMiniBot}>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID">
                            <Form.Label>Mini Bot Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" onChange={props.changeMiniBotName} value={props.miniBotName} />
                            <span className={"text-muted"}>Required</span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row hidden={!props.createMiniBot}>
                    <Col>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {props.miniSearchType ? props.miniSearchType : "Select Mini Bot Type"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {props.miniBotTypes.map((typ) => (
                                    <Dropdown.Item onClick={props.miniBotListChange}>{typ}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row hidden={!props.createMiniBot}>
                    <Col>
                        <Form.Group className="mb-3" controlId="miniBotNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" onChange={props.miniBotNotesChange} rows={2} value={props.miniBotNotes} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={props.isMigrating} onClick={props.startMigration}>
                    {props.isMigrating ? "Config migration in progress..." : "Copy"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function AddShadowHost(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Add Shadow Host</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="controlID">
                                <Form.Label>Xpath Shadow Host</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={props.onDataChange(props.ShadowHostXpath)} value={props.tempShadowHostXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function AddRandomValueOption(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Add Random Value Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                    {props.randomValueOption ? props.randomValueOption : "Which Random Value?"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.randomGeneratorOptions.map((typ) => (
                                        <Dropdown.Item onClick={props.randomValueOptionChange}>{typ}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function LiveBotRunView(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>{props.botName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <iframe id='inSession' style={{ width: '100%', height: '90vh' }} src={props.runningBotUrl}></iframe>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
function SpreadSheetConfigurationModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Spreadsheet Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="spreadSheetType">
                                    {props.tempSpreadSheetConfiguration.spreadSheetConfiguration.spreadSheetType != null ?
                                        typeof props.tempSpreadSheetConfiguration.spreadSheetConfiguration.spreadSheetType === 'number' ?
                                            props.spreadSheetTypeOptions[props.tempSpreadSheetConfiguration.spreadSheetConfiguration.spreadSheetType] :
                                            props.tempSpreadSheetConfiguration.spreadSheetConfiguration.spreadSheetType
                                        : "--Select Spreadsheet Type--"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.spreadSheetTypeOptions.map((typ) => (
                                        <Dropdown.Item onClick={props.onDataChange(props.SpreadSheetConfigurationSpreadSheetType)}>{typ}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="refreshPageAfterRecord">
                                <Form.Check type="checkbox" label="Refresh Page After Each Row" onChange={props.onDataChange(props.SpreadSheetConfigurationRefreshPageAfterRecord)} checked={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.refreshPageAfterRecord} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="htmlColumnReaderXpath">
                                <Form.Label>Column Name Value HTML Xpath</Form.Label>
                                <Form.Control type="text" placeholder="(//*[@class='row'])[0]" onChange={props.onDataChange(props.SpreadSheetConfigurationHtmlColumnReaderXpath)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.htmlColumnReaderXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="htmlAllRowsXpath">
                                <Form.Label>HTML All Rows Xpath</Form.Label>
                                <Form.Control type="text" placeholder="//*[@class='row'])" onChange={props.onDataChange(props.SpreadSheetConfigurationHtmlAllRowsXpath)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.htmlAllRowsXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="htmlRowColumnValue">
                                <Form.Label>Row Column Value HTML Xpath</Form.Label>
                                <Form.Control type="text" placeholder="//*[@class='row'][%ROW][%COLUMN%])" onChange={props.onDataChange(props.SpreadSheetConfigurationHtmlRowColumnValue)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.htmlRowColumnValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipRows">
                                <Form.Label>Skip Rows</Form.Label>
                                <Form.Control type="number" placeholder="25" onChange={props.onDataChange(props.SpreadSheetConfigurationSkipRows)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.skipRows} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="mergeColumns">
                                <Form.Label>Merge Columns</Form.Label>
                                <Form.Control type="text" placeholder="Example: First Name%Last Name | Phone number&Home Address" onChange={props.onDataChange(props.SpreadSheetConfigurationMergeColumns)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.mergeColumns} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="mergeColumnsWithFormat">
                                <Form.Label>Merge Columns and Format Text (Must Match Merge Column)</Form.Label>
                                <Form.Control type="text" placeholder="Example: BRACKET%PARENTHESES | CAP" onChange={props.onDataChange(props.SpreadSheetConfigurationMergeColumnsWithFormat)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.mergeColumnsWithFormat} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="setDelimiter">
                                <Form.Label>Column Set Separator</Form.Label>
                                <Form.Control type="text" placeholder="Example: '|'" onChange={props.onDataChange(props.SpreadSheetConfigurationSetDelimiter)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.setDelimiter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="columnDelimiter">
                                <Form.Label>Column Separator</Form.Label>
                                <Form.Control type="text" placeholder="Example: '%'" onChange={props.onDataChange(props.SpreadSheetConfigurationColumnDelimiter)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.columnDelimiter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="localHtmlFilePath">
                                <Form.Label>Path of Local Spreadsheet File</Form.Label>
                                <Form.Control type="text" placeholder="/Users/name/Downloads/spreadsheet.html" onChange={props.onDataChange(props.SpreadSheetConfigurationLocalHtmlHtmlPath)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.localHtmlFilePath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="acceptRowColumns">
                                <Form.Label>Accept Row Columns</Form.Label>
                                <Form.Control type="text" placeholder="Example: Is Complete%Status" onChange={props.onDataChange(props.SpreadSheetConfigurationAcceptRowColumns)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.acceptRowColumns} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="acceptRowWithColumnValues">
                                <Form.Label>Accept Row with Column Value</Form.Label>
                                <Form.Control type="text" placeholder="Example: UPLOADED%Active (Early)" onChange={props.onDataChange(props.SpreadSheetConfigurationAcceptRowWithColumnValues)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.acceptRowWithColumnValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="distinctBy">
                                <Form.Label>Group By</Form.Label>
                                <Form.Control type="text" placeholder="Example: EmployeeId%OR%LastName" onChange={props.onDataChange(props.SpreadSheetConfigurationDistinctBy)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.distinctBy} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="emptyColumns">
                                <Form.Label>Handle Empty Columns</Form.Label>
                                <Form.Control type="text" placeholder="Example: Home Phone%OR%Home Address" onChange={props.onDataChange(props.SpreadSheetConfigurationEmptyColumns)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.emptyColumns} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="emptyColumnValues">
                                <Form.Label>Empty Column Values</Form.Label>
                                <Form.Control type="text" placeholder="Example: 216-424-5421%OR%111 main st" onChange={props.onDataChange(props.SpreadSheetConfigurationEmptyColumnValues)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.emptyColumnValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="oeType">
                                    {props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeType != null ?
                                        typeof props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeType === 'number' ?
                                            props.oeTypeOptions[props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeType] :
                                            props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeType
                                        : "--Select Online Spreadsheet Editor Type--"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.oeTypeOptions.map((typ) => (
                                        <Dropdown.Item onClick={props.onDataChange(props.SpreadSheetConfigurationOEType)}>{typ}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeCellNavigatorXpath">
                                <Form.Label>Online Editor Cell Navigator Xpath</Form.Label>
                                <Form.Control type="text" placeholder="//*[@id=FormulaBar-NameBox-input]" onChange={props.onDataChange(props.SpreadSheetConfigurationOECellNavigatorXpath)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeCellNavigatorXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeFormulaInputXpath">
                                <Form.Label>Online Editor Formula Input Xpath</Form.Label>
                                <Form.Control type="text" placeholder="//*[@id=formulaBarTextDivId_textElement]" onChange={props.onDataChange(props.SpreadSheetConfigurationOEFormulaInputXpath)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeFormulaInputXpath} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeStartingCell">
                                <Form.Label>Online Editor Starting Search Cell</Form.Label>
                                <Form.Control type="text" placeholder="A1" onChange={props.onDataChange(props.SpreadSheetConfigurationOEStartingCell)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeStartingCell} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeColumnValue">
                                <Form.Label>Online Editor Column Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name or {Date_of_month}" onChange={props.onDataChange(props.SpreadSheetConfigurationOEColumnValue)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeColumnValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeRowValue">
                                <Form.Label>Online Editor Row Value</Form.Label>
                                <Form.Control type="text" placeholder="Jeff or {ID}" onChange={props.onDataChange(props.SpreadSheetConfigurationOERowValue)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeRowValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeCellValues">
                                <Form.Label>Online Editor Cell Values & Custom Movement (VALUE:UP:DOWN:LEFT:Right)</Form.Label>
                                <Form.Control type="text" placeholder="Last_Name:0:0:0:0,Bag_Rev:0:0:0:1" onChange={props.onDataChange(props.SpreadSheetConfigurationOECellValues)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeCellValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeRepeatSpreadSheetEntry">
                                <Form.Label>Repeat Entry Key</Form.Label>
                                <Form.Control type="text" placeholder="Ex: quantity" onChange={props.onDataChange(props.SpreadSheetConfigurationOERepeatSpreadSheetEntry)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeRepeatSpreadSheetEntry} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeColumnSearchSkip">
                                <Form.Label>Column Search Skip (Start At Then Skip)</Form.Label>
                                <Form.Control type="text" placeholder="2%SKIP%4" onChange={props.onDataChange(props.SpreadSheetConfigurationOEColumnSearchSkip)} value={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeColumnSearchSkip} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeCellValueMustBeEmpty">
                                <Form.Check type="checkbox" label="Cell Must Be Empty to Edit" onChange={props.onDataChange(props.SpreadSheetConfigurationOECellValueMustBeEmpty)} checked={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeCellValueMustBeEmpty} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeStartWithColumn">
                                <Form.Check type="checkbox" label="Start Movement with Column" onChange={props.onDataChange(props.SpreadSheetConfigurationOEStartWithColumn)} checked={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeStartWithColumn} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeRowCellSearchValueStartsWith">
                                <Form.Check type="checkbox" label="Search Row Value by Starts With instead of Equals" onChange={props.onDataChange(props.SpreadSheetConfigurationOERowCellSearchValueStartsWith)} checked={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeRowCellSearchValueStartsWith} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oeColumnCellSearchValueStartsWith">
                                <Form.Check type="checkbox" label="Search Column Value by Starts With instead of Equals" onChange={props.onDataChange(props.SpreadSheetConfigurationOEColumnCellSearchValueStartsWith)} checked={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oeColumnCellSearchValueStartsWith} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="oePreventDuplicateEntry">
                                <Form.Check type="checkbox" label="Prevent Duplicate Entries" onChange={props.onDataChange(props.SpreadSheetConfigurationOEPreventDuplicateEntry)} checked={props.tempSpreadSheetConfiguration.spreadSheetConfiguration.oePreventDuplicateEntry} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function TextFormatterModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Text Formatter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="placeBrackets">
                                <Form.Check type="checkbox" label="Surround with Brackets" onChange={props.onDataChange(props.TextFormatterPlaceBrackets)} checked={props.tempTextFormatter.textFormatter.placeBrackets} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="placeParentheses">
                                <Form.Check type="checkbox" label="Surround with Parentheses" onChange={props.onDataChange(props.TextFormatterPlaceParentheses)} checked={props.tempTextFormatter.textFormatter.placeParentheses} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="capitalizeAll">
                                <Form.Check type="checkbox" label="Capitalize All" onChange={props.onDataChange(props.TextFormatterCapitalizeAll)} checked={props.tempTextFormatter.textFormatter.capitalizeAll} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="lowercaseAll">
                                <Form.Check type="checkbox" label="Lowercase All" onChange={props.onDataChange(props.TextFormatterLowercaseAll)} checked={props.tempTextFormatter.textFormatter.lowercaseAll} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="removeMiddleValue">
                                <Form.Check type="checkbox" label="Remove Middle Value" onChange={props.onDataChange(props.TextFormatterRemoveMiddleValue)} checked={props.tempTextFormatter.textFormatter.removeMiddleValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="swapWithBusinessEliteInterpreters">
                                <Form.Check type="checkbox" label="Replace with Business Collection Item" onChange={props.onDataChange(props.TextFormatterSwapWithBusinessEliteInterpreters)} checked={props.tempTextFormatter.textFormatter.swapWithBusinessEliteInterpreters} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="weekOfMonth">
                                <Form.Check type="checkbox" label="Get Month Week Number" onChange={props.onDataChange(props.TextFormatterWeekOfMonth)} checked={props.tempTextFormatter.textFormatter.weekOfMonth} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="yearOnly">
                                <Form.Check type="checkbox" label="Get Year Only From Date" onChange={props.onDataChange(props.TextFormatterYearOnly)} checked={props.tempTextFormatter.textFormatter.yearOnly} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="findText">
                                <Form.Label>Find Text</Form.Label>
                                <Form.Control type="text" placeholder="Example: 'and'" onChange={props.onDataChange(props.TextFormatterFindText)} value={props.tempTextFormatter.textFormatter.findText} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="replaceText">
                                <Form.Label>Replace Text</Form.Label>
                                <Form.Control type="text" placeholder="Example: '&'" onChange={props.onDataChange(props.TextFormatterReplaceText)} value={props.tempTextFormatter.textFormatter.replaceText} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="replaceTextRegularExpression">
                                <Form.Label>Clear Text by Regular Regular Expression</Form.Label>
                                <Form.Control type="text" placeholder="[^0-9.]" onChange={props.onDataChange(props.TextFormatterReplaceTextRegularExpression)} value={props.tempTextFormatter.textFormatter.replaceTextRegularExpression} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="dateConverter">
                                <Form.Label>Format Date Value</Form.Label>
                                <Form.Control type="text" placeholder="dd/M/yyyy" onChange={props.onDataChange(props.TextFormatterDateConverter)} value={props.tempTextFormatter.textFormatter.dateConverter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="convertToClosesTuesday">
                                <Form.Check type="checkbox" label="Convert to Following Tuesday? (Format Date Required)" onChange={props.onDataChange(props.TextFormatterConvertToClosesTuesday)} checked={props.tempTextFormatter.textFormatter.convertToClosesTuesday} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="truncate">
                                <Form.Label>Truncate Start Text</Form.Label>
                                <Form.Control type="number" placeholder="3 (will remove first 3 characters)" onChange={props.onDataChange(props.TextFormatterTruncate)} value={props.tempTextFormatter.textFormatter.truncate} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="prefill">
                                <Form.Label>Prefill Text</Form.Label>
                                <Form.Control type="text" placeholder="'Pre' (added before value)" onChange={props.onDataChange(props.TextFormatterPrefill)} value={props.tempTextFormatter.textFormatter.prefill} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="prefillWithExceptions">
                                <Form.Label>Prefill Text With Exceptions</Form.Label>
                                <Form.Control type="text" placeholder="'Pre' (added before value but with conditions if match found)" onChange={props.onDataChange(props.TextFormatterPrefillWithExceptions)} value={props.tempTextFormatter.textFormatter.prefillWithExceptions} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipBusinessEliteInterpreters">
                                <Form.Label>Skip Business ID's</Form.Label>
                                <Form.Control type="text" placeholder="Ex: (womens regular left)%(womens stiff left)" onChange={props.onDataChange(props.TextFormatterSkipBusinessEliteInterpreters)} value={props.tempTextFormatter.textFormatter.skipBusinessEliteInterpreters} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="dateDifference">
                                <Form.Label>Get Date Difference</Form.Label>
                                <Form.Control type="text" placeholder="Ex: Drop_Date%Pickup_Date" onChange={props.onDataChange(props.TextFormatterDateDifference)} value={props.tempTextFormatter.textFormatter.dateDifference} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="calculateByCompleteDays">
                                <Form.Check type="checkbox" label="Calculate By Overall Total Days" onChange={props.onDataChange(props.TextFormatterCalculateByCompleteDays)} checked={props.tempTextFormatter.textFormatter.calculateByCompleteDays} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="addDataValuesFromDateDifference">
                                <Form.Label>Add Data Values From Date Difference</Form.Label>
                                <Form.Control type="text" placeholder="Play_Day_Month_Number{i}:Drop_Date:FormatDate:%M|Play_Day_Year_Number{i}:Drop_Date:GetYearOnlyFromDate" onChange={props.onDataChange(props.TextFormatterAddDataValuesFromDateDifference)} value={props.tempTextFormatter.textFormatter.addDataValuesFromDateDifference} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="divideValues">
                                <Form.Label>Divide Data Values</Form.Label>
                                <Form.Control type="text" placeholder="Ex: Bag_Rev/Bag_Rev_Divisor" onChange={props.onDataChange(props.TextFormatterDivideValues)} value={props.tempTextFormatter.textFormatter.divideValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="prefillWithExceptionAction">
                                    {props.tempTextFormatter.textFormatter.preFillExceptionAction != null ?
                                        typeof props.tempTextFormatter.textFormatter.preFillExceptionAction === 'number' ?
                                            props.textFormatterPreFillExceptionActionOptions[props.tempTextFormatter.textFormatter.preFillExceptionAction] :
                                            props.tempTextFormatter.textFormatter.preFillExceptionAction
                                        : "--Select Prefill Action--"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.textFormatterPreFillExceptionActionOptions.map((typ) => (
                                        <Dropdown.Item onClick={props.onDataChange(props.TextFormatterPreFillExceptionAction)}>{typ}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="postFill">
                                <Form.Label>Append Text</Form.Label>
                                <Form.Control type="text" placeholder="'ing' (added after value) " onChange={props.onDataChange(props.TextFormatterPostFill)} value={props.tempTextFormatter.textFormatter.postFill} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="splitWithDelimiter">
                                <Form.Label>Split with Delimiter</Form.Label>
                                <Form.Control type="text" placeholder="Example: ','" onChange={props.onDataChange(props.TextFormatterSplitWithDelimiter)} value={props.tempTextFormatter.textFormatter.splitWithDelimiter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="addDaysToCalendar">
                                <Form.Label>Add Days to Calendar</Form.Label>
                                <Form.Control type="number" placeholder="-3, 2, 50" onChange={props.onDataChange(props.TextFormatterAddDaysToCalendar)} value={props.tempTextFormatter.textFormatter.addDaysToCalendar} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function DownloadModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Download Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="downloadType">
                                    {props.tempDownload.download.downloadType != null ?
                                        typeof props.tempDownload.download.downloadType === 'number' ?
                                            props.downloadTypeOptions[props.tempDownload.download.downloadType] :
                                            props.tempDownload.download.downloadType
                                        : "--Select Download Type--"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props.downloadTypeOptions.map((typ) => (
                                        <Dropdown.Item onClick={props.onDataChange(props.DownloadDownloadType)}>{typ}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="store">
                                <Form.Check type="checkbox" label="Store" onChange={props.onDataChange(props.DownloadStore)} checked={props.tempDownload.download.store} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractStore">
                                <Form.Check type="checkbox" label="Unzip and Store" onChange={props.onDataChange(props.DownloadExtractStore)} checked={props.tempDownload.download.extractStore} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractLink">
                                <Form.Check type="checkbox" label="Extract Url" onChange={props.onDataChange(props.DownloadExtractLink)} checked={props.tempDownload.download.extractLink} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractLinkStartsWith">
                                <Form.Label>Extract Url - Starts With</Form.Label>
                                <Form.Control type="text" placeholder="Example: 'https://'" onChange={props.onDataChange(props.DownloadExtractLinkStartsWith)} value={props.tempDownload.download.extractLinkStartsWith} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractLinkEndsWith">
                                <Form.Label>Extract Url - Ends With</Form.Label>
                                <Form.Control type="text" placeholder="Example: ')'" onChange={props.onDataChange(props.DownloadExtractLinkEndsWith)} value={props.tempDownload.download.extractLinkEndsWith} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractFileName">
                                <Form.Check type="checkbox" label="Extract File Name" onChange={props.onDataChange(props.DownloadExtractFileName)} checked={props.tempDownload.download.extractFileName} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractFileNameStartsWith">
                                <Form.Label>Extract File Name - Starts With</Form.Label>
                                <Form.Control type="text" placeholder="Example: 'https://'" onChange={props.onDataChange(props.DownloadExtractFileNameStartsWith)} value={props.tempDownload.download.extractFileNameStartsWith} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="extractFileNameEndsWith">
                                <Form.Label>Extract File name - Ends With</Form.Label>
                                <Form.Control type="text" placeholder="Example: ')'" onChange={props.onDataChange(props.DownloadExtractFileNameEndsWith)} value={props.tempDownload.download.extractFileNameEndsWith} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="directory">
                                <Form.Label>Local Download Directory</Form.Label>
                                <Form.Control type="text" placeholder="Example: '/local/path/downloads'" onChange={props.onDataChange(props.DownloadDirectory)} value={props.tempDownload.download.directory} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitTime">
                                <Form.Label>Wait Time (In Milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.DownloadWaitTime)} value={props.tempDownload.download.waitTime} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="fileType">
                                <Form.Label>Direct Link File Type</Form.Label>
                                <Form.Control type="text" placeholder="Example: .png" onChange={props.onDataChange(props.DownloadFileType)} value={props.tempDownload.download.fileType} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function UploadModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Upload Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="uploadStoredFiles">
                                <Form.Check type="checkbox" label="Upload Stored Files" onChange={props.onDataChange(props.UploadUploadStoredFiles)} checked={props.tempUpload.upload.uploadStoredFiles} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="waitTime">
                                <Form.Label>Wait Time (In Milliseconds)</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5000" onChange={props.onDataChange(props.UploadWaitTime)} value={props.tempUpload.upload.waitTime} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function APIRequestModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>API Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="externalAPIType1">
                                <Form.Label>API Request Type</Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="apiHttpRequestType">
                                        {props.tempAPIRequest.apiRequest.apiHttpRequestType != null ?
                                            typeof props.tempAPIRequest.apiRequest.apiHttpRequestType === 'number' ?
                                                props.apiHttpRequestTypeOptions[props.tempAPIRequest.apiRequest.apiHttpRequestType] :
                                                props.tempAPIRequest.apiRequest.apiHttpRequestType
                                            : "--Select Request Type--"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {props.apiHttpRequestTypeOptions.map((typ) => (
                                            <Dropdown.Item onClick={props.onDataChange(props.APIRequestAPIHttpRequestType)}>{typ}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="externalAPIType2">
                                <Form.Label>API Response Type</Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="apiHttpResponseType">
                                        {props.tempAPIRequest.apiRequest.apiHttpResponseType != null ?
                                            typeof props.tempAPIRequest.apiRequest.apiHttpResponseType === 'number' ?
                                                props.apiHttpResponseTypeOptions[props.tempAPIRequest.apiRequest.apiHttpResponseType] :
                                                props.tempAPIRequest.apiRequest.apiHttpResponseType
                                            : "--Select Response Type--"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {props.apiHttpResponseTypeOptions.map((typ) => (
                                            <Dropdown.Item onClick={props.onDataChange(props.APIRequestAPIHttpResponseType)}>{typ}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="externalAPIType3">
                                <Form.Label>External API Type</Form.Label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="externalAPIType">
                                        {props.tempAPIRequest.apiRequest.externalAPIType != null ?
                                            typeof props.tempAPIRequest.apiRequest.externalAPIType === 'number' ?
                                                props.externalAPITypeOptions[props.tempAPIRequest.apiRequest.externalAPIType] :
                                                props.tempAPIRequest.apiRequest.externalAPIType
                                            : "--Select External API Type--"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {props.externalAPITypeOptions.map((typ) => (
                                            <Dropdown.Item onClick={props.onDataChange(props.APIRequestAPIExternalAPIType)}>{typ}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="endPoint">
                                <Form.Label>End Point</Form.Label>
                                <Form.Control type="text" placeholder="Example: 'insert/comment/{commentid}'" onChange={props.onDataChange(props.APIRequestEndPoint)} value={props.tempAPIRequest.apiRequest.endPoint} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="payloadData">
                                <Form.Label>Full Payload Data</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="JSON Data" onChange={props.onDataChange(props.APIRequestPayloadData)} value={props.tempAPIRequest.apiRequest.payloadData} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="replaceRequestKeys">
                                <Form.Label>Replace Request Keys (Key name in Dictionary)</Form.Label>
                                <Form.Control type="text" placeholder="Example: NAME,URL" onChange={props.onDataChange(props.APIRequestReplaceRequestKeys)} value={props.tempAPIRequest.apiRequest.replaceRequestKeys} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="replaceRequestValues">
                                <Form.Label>Replace Request Values (Property Name in Request Payload)</Form.Label>
                                <Form.Control type="text" placeholder="Will retrieve value from Dictionary" onChange={props.onDataChange(props.APIRequestReplaceRequestValues)} value={props.tempAPIRequest.apiRequest.replaceRequestValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="requestContainsTimeStamp">
                                <Form.Check type="checkbox" label="Request Contains Timestamp" onChange={props.onDataChange(props.APIRequestRequestContainsTimeStamp)} checked={props.tempAPIRequest.apiRequest.requestContainsTimeStamp} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="encodeRequestNameValue">
                                <Form.Check type="checkbox" label="Encode Request Name" onChange={props.onDataChange(props.APIRequestEncodeRequestNameValue)} checked={props.tempAPIRequest.apiRequest.encodeRequestNameValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="encodeRequestURLValue">
                                <Form.Check type="checkbox" label="Encode Request URL" onChange={props.onDataChange(props.APIRequestEncodeRequestURLValue)} checked={props.tempAPIRequest.apiRequest.encodeRequestURLValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="removeDomainProtocols">
                                <Form.Check type="checkbox" label="Remove Domain Protocol" onChange={props.onDataChange(props.APIRequestRemoveDomainProtocols)} checked={props.tempAPIRequest.apiRequest.removeDomainProtocols} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="newResultsByID">
                                <Form.Check type="checkbox" label="Track For New Results By ID" onChange={props.onDataChange(props.APIRequestRequestNewResultsByID)} checked={props.tempAPIRequest.apiRequest.newResultsByID} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="reverseResults">
                                <Form.Check type="checkbox" label="Reverse Search Results" onChange={props.onDataChange(props.APIRequestRequestReverseResults)} checked={props.tempAPIRequest.apiRequest.reverseResults} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="messageAllAPIResponses">
                                <Form.Check type="checkbox" label="Message All API Responses" onChange={props.onDataChange(props.APIRequestMessageAllAPIResponses)} checked={props.tempAPIRequest.apiRequest.messageAllAPIResponses} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="getArrayFromRootObject">
                                <Form.Label>Extract Main Array From Root Response Object</Form.Label>
                                <Form.Control type="text" placeholder="Root Array Name " onChange={props.onDataChange(props.APIRequestGetArrayFromRootObject)} value={props.tempAPIRequest.apiRequest.getArrayFromRootObject} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="storeResponseValues">
                                <Form.Label>Store Response Values</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Example: ID,TimeStamp,Requester" onChange={props.onDataChange(props.APIRequestStoreResponseValues)} value={props.tempAPIRequest.apiRequest.storeResponseValues} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="mergeResults">
                                <Form.Label>Merge Response Values</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Example: Name,Address,Purchase_Order" onChange={props.onDataChange(props.APIRequestMergeResults)} value={props.tempAPIRequest.apiRequest.mergeResults} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="mergePropertiesWithFormat">
                                <Form.Label>Merge Response with Format (optional but must match Merge Values if used)</Form.Label>
                                <Form.Control type="text" placeholder="Example:CAP,DASH,PAREN" onChange={props.onDataChange(props.APIRequestMergePropertiesWithFormat)} value={props.tempAPIRequest.apiRequest.mergePropertiesWithFormat} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="setDelimiter">
                                <Form.Label>How to Split Keys in Merge Set</Form.Label>
                                <Form.Control type="text" placeholder=", or |" onChange={props.onDataChange(props.APIRequestSetDelimiter)} value={props.tempAPIRequest.apiRequest.setDelimiter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="propertyDelimiter">
                                <Form.Label>How to Split Properties in Set</Form.Label>
                                <Form.Control type="text" placeholder=", or |" onChange={props.onDataChange(props.APIRequestPropertyDelimiter)} value={props.tempAPIRequest.apiRequest.propertyDelimiter} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="rootIgnores">
                                <Form.Label>Ignore Root Items (Prevents from extraction)</Form.Label>
                                <Form.Control type="text" placeholder="status:refunded,name:Smith" onChange={props.onDataChange(props.APIRequestRootIgnores)} value={props.tempAPIRequest.apiRequest.rootIgnores} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="catchAllItems">
                                <Form.Label>Group One to Many (Ex: multiple products in one order)</Form.Label>
                                <Form.Control type="text" placeholder="Path to Array Object: line_items" onChange={props.onDataChange(props.APIRequestCatchAllItems)} value={props.tempAPIRequest.apiRequest.catchAllItems} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="ignoreAllItems">
                                <Form.Label>Ignore Catch item if Text Contains</Form.Label>
                                <Form.Control type="text" placeholder="name:Insurance" onChange={props.onDataChange(props.APIRequestIgnoreAllItems)} value={props.tempAPIRequest.apiRequest.ignoreAllItems} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="groupResultsBy">
                                <Form.Label>Group Results By</Form.Label>
                                <Form.Control type="text" placeholder="example:Track_ID" onChange={props.onDataChange(props.APIRequestGroupResultsBy)} value={props.tempAPIRequest.apiRequest.groupResultsBy} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="groupByTargetProperty">
                                <Form.Label>Group Response Target Property</Form.Label>
                                <Form.Control type="text" placeholder="example:quantity sku Flex Hand Gender" onChange={props.onDataChange(props.APIRequestGroupByTargetProperty)} value={props.tempAPIRequest.apiRequest.groupByTargetProperty} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="groupMergeName">
                                <Form.Label>Group Merge Name</Form.Label>
                                <Form.Control type="text" placeholder="example:Last_Name quantity sku Flex Hand " onChange={props.onDataChange(props.APIRequestGroupMergeName)} value={props.tempAPIRequest.apiRequest.groupMergeName} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="groupMergeValue">
                                <Form.Label>Group Merge Value</Form.Label>
                                <Form.Control type="text" placeholder="example:Last_Name%BOT_GROUP_DATA" onChange={props.onDataChange(props.APIRequestGroupMergeValue)} value={props.tempAPIRequest.apiRequest.groupMergeValue} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="changeStep">
                                <Form.Label>Which Step to Change?</Form.Label>
                                <Form.Control type="number" placeholder="example:16" onChange={props.onDataChange(props.APIRequestChangeStep)} value={props.tempAPIRequest.apiRequest.changeStep} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="skipToStep">
                                <Form.Label>Which Step to Skip To?</Form.Label>
                                <Form.Control type="number" placeholder="example:29" onChange={props.onDataChange(props.APIRequestSkipToStep)} value={props.tempAPIRequest.apiRequest.skipToStep} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="goToStepAfterComplete">
                                <Form.Label>Go to Step After API Completed</Form.Label>
                                <Form.Control type="number" placeholder="example:1" onChange={props.onDataChange(props.APIRequestGoToStepAfterComplete)} value={props.tempAPIRequest.apiRequest.goToStepAfterComplete} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
function DynamicStepCreatorModal(props) {
    return (
        props.htmlData.htmlControls[props.currentHtmlControlIndex] && props.htmlData.htmlControls[props.currentHtmlControlIndex] ? (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Copy and Inject Steps</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="placementStep">
                                <Form.Label>Which Step to Inject</Form.Label>
                                <Form.Control type="number" placeholder="Example: 22" onChange={props.onDataChange(props.DynamicStepCreatorPlacementStep)} value={props.tempDynamicStepCreator.dynamicStepCreator.placementStep} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="startRange">
                                <Form.Label>Start Copy Step</Form.Label>
                                <Form.Control type="number" placeholder="Example: 1" onChange={props.onDataChange(props.DynamicStepCreatorStartRange)} value={props.tempDynamicStepCreator.dynamicStepCreator.startRange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="finishRange">
                                <Form.Label>Finish Copy Step</Form.Label>
                                <Form.Control type="number" placeholder="Example: 5" onChange={props.onDataChange(props.DynamicStepCreatorFinishRange)} value={props.tempDynamicStepCreator.dynamicStepCreator.finishRange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="rules">
                                <Form.Label>Rules to Apply</Form.Label>
                                <Form.Control type="text" placeholder="Example: ReplaceInLabel:Drop_Month_Number:Play{i}_Month_Number" onChange={props.onDataChange(props.DynamicStepCreatorRules)} value={props.tempDynamicStepCreator.dynamicStepCreator.rules} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="dataCreationIterator">
                                <Form.Label>Data Field to Determine Copy Count</Form.Label>
                                <Form.Control type="text" placeholder="Example: Days_Of_Play" onChange={props.onDataChange(props.DynamicStepCreatorDataCreationIterator)} value={props.tempDynamicStepCreator.dynamicStepCreator.dataCreationIterator} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button hidden={!props.showDeleteElementButton} style={{ "marginRight": "6px" }} variant="danger" onClick={props.deleteElement(props.modalName)}>
                        {"Delete"}
                    </Button>
                    <Button variant="primary" onClick={props.onSaveModalData(props.modalName)}>
                        {"Save"}
                    </Button>
                </Modal.Footer>
            </Modal>) : (<div></div>)
    );
}
const initialGetValueFromOtherElement = { getValueFromOtherElement: { otherElementXpath: '', alternativeElementXpath: '', increaseValueBy: null, increaseValueByAlternative: null, isTimeValue: false, elementType: 'Label', isAmPmFormat: false } };
const initialModalCloserState = { modalCloser: { elementId: '', class: '', xPath: '', closeBefore: false, closeAfter: false, waitUntilNotVisible: false, waitUntilNotVisibleTimeLength: null, removeWaitTimeLength: null, clickDismiss: false, thenScript: null, isPresent: false } };
const initialCaptchaSolverState = { captchaSolver: { version: 'V1', captchaType: 4, callBack: false, isHCaptcha: false, useCaptchaExtension: false, useCaptchaExtensionThenClickButton: false, captchaExtensionLoadTimeDelay: 0, foreteesCaptchaSolver: false, isScriptKey: false, geeTest: false, hardCallBack: false, captchaExistCheck: false, extractKeyFromSrcUrl: false, overrideCallback: false, xPathCaptchaSiteKey: '', captchaResponseXpath: '', v1CaptchaElementId: '', v1ElementInputFieldXPath: '', v1Base64Image: false, v1VerifySkipCount: 0, verifyCaptcha: false, refreshBeforeSolving: false, captchaExtensionXpath: null } };
const initialIFrameSwitcher = { frameSwitch: { elementId: '', class: '', xPath: '', whichWindow: 0, clickSwitch: false, skipIFrameSwitchBack: false, pauseBeforeSwitch: 0, includeDynamicStepCount: false, excludeExtraWindowCount: false, ignoreFail: false } };
const initialITwoClickSelector = { twoClickSelector: { xPath: '' } };
const initialITabSplit = { tabSplit: { numberOfTabsTextXpath: '' } };
const initialITwoFormAuthentication = { twoFormAuthentication: { authenticationType: 'ALLOWACCESS', emailConfirmationInstruction: '', acceptXpath: '', requireAcceptClick: false } };
const initialIClickLabelHelper = { clickLabelHelper: '' };
const initialIClickActionMultiplierCount = { clickActionMultiplierCount: '' };
const initialAPIDataLabelClick = { apiDataLabelClick: '' };
const initialInfiniteScroll = { infiniteScroll: { scrollScript: '', scrollSection: '', scrollToTopScript: '', waitTimeBetweenScroll: 0, waitUntilNotVisible: null, skipFirstItems: 0, sectionHeightScript: null, itemsToHandlePerPage: 0, openItemsInTab: false, preFillNewTabUrl: null } };
const initialListSelectorCondition = { listSelectorCondition: { itemCounterGreaterThan: null, goRepeatStep: null, textContentsContains: null, textContentsDoesNotContains: null, waitUntilElementNotPresentXpath: null, waitUntilElementNotPresentTimeout: null, userListedItemsMatchXpath: false, combineMultipleTextElement: false } };
const initialElementCondition = { elementCondition: { urlContains: null, scripts: null, skipSteps: null, repeatStepIfFail: null, xpathElementExist: null, apiDataValues: null, valueDoesNotMatch: null, previousValueDoesNotMatch: null, skipDataLibraryItem: false, removeDynamicStep: false, continueIfElementChanged: false } };
const initialSpreadSheetConfiguration = { spreadSheetConfiguration: { spreadSheetType: null, htmlColumnReaderXpath: null, htmlAllRowsXpath: null, htmlRowColumnValue: null, skipRows: 0, mergeColumns: null, mergeColumnsWithFormat: null, setDelimiter: null, columnDelimiter: null, localHtmlFilePath: null, acceptRowColumns: null, acceptRowWithColumnValues: null, refreshPageAfterRecord: false, distinctBy: null, emptyColumns: null, emptyColumnValues: null, oeCellNavigatorXpath: null, oeFormulaInputXpath: null, oeStartingCell: null, oeColumnValue: null, oeRowValue: null, oeCellValues: null, oeCellValueMustBeEmpty: false, oeStartWithColumn: false, oeRowCellSearchValueStartsWith: false, oeColumnCellSearchValueStartsWith: false, oeType: null, oeRepeatSpreadSheetEntry: null, oeColumnSearchSkip: null, oePreventDuplicateEntry: false } };
const initialTextFormatter = { textFormatter: { findText: null, replaceText: null, replaceTextRegularExpression: null, placeBrackets: false, placeParentheses: false, dateConverter: null, splitWithDelimiter: null, addDaysToCalendar: null, capitalizeAll: false, lowercaseAll: false, truncate: 0, prefill: null, prefillWithExceptions: null, preFillExceptionAction: null, postFill: null, removeMiddleValue: false, swapWithBusinessEliteInterpreters: false, convertToClosesTuesday: false, weekOfMonth: false, yearOnly: false, skipBusinessEliteInterpreters: null, dateDifference: null, divideValues: null, addDataValuesFromDateDifference: null } };
const initialDownload = { download: { downloadType: null, store: false, extractStore: false, extractLink: false, extractLinkStartsWith: null, extractLinkEndsWith: null, extractName: false, extractFileNameStartsWith: null, extractFileNameEndsWith: null, directory: null, waitTime: null, fileType: null } };
const initialUpload = { upload: { uploadStoredFiles: false, waitTime: null } };
const initialAPIRequest = { apiRequest: { apiHttpRequestType: null, apiHttpResponseType: null, externalAPIType: null, endPoint: null, payloadData: null, replaceRequestKeys: null, replaceRequestValues: null, storeResponseValues: null, requestContainsTimeStamp: false, encodeRequestNameValue: false, encodeRequestURLValue: false, removeDomainProtocols: false, newResultsByID: false, reverseResults: false, mergeResults: null, getArrayFromRootObject: null, mergePropertiesWithFormat: null, setDelimiter: null, propertyDelimiter: null, ignoreAllItems: null, messageAllAPIResponses: false, goToStepAfterComplete: null, changeStep: 0, skipToStep: 0 } };
const initialDynamicStepCreator = { dynamicStepCreator: { placementStep: 0, startRange: 0, finishRange: null, rules: null, dataCreationIterator: null } };
const access = parseInt(localStorage.getItem('l'));
var fakeIndexCount = 1;
@inject('rootStore')
@observer
export default class HtmlBuilder extends Component {
    constructor() {
        super();
        this.state = {
            botId: '',
            userId: '',
            captchaData: [],
            suspensionReasons: [],
            datePickers: [],
            htmlData: undefined,
            liveFeedStatus: '(Disconnected)',
            mainBotDuplicateName: '',
            showUpgradeNeededModal: false,
            masterBotId: '',
            showSharedBotModal: false,
            mainBotDuplicateName: '',
            showConfirmDuplicateModal: false,
            addGetValueFromElementModalShow: false,
            addModalCloserModalShow: false,
            addCaptchaSolverModalShow: false,
            iFrameSwitcherModalShow: false,
            infiniteScrollModalShow: false,
            listSelectorConditionModalShow: false,
            elementConditionModalShow: false,
            addTimeoutWaitModalShow: false,
            addDaysToCalendarModalShow: false,
            stepTargetUtcStartTimeModalShow: false,
            lurkBeforeModalShow: false,
            lurkUntilModalShow: false,
            clickAndLurkModalShow: false,
            goRepeatStepModalShow: false,
            pauseBeforeActionModalShow: false,
            pauseAfterActionModalShow: false,
            scrollToBottomScriptModalShow: false,
            setDataDateOnCalendarModalShow: false,
            emptyListLabelXpathModalShow: false,
            redirectAfterUrlModalShow: false,
            addShadowHostModalShow: false,
            randomValueGeneratorModalShow: false,
            spreadSheetConfigurationModalShow: false,
            downloadModalShow: false,
            uploadModalShow: false,
            dynamicStepCreatorModalShow: false,
            textFormatterModalShow: false,
            twoClickSelectorModalShow: false,
            tabSplitModalShow: false,
            twoFormAuthenticationModalShow: false,
            clickLabelHelperModalShow: false,
            clickActionMultiplierCountModalShow: false,
            apiDataLabelClickModalShow: false,
            currentHtmlControlIndex: -1,
            currenInnerHtmlControl: -1,
            showAllowBypassHtmlButton: false,
            superSync: false,
            currentEventTargetValueHolder: undefined,
            currentEventTargetCheckedHolder: undefined,
            tempGetValueFromOtherElementData: initialGetValueFromOtherElement,
            tempModalCloserData: initialModalCloserState,
            tempCaptchaSolverData: initialCaptchaSolverState,
            tempIframeSwitcher: initialIFrameSwitcher,
            tempInfiniteScroll: initialInfiniteScroll,
            tempListSelectorCondition: initialListSelectorCondition,
            tempElementCondition: initialElementCondition,
            tempTimeoutWaitTime: '',
            tempAddDaysToCalendar: '',
            tempStepTargetStartTime: '',
            tempLurkBefore: '',
            tempLurkUntil: '',
            tempClickAndLurk: '',
            tempGoRepeatStep: '',
            tempPauseBeforeAction: '',
            tempPauseAfterAction: '',
            tempScrollToBottomScript: '',
            tempSetDataDateOnCalendar: '',
            tempEmptyListLabelXpath: '',
            tempRedirectAfterUrl: '',
            tempShadowHostXpath: '',
            tempTwoClickSelector: initialITwoClickSelector,
            tempTabSplit: initialITabSplit,
            tempTwoFormAuthentication: initialITwoFormAuthentication,
            tempClickLabelHelper: initialIClickLabelHelper,
            tempClickActionMultiplierCount: initialIClickActionMultiplierCount,
            tempSpreadSheetConfiguration: initialSpreadSheetConfiguration,
            tempDownload: initialDownload,
            tempUpload: initialUpload,
            tempTextFormatter: initialTextFormatter,
            tempAPIDataLabelClick: initialAPIDataLabelClick,
            tempAPIRequest: initialAPIRequest,
            tempDynamicStepCreator: initialDynamicStepCreator,
            GetValueFromOtherElement: 'GetValueFromOtherElement',
            ModalCloser: 'ModalCloser',
            CaptchaSolver: 'CaptchaSolver',
            InfiniteScroll: 'InfiniteScroll',
            ListSelectorCondition: 'ListSelectorCondition',
            ElementCondition: 'ElementCondition',
            TimeoutWait: 'TimeoutWait',
            AddToCalendar: 'AddToCalendar',
            StepTargetStartTime: 'StepTargetStartTime',
            LurkBefore: 'LurkBefore',
            LurkUntil: 'LurkUntil',
            ClickAndLurk: 'ClickAndLurk',
            GoRepeatStep: 'GoRepeatStep',
            PauseBefore: 'PauseBefore',
            PauseAfter: 'PauseAfter',
            ModalEmptyListLabelXpath: 'ModalEmptyListLabelXpath',
            RedirectAfter: 'RedirectAfter',
            TwoClickSelect: 'TwoClickSelect',
            TabSplit: 'TabSplit',
            TwoFormAuthentication: 'TwoFormAuthentication',
            SpreadSheetConfiguration: 'SpreadSheetConfiguration',
            TextFormatter: 'TextFormatter',
            Download: 'Download',
            Upload: 'Upload',
            DynamicStepCreator: 'DynamicStepCreator',
            APIRequest: 'APIRequest',

            GetValueFromOtherElementOtherElementXpath: 'GetValueFromOtherElementOtherElementXpath',
            GetValueFromOtherElementAlternativeElementXpath: 'GetValueFromOtherElementAlternativeElementXpath',
            GetValueFromOtherElementIncreaseValueBy: 'GetValueFromOtherElementIncreaseValueBy',
            GetValueFromOtherElementIncreaseValueByAlternative: 'GetValueFromOtherElementIncreaseValueByAlternative',
            GetValueFromOtherElementElementType: 'GetValueFromOtherElementElementType',
            GetValueFromOtherElementIsTimeValue: 'GetValueFromOtherElementIsTimeValue',
            GetValueFromOtherElementIsAmPmFormat: 'GetValueFromOtherElementIsAmPmFormat',

            ModalCloserElementId: 'ModalCloserElementId',
            ModalCloserClass: 'ModalCloserClass',
            ModalCloserXpath: 'ModalCloserXpath',
            ModalCloserCloseBefore: 'ModalCloserCloseBefore',
            ModalCloserCloseAfter: 'ModalCloserCloseAfter',
            ModalCloserWaitUntilNotVisible: 'ModalCloserWaitUntilNotVisible',
            ModalCloserWaitUntilNotVisibleTimeLength: 'ModalCloserWaitUntilNotVisibleTimeLength',
            ModalCloserRemoveWaitTimeLength: 'ModalCloserRemoveWaitTimeLength',
            ModalCloserClickDismiss: 'ModalCloserClickDismiss',
            ModalCloserThenScript: 'ModalCloserThenScript',
            ModalCloserIsPresent: 'ModalCloserIsPresent',

            CaptchaSolverVersion: 'CaptchaSolverVersion',
            CaptchaSolverType: 'CaptchaSolverType',
            CaptchaSolverRunPoseCallback: 'CaptchaSolverRunPoseCallback',
            CaptchaSolverIsHCaptcha: 'CaptchaSolverIsHCaptcha',
            CaptchaSolverUseCaptchaExtension: 'CaptchaSolverUseCaptchaExtension',
            CaptchaSolverUseCaptchaExtensionThenClickButton: 'CaptchaSolverUseCaptchaExtensionThenClickButton',
            CaptchaSolverCaptchaExtensionLoadTimeDelay: 'CaptchaSolverCaptchaExtensionLoadTimeDelay',
            CaptchaSolverForeteesCaptchaSolver: 'CaptchaSolverForeteesCaptchaSolver',
            CaptchaSolverScriptKey: 'CaptchaSolverScriptKey',
            CaptchaSolverGeeTest: 'CaptchaSolverGeeTest',
            CaptchaSolverRunHardPostCallback: 'CaptchaSolverRunHardPostCallback',
            CaptchaSolverCheckIfCaptchaExist: 'CaptchaSolverCheckIfCaptchaExist',
            CaptchaSolverExtractKeyFromSrcUrl: 'CaptchaSolverExtractKeyFromSrcUrl',
            CaptchaSolverOverrideCallback: 'CaptchaSolverOverrideCallback',
            CaptchaSolverV1Base64Image: 'CaptchaSolverV1Base64Image',
            CaptchaSolverTokenFunction: 'CaptchaSolverTokenFunction',
            CaptchaSolverXpathSiteKey: 'CaptchaSolverXpathSiteKey',
            CaptchaSolverResponseXpath: 'CaptchaSolverResponseXpath',
            CaptchaSolverV1ElementId: 'CaptchaSolverV1ElementId',
            CaptchaSolverV1ElementInputFieldXpath: 'CaptchaSolverV1ElementInputFieldXpath',
            CaptchaSolverV1VerifySkipCount: 'CaptchaSolverV1VerifySkipCount',
            CaptchaSolverVerifyCaptcha: 'CaptchaSolverVerifyCaptcha',
            CaptchaSolverRefreshBeforeSolving: 'CaptchaSolverRefreshBeforeSolving',
            CaptchaSolverCaptchaExtensionXpath: 'CaptchaSolverCaptchaExtensionXpath',

            IFrameSwitcherElementId: 'IFrameSwitcherElementId',
            IFrameSwitcherClass: 'IFrameSwitcherClass',
            IFrameSwitcherXpath: 'IFrameSwitcherXpath',
            IFrameSwitcherWhichWindow: 'IFrameSwitcherWhichWindow',
            IFrameSwitcherPauseBeforeSwitch: 'IFrameSwitcherPauseBeforeSwitch',
            IFrameSwitcherClickAndSwitch: 'IFrameSwitcherClickAndSwitch',
            IFrameSwitcherSkipSwitchBack: 'IFrameSwitcherSkipSwitchBack',
            IFrameSwitcherIncludeDynamicStepCount: 'IFrameSwitcherIncludeDynamicStepCount',
            IFrameSwitcherExcludeExtraWindowCount: 'IFrameSwitcherExcludeExtraWindowCount',
            IFrameSwitcherIgnoreFail: 'IFrameSwitcherIgnoreFail',

            InfiniteScrollScrollScript: 'InfiniteScrollScrollScript',
            InfiniteScrollScrollSection: 'InfiniteScrollScrollSection',
            InfiniteScrollScrollToTopScript: 'InfiniteScrollScrollToTopScript',
            InfiniteScrollWaitTimeBetweenScroll: 'InfiniteScrollWaitTimeBetweenScroll',
            InfiniteScrollWaitUntilNotVisible: 'InfiniteScrollWaitUntilNotVisible',
            InfiniteScrollItemsToHandlePerPage: 'InfiniteScrollItemsToHandlePerPage',
            InfiniteScrollSkipFirstItems: 'InfiniteScrollSkipFirstItems',
            InfiniteScrollOpenItemsInTab: 'InfiniteScrollOpenItemsInTab',
            InfiniteScrollPrefillNewTabUrl: 'InfiniteScrollPrefillNewTabUrl',
            InfiniteScrollSectionHeightScript: 'InfiniteScrollSectionHeightScript',

            ListSelectorConditionItemCounterGreaterThan: 'ListSelectorItemCounterGreaterThan',
            ListSelectorConditionGoRepeatStep: 'ListSelectorGoRepeatStep',
            ListSelectorConditionTextContentsContains: 'ListSelectorConditionTextContentsContains',
            ListSelectorConditionTextContentsDoesNotContains: 'ListSelectorConditionTextContentsDoesNotContains',
            ListSelectorConditionWaitUntilElementNotPresentXpath: 'ListSelectorConditionWaitUntilElementNotPresentXpath',
            ListSelectorConditionWaitUntilElementNotPresentTimeout: 'ListSelectorConditionWaitUntilElementNotPresentTimeout',
            ListSelectorConditionUserListedItemsMatchXpath: 'ListSelectorConditionUserListedItemsMatchXpath',
            ListSelectorConditionCombineMultipleTextElement: 'ListSelectorConditionCombineMultipleTextElement',


            ElementConditionUrlContains: 'ElementConditionUrlContains',
            ElementConditionScripts: 'ElementConditionScripts',
            ElementConditionSkipSteps: 'ElementConditionSkipSteps',
            ElementConditionRepeatStepIfFail: 'ElementConditionRepeatStepIfFail',
            ElementConditionXpathElementExist: 'ElementConditionXpathElementExist',
            ElementConditionValueDoesNotMatch: 'ElementConditionValueDoesNotMatch',
            ElementConditionPreviousValueDoesNotMatch: 'ElementConditionPreviousValueDoesNotMatch',
            ElementConditionAPIDataValues: 'ElementConditionAPIDataValues',
            ElementConditionSkipDataLibraryItem: 'ElementConditionSkipDataLibraryItem',
            ElementConditionRemoveDynamicStep: 'ElementConditionRemoveDynamicStep',
            ElementConditionContinueIfElementChanged: 'ElementConditionContinueIfElementChanged',

            AddTimeoutWaitTime: 'AddTimeoutWaitTime',
            AddDaysToCalendar: 'AddDaysToCalendar',
            StepTargetUTCStartTime: 'StepTargetUTCStartTime',
            LurkBefore: 'LurkBefore',
            LurkUntil: 'LurkUntil',
            ClickAndLurk: 'ClickAndLurk',
            PauseBeforeAction: 'PauseBeforeAction',
            PauseAfterAction: 'PauseAfterAction',
            ScrollToBottomScript: 'ScrollToBottomScript',
            SetDataDateOnCalendar: 'SetDataDateOnCalendar',
            EmptyListLabelXpath: 'EmptyListLabelXpath',
            RedirectAfterUrl: 'RedirectAfterUrl',
            ShadowHostXpath: 'ShadowPathXpath',
            RandomValueGeneratorOption: 'RandomValueGeneratorOption',
            ClickLabelHelper: 'ClickLabelHelper',
            ClickActionMultiplierCount: 'ClickActionMultiplierCount',
            APIDataLabelClick: 'APIDataLabelClick',

            SpreadSheetConfigurationSpreadSheetType: 'SpreadSheetConfigurationSpreadSheetType',
            SpreadSheetConfigurationHtmlColumnReaderXpath: 'SpreadSheetConfigurationHtmlColumnReaderXpath',
            SpreadSheetConfigurationHtmlAllRowsXpath: 'SpreadSheetConfigurationHtmlAllRowsXpath',
            SpreadSheetConfigurationHtmlRowColumnValue: 'SpreadSheetConfigurationHtmlRowColumnValue',
            SpreadSheetConfigurationSkipRows: 'SpreadSheetConfigurationSkipRows',
            SpreadSheetConfigurationMergeColumns: 'SpreadSheetConfigurationMergeColumns',
            SpreadSheetConfigurationMergeColumnsWithFormat: 'SpreadSheetConfigurationMergeColumnsWithFormat',
            SpreadSheetConfigurationSetDelimiter: 'SpreadSheetConfigurationSetDelimiter',
            SpreadSheetConfigurationColumnDelimiter: 'SpreadSheetConfigurationColumnDelimiter',
            SpreadSheetConfigurationLocalHtmlHtmlPath: 'SpreadSheetConfigurationLocalHtmlHtmlPath',
            SpreadSheetConfigurationAcceptRowColumns: 'SpreadSheetConfigurationAcceptRowWithColumnValues',
            SpreadSheetConfigurationAcceptRowWithColumnValues: 'SpreadSheetConfigurationAcceptRowColumns',
            SpreadSheetConfigurationRefreshPageAfterRecord: 'SpreadSheetConfigurationRefreshPageAfterRecord',
            SpreadSheetConfigurationDistinctBy: 'SpreadSheetConfigurationDistinctBy',
            SpreadSheetConfigurationEmptyColumns: 'SpreadSheetConfigurationEmptyColumns',
            SpreadSheetConfigurationEmptyColumnValues: 'SpreadSheetConfigurationEmptyColumnValues',
            SpreadSheetConfigurationOECellNavigatorXpath: 'SpreadSheetConfigurationOECellNavigatorXpath',
            SpreadSheetConfigurationOEColumnSearchSkip: 'SpreadSheetConfigurationOEColumnSearchSkip',
            SpreadSheetConfigurationOEFormulaInputXpath: 'SpreadSheetConfigurationOEFormulaInputXpath',
            SpreadSheetConfigurationOEStartingCell: 'SpreadSheetConfigurationOEStartingCell',
            SpreadSheetConfigurationOEColumnValue: 'SpreadSheetConfigurationOEColumnValue',
            SpreadSheetConfigurationOERowValue: 'SpreadSheetConfigurationOERowValue',
            SpreadSheetConfigurationOECellValues: 'SpreadSheetConfigurationOECellValues',
            SpreadSheetConfigurationOECellValueMustBeEmpty: 'SpreadSheetConfigurationOECellValueMustBeEmpty',
            SpreadSheetConfigurationOEStartWithColumn: 'SpreadSheetConfigurationOEStartWithColumn',
            SpreadSheetConfigurationOERowCellSearchValueStartsWith: 'SpreadSheetConfigurationOERowCellSearchValueStartsWith',
            SpreadSheetConfigurationOEColumnCellSearchValueStartsWith: 'SpreadSheetConfigurationOEColumnCellSearchValueStartsWith',
            SpreadSheetConfigurationOEType: 'SpreadSheetConfigurationOEType',
            SpreadSheetConfigurationOERepeatSpreadSheetEntry: 'SpreadSheetConfigurationOERepeatSpreadSheetEntry',
            SpreadSheetConfigurationOEPreventDuplicateEntry: 'SpreadSheetConfigurationOEPreventDuplicateEntry',


            TextFormatterFindText: 'TextFormatterFindText',
            TextFormatterReplaceText: 'TextFormatterReplaceText',
            TextFormatterReplaceTextRegularExpression: 'TextFormatterReplaceTextRegularExpression',
            TextFormatterPlaceBrackets: 'TextFormatterPlaceBrackets',
            TextFormatterConvertToClosesTuesday: 'TextFormatterConvertToClosesTuesday',
            TextFormatterCalculateByCompleteDays: 'TextFormatterCalculateByCompleteDays',
            TextFormatterPlaceParentheses: 'TextFormatterPlaceParentheses',
            TextFormatterDateConverter: 'TextFormatterDateConverter',
            TextFormatterCapitalizeAll: 'TextFormatterCapitalizeAll',
            TextFormatterLowercaseAll: 'TextFormatterLowercaseAll',
            TextFormatterSplitWithDelimiter: 'TextFormatterSplitWithDelimiter',
            TextFormatterAddDaysToCalendar: 'TextFormatterAddDaysToCalendar',
            TextFormatterTruncate: 'TextFormatterTruncate',
            TextFormatterPrefill: 'TextFormatterPrefill',
            TextFormatterPrefillWithExceptions: 'TextFormatterPrefillWithExceptions',
            TextFormatterPreFillExceptionAction: 'TextFormatterPreFillExceptionAction',
            TextFormatterPostFill: 'TextFormatterPostFill',
            TextFormatterRemoveMiddleValue: 'TextFormatterRemoveMiddleValue',
            TextFormatterSwapWithBusinessEliteInterpreters: 'TextFormatterSwapWithBusinessEliteInterpreters',
            TextFormatterWeekOfMonth: 'TextFormatterWeekOfMonth',
            TextFormatterYearOnly: 'TextFormatterYearOnly',
            TextFormatterSkipBusinessEliteInterpreters: 'TextFormatterSkipBusinessEliteInterpreters',
            TextFormatterDateDifference: 'TextFormatterDateDifference',
            TextFormatterDivideValues: 'TextFormatterDivideValues',
            TextFormatterAddDataValuesFromDateDifference: 'TextFormatterAddDataValuesFromDateDifference',

            DownloadDownloadType: 'DownloadDownloadType',
            DownloadStore: 'DownloadStore',
            DownloadExtractStore: 'DownloadExtractStore',
            DownloadExtractLink: 'DownloadExtractLink',
            DownloadExtractLinkStartsWith: 'DownloadExtractLinkStartsWith',
            DownloadExtractLinkEndsWith: 'DownloadExtractLinkEndsWith',
            DownloadExtractFileName: 'DownloadExtractFileName',
            DownloadExtractFileNameStartsWith: 'DownloadExtractFileNameStartsWith',
            DownloadExtractFileNameEndsWith: 'DownloadExtractFileNameEndsWith',
            DownloadDirectory: 'DownloadDirectory',
            DownloadWaitTime: 'DownloadWaitTime',
            DownloadFileType: 'DownloadFileType',

            UploadUploadStoredFiles: 'UploadUploadStoredFiles',
            UploadWaitTime: 'UploadWaitTime',

            DynamicStepCreatorPlacementStep: 'DynamicStepCreatorPlacementStep',
            DynamicStepCreatorStartRange: 'DynamicStepCreatorStartRange',
            DynamicStepCreatorFinishRange: 'DynamicStepCreatorFinishRange',
            DynamicStepCreatorRules: 'DynamicStepCreatorRules',
            DynamicStepCreatorDataCreationIterator: 'DynamicStepCreatorDataCreationIterator',

            TwoFormAuthenticationType: 'TwoFormAuthenticationType',
            TwoFormAuthenticationAcceptPath: 'TwoFormAuthenticationAcceptPath',
            TwoFormAuthenticationEmailConfirmationInstruction: 'TwoFormAuthenticationEmailConfirmationInstruction',
            TwoFormAuthenticationRequireAcceptClick: 'TwoFormAuthenticationRequireAcceptClick',

            APIRequestAPIHttpRequestType: 'APIRequestAPIHttpRequestType',
            APIRequestAPIHttpResponseType: 'APIRequestAPIHttpResponseType',
            APIRequestAPIExternalAPIType: 'APIRequestAPIExternalAPIType',
            APIRequestEndPoint: 'APIRequestEndPoint',
            APIRequestPayloadData: 'APIRequestPayloadData',
            APIRequestReplaceRequestKeys: 'APIRequestReplaceRequestKeys',
            APIRequestReplaceRequestValues: 'APIRequestReplaceRequestValues',
            APIRequestRequestContainsTimeStamp: 'APIRequestRequestContainsTimeStamp',
            APIRequestEncodeRequestNameValue: 'APIRequestEncodeRequestNameValue',
            APIRequestEncodeRequestURLValue: 'APIRequestEncodeRequestURLValue',
            APIRequestRemoveDomainProtocols: 'APIRequestRemoveDomainProtocols',
            APIRequestStoreResponseValues: 'APIRequestStoreResponseValues',
            APIRequestRequestNewResultsByID: 'APIRequestRequestNewResultsByID',
            APIRequestRequestReverseResults: 'APIRequestRequestReverseResults',
            APIRequestMessageAllAPIResponses: 'APIRequestMessageAllAPIResponses',
            APIRequestMergeResults: 'APIRequestMergeResults',
            APIRequestGetArrayFromRootObject: 'APIRequestGetArrayFromRootObject',
            APIRequestMergePropertiesWithFormat: 'APIRequestMergePropertiesWithFormat',
            APIRequestSetDelimiter: 'APIRequestSetDelimiter',
            APIRequestPropertyDelimiter: 'APIRequestPropertyDelimiter',
            APIRequestRootIgnores: 'APIRequestRootIgnores',
            APIRequestCatchAllItems: 'APIRequestCatchAllItems',
            APIRequestIgnoreAllItems: 'APIRequestIgnoreAllItems',
            APIRequestGroupResultsBy: 'APIRequestGroupResultsBy',
            APIRequestGroupByTargetProperty: 'APIRequestGroupByTargetProperty',
            APIRequestGroupMergeName: 'APIRequestGroupMergeName',
            APIRequestGroupMergeValue: 'APIRequestGroupMergeValue',
            APIRequestChangeStep: 'APIRequestChangeStep',
            APIRequestSkipToStep: 'APIRequestSkipToStep',
            APIRequestGoToStepAfterComplete: 'APIRequestGoToStepAfterComplete',

            ApplyJSClick: 'ApplyJSClick',
            ApplyDateRangeAdditionalClick: 'ApplyDateRangeAdditionalClick',
            ApplyTwoDayCalendarClick: 'ApplyTwoDayCalendarClick',
            ApplyRepeatStep: 'ApplyRepeatStep',
            ApplyDragAndDrop: 'ApplyDragAndDrop',
            ApplySubStringInput: 'ApplySubStringInput',
            ApplyIgnoreFail: 'ApplyIgnoreFail',
            ApplyUserLabelClick: 'ApplyUserLabelClick',
            ApplyRefreshAction: 'ApplyRefreshAction',
            ApplySubmitForm: 'ApplySubmitForm',
            ApplyTroubleShootStep: 'ApplyTroubleShootStep',
            ApplyAfterLoginStep: 'ApplyAfterLoginStep',
            ApplyShowViewerStatusStep: 'ApplyShowViewerStatusStep',
            ApplyApplyToAllElements: 'ApplyApplyToAllElements',
            ApplyBackRepeatStep: 'ApplyBackRepeatStep',
            ApplyTrackAction: 'ApplyTrackAction',
            ApplyFailTracker: 'ApplyFailTracker',
            ApplySwapAPIPayloadData: 'ApplySwapAPIPayloadData',
            ApplyStoreAPIResponseValues: 'ApplyStoreAPIResponseValues',
            ApplyRandomizeElementSelection: 'ApplyRandomizeElementSelection',
            ApplyScrollToElement: 'ApplyScrollToElement',
            ApplySelectOptionValue: 'ApplySelectOptionValue',
            ApplySelectOptionIndex: 'ApplySelectOptionIndex',
            ApplyConfirmStepSuccess: 'ApplyConfirmStepSuccess',
            ApplySelectAll: 'ApplySelectAll',
            ApplySelectAllAndPrint: 'ApplySelectAllAndPrint',
            ApplyHrefNav: 'ApplyHrefNav',
            ApplyGroupAction: 'ApplyGroupAction',
            ApplyNodeKeyboardType: 'ApplyNodeKeyboardType',
            ApplyNodeMoveToElement: 'ApplyNodeMoveToElement',
            ApplyNodeClick: 'ApplyNodeClick',
            ApplyUserCoordinatesClick: 'ApplyUserCoordinatesClick',
            ApplyScrollToBottom: 'ApplyScrollToBottom',
            ApplyChildActionSelectOption: 'ApplyChildActionSelectOption',
            ApplyIncludeLabelInSearch: 'ApplyIncludeLabelInSearch',
            ApplyIncludeBasicDateInSearch: 'ApplyIncludeBasicDateInSearch',
            ApplyUtcStartTimeSearch: 'ApplyUtcStartTimeSearch',
            ApplyDetachedRowAction: 'ApplyDetachedRowAction',
            ApplyWaitUntilPresentListSelector: 'ApplyWaitUntilPresentListSelector',
            ApplyDoubleClickListItem: 'ApplyDoubleClickListItem',
            ApplyRefreshAndGo: 'ApplyRefreshAndGo',
            ApplyDoubleClickElement: 'ApplyDoubleClickElement',
            ApplyMainRowAction: 'ApplyMainRowAction',
            ApplyInnerRowAction: 'ApplyInnerRowAction',
            MainElementControlID: 'MainElementControlID',
            MainElementControlClass: 'MainElementControlClass',
            MainElementControlXPath: 'MainElementControlXPath',
            suspensionReasonsSelection: '',
            suspensionMessage: '',
            verifyUrl: 'verify/html?botId=',
            liveFeedMessages: '',
            allSavedData: [],
            selectedDatePicker: 'MATDATEPICKER',
            syncButtonText: 'Sync All HTML',
            isSyncButtonDisabled: false,
            showAssignBotModal: false,
            engineers: [],
            tiers: [],
            selectedEngineer: '',
            assignMessage: '',
            selectedTier: '',
            selectedEngineerUserId: '',
            disableSaveButton: false,
            hideSuccessLabel: true,
            publicShareUrl: '',
            publicShareModalShow: false,
            publicShareCopyButtonText: 'Copy',
            isMigrating: false,
            configOriginalBotId: '',
            copyConfigModalShow: false,
            miniBotTypes: [],
            randomGeneratorOptions: [],
            spreadSheetTypeOptions: [],
            oeTypeOptions: [],
            textFormatterPreFillExceptionActionOptions: [],
            downloadTypeOptions: [],
            apiHttpRequestTypeOptions: [],
            apiHttpResponseTypeOptions: [],
            externalAPITypeOptions: [],
            createMiniBot: false,
            miniSearchType: '',
            randomValueOption: '',
            miniBotName: '',
            miniBotNotes: '',
            runningBotUrl: '',
            liveBotViewModalShow: false,
            liveBotName: '',
            assignDueDate: '',
            assignedEngineer: '',
            assignedBotDueDate: '',
            showDeleteElementButton: false,
            bzE: false,
            bzeTier: null
        };
    }
    componentDidMount() {
        const rootStore = this.props.rootStore;
        var botId = getParameterByName('bid', window.location.href);
        if (!botId) {
            alert('Missing Information. Please try again.');
            window.location = '/';
        }
        this.setState({ botId: botId }, () => this.getCaptchaVersionData())
    }
    getCaptchaVersionData = () => {
        ApiClient.get('bothtml/captcha/version/support').then((response) => {
            if (response) {
                this.setState({ captchaData: response.data }, () => {
                    this.getSuspensionReasons();
                })
            }
        }).catch((error) => { });
    }
    getSuspensionReasons = () => {
        ApiClient.get('bothtml/suspension/reasons').then((response1) => {
            if (response1) {
                this.setState({ suspensionReasons: response1.data }, () => {
                    this.getDatePickers();
                })
            }
        }).catch((error) => { });
    }
    getDatePickers = () => {
        ApiClient.get('bothtml/supported/date-pickers').then((response2) => {
            if (response2) {
                this.setState({ datePickers: response2.data }, () => {
                    this.getMiniBots();
                })
            }
        }).catch((error) => { });
    }
    getMiniBots = () => {
        ApiClient.get('minibot/all').then((response) => {
            this.setState({ miniBotTypes: response.data }, () => {
                this.getRandomGenerateOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getRandomGenerateOptions = () => {
        ApiClient.get('bothtml/supported/randomgenerator/options').then((response) => {
            this.setState({ randomGeneratorOptions: response.data }, () => {
                this.getPreFillActionOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getPreFillActionOptions = () => {
        ApiClient.get('bothtml/supported/prefill/action/options').then((response) => {
            this.setState({ textFormatterPreFillExceptionActionOptions: response.data }, () => {
                this.getOnlineEditorSpreadSheetTypeOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getOnlineEditorSpreadSheetTypeOptions = () => {
        ApiClient.get('bothtml/supported/online/spreadsheet/types').then((response) => {
            this.setState({ oeTypeOptions: response.data }, () => {
                this.getSpreadSheetTypeOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getSpreadSheetTypeOptions = () => {
        ApiClient.get('bothtml/supported/spreadsheet/type/options').then((response) => {
            this.setState({ spreadSheetTypeOptions: response.data }, () => {
                this.getDownloadTypeOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getDownloadTypeOptions = () => {
        ApiClient.get('bothtml/supported/download/type/options').then((response) => {
            this.setState({ downloadTypeOptions: response.data }, () => {
                this.getAPIHttpRequestTypeOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getAPIHttpRequestTypeOptions = () => {
        ApiClient.get('bothtml/supported/http/request/type/options').then((response) => {
            this.setState({ apiHttpRequestTypeOptions: response.data }, () => {
                this.getAPIHttpResponseTypeOptions();
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getAPIHttpResponseTypeOptions = () => {
        ApiClient.get('bothtml/supported/http/response/type/options').then((response) => {
            this.setState({ apiHttpResponseTypeOptions: response.data }, () => {
                this.getExternalAPITypeOptions()
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    getExternalAPITypeOptions = () => {
        ApiClient.get('bothtml/supported/external/api/type/options').then((response) => {
            this.setState({ externalAPITypeOptions: response.data }, () => {
                var searchLinked = getParameterByName('linked', window.location.href);
                this.getHtmlData(searchLinked && searchLinked == 'true');
            });
        }).catch((error) => {
            alert(error.response.error)
        });
    }
    miniBotListChange = (e) => {
        this.setState({ miniSearchType: e.target.text }, () => {
            this.getMiniBots();
        });
    }
    createMiniBotNameChange = (e) => {
        this.setState({ createMiniBot: e.target.checked })
    }
    miniBotNotesChange = (e) => {
        this.setState({ miniBotNotes: e.target.value })
    }
    changeMiniBotName = (e) => {
        this.setState({ miniBotName: e.target.value })
    }
    randomValueOptionChange = (e) => {
        this.setState({ randomValueOption: e.target.text == "Which Random Value?" ? null : e.target.text });
    }
    getHtmlData = (isLinked) => {
        ApiClient.get('bothtml/' + (isLinked ? 'linked' : 'unlinked') + '/next/' + this.state.botId).then((response3) => {
            if (response3) {
                this.setState({
                    htmlData: response3.data, selectedDatePicker: response3.data && response3.data.datePickerType ? response3.data.datePickerType : 'MATDATEPICKER',
                    bzE: response3.data.bzE, bzeTier: response3.data.bzeTier
                }, () => {
                    this.getTiers();
                })
            }
        }).catch((error) => { });
    }
    getShowBypassButton = () => {
        ApiClient.get('user/allow/html/passive').then((response2) => {
            if (response2) {
                this.setState({ showAllowBypassHtmlButton: response2.data })
            }
        }).catch((error) => { });
    }
    getTiers = () => {
        ApiClient.get('bot/service/supported/tiers').then((response2) => {
            if (response2) {
                this.setState({ tiers: response2.data }, () => {
                    this.getEngineers();
                })
            }
        }).catch((error) => { });
    }
    getEngineers = () => {
        ApiClient.get('user/task/assignees').then((response2) => {
            if (response2) {
                this.setState({ engineers: response2.data }, () => {
                    ApiClient.get('bot/service/task/assignment/' + this.state.botId).then((response3) => {
                        if (response3) {
                            this.setState({ assignedEngineer: response3.data.assignedUserFullName, assignedBotDueDate: response3.data.dueDate }, () => {
                                this.getShowBypassButton();
                            });
                        }
                    }).catch((error) => { });
                });
            }
        }).catch((error) => { });
    }
    onShowModalFactory = (show, modalName, i, i2, allowRemove) => () => {
        if (show) {
            this.setModalTempData(modalName, i, i2);
            this.setState({ currentHtmlControlIndex: i, currenInnerHtmlControl: i2, showDeleteElementButton: allowRemove }, () => {
                this.showModal(modalName, show)
            })
        }
        else this.showModal(modalName, show)
    }
    setModalTempData = (which, index, i) => {
        switch (which) {
            case this.state.GetValueFromOtherElement:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.getValueFromOtherElement)
                    this.setState(
                        {
                            tempGetValueFromOtherElementData: {
                                ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                    update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.getValueFromOtherElement })
                            }
                        });
                break;
            case this.state.ModalCloser:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.modalCloser)
                    this.setState(
                        {
                            tempModalCloserData: {
                                ...this.state.tempModalCloserData, modalCloser:
                                    update(this.state.tempModalCloserData.modalCloser, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.modalCloser })
                            }
                        });
                break;
            case this.state.CaptchaSolver:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.captchaSolver)
                    this.setState(
                        {
                            tempCaptchaSolverData: {
                                ...this.state.tempCaptchaSolverData, captchaSolver:
                                    update(this.state.tempCaptchaSolverData.captchaSolver, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.captchaSolver })
                            }
                        });
                break;
            case this.state.IFrameSwitcher:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.frameSwitch)
                    this.setState(
                        {
                            tempIframeSwitcher: {
                                ...this.state.tempIframeSwitcher, frameSwitch:
                                    update(this.state.tempIframeSwitcher.frameSwitch, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.frameSwitch })
                            }
                        });
                break;
            case this.state.InfiniteScroll:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.infiniteScroll)
                    this.setState(
                        {
                            tempInfiniteScroll: {
                                ...this.state.tempInfiniteScroll, infiniteScroll:
                                    update(this.state.tempInfiniteScroll.infiniteScroll, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.infiniteScroll })
                            }
                        });
                break;
            case this.state.ListSelectorCondition:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.listSelectorCondition)
                    this.setState(
                        {
                            tempListSelectorCondition: {
                                ...this.state.tempListSelectorCondition, listSelectorCondition:
                                    update(this.state.tempListSelectorCondition.listSelectorCondition, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.listSelectorCondition })
                            }
                        });
                break;
            case this.state.ElementCondition:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.elementCondition)
                    this.setState(
                        {
                            tempElementCondition: {
                                ...this.state.tempElementCondition, elementCondition:
                                    update(this.state.tempElementCondition.elementCondition, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.elementCondition })
                            }
                        });
                break;
            case this.state.TimeoutWait:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.timeoutWaitTime)
                    this.setState({ tempTimeoutWaitTime: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.timeoutWaitTime })
                break;
            case this.state.AddToCalendar:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.addDaysToCalendar)
                    this.setState({ tempAddDaysToCalendar: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.addDaysToCalendar })
                break;
            case this.state.StepTargetStartTime:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.stepTargetUtcStartTime)
                    this.setState({ tempStepTargetStartTime: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.stepTargetUtcStartTime })
                break;
            case this.state.LurkBefore:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.lurkBefore)
                    this.setState({ tempLurkBefore: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.lurkBefore })
                break;
            case this.state.LurkUntil:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.lurkUntil)
                    this.setState({ tempLurkUntil: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.lurkUntil })
                break;
            case this.state.ClickAndLurk:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.clickAndLurk)
                    this.setState({ tempClickAndLurk: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.clickAndLurk })
                break;
            case this.state.GoRepeatStep:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.goRepeatStep)
                    this.setState({ tempGoRepeatStep: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.goRepeatStep })
                break;
            case this.state.PauseBefore:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.pauseBeforeAction)
                    this.setState({ tempPauseBeforeAction: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.pauseBeforeAction })
                break;
            case this.state.PauseAfter:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.pauseAfterAction)
                    this.setState({ tempPauseAfterAction: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.pauseAfterAction })
                break;
            case this.state.ScrollToBottomScript:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.scrollToBottomScript)
                    this.setState({ tempScrollToBottomScript: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.scrollToBottomScript })
                break;
            case this.state.SetDataDateOnCalendar:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.setDataDateOnCalendar)
                    this.setState({ tempSetDataDateOnCalendar: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.setDataDateOnCalendar })
                break;
            case this.state.ModalEmptyListLabelXpath:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.emptyListLabelXpath)
                    this.setState({ tempEmptyListLabelXpath: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.emptyListLabelXpath })
                break;
            case this.state.RedirectAfter:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.redirectAfterUrl)
                    this.setState({ tempRedirectAfterUrl: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.redirectAfterUrl })
                break;
            case this.state.ShadowHostXpath:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.shadowRootHost)
                    this.setState({ tempShadowHostXpath: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.shadowRootHost })
                break;
            case this.state.RandomValueGeneratorOption:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.randomValueGenerator != null)
                    this.setState({
                        randomValueOption:
                            typeof this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.randomValueGenerator === 'number' ?
                                this.state.randomGeneratorOptions[this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.randomValueGenerator] :
                                this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.randomValueGenerator
                    })
                break;
            case this.state.TwoClickSelect:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.twoClickSelector)
                    this.setState(
                        {
                            tempTwoClickSelector: {
                                ...this.state.tempTwoClickSelector, twoClickSelector:
                                    update(this.state.tempTwoClickSelector.twoClickSelector, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.twoClickSelector })
                            }
                        });
                break;
            case this.state.TabSplit:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.tabSplit)
                    this.setState(
                        {
                            tempTabSplit: {
                                ...this.state.tempTabSplit, tabSplit:
                                    update(this.state.tempTabSplit.tabSplit, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.tabSplit })
                            }
                        });
                break;
            case this.state.TwoFormAuthentication:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.twoFormAuthentication)
                    this.setState(
                        {
                            tempTwoFormAuthentication: {
                                ...this.state.tempTwoFormAuthentication, twoFormAuthentication:
                                    update(this.state.tempTwoFormAuthentication.twoFormAuthentication, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.twoFormAuthentication })
                            }
                        });
                break;
            case this.state.ClickLabelHelper:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.clickLabelHelper)
                    this.setState(
                        {
                            tempClickLabelHelper: {
                                ...this.state.tempClickLabelHelper, clickLabelHelper:
                                    update(this.state.tempClickLabelHelper.clickLabelHelper, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.clickLabelHelper })
                            }
                        });
                break;
            case this.state.ClickActionMultiplierCount:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.clickActionMultiplierCount)
                    this.setState(
                        {
                            tempClickActionMultiplierCount: {
                                ...this.state.tempClickActionMultiplierCount, clickActionMultiplierCount:
                                    update(this.state.tempClickActionMultiplierCount.clickActionMultiplierCount, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.clickActionMultiplierCount })
                            }
                        });
                break;
            case this.state.APIDataLabelClick:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.apiDataLabelClick)
                    this.setState(
                        {
                            tempAPIDataLabelClick: {
                                ...this.state.tempAPIDataLabelClick, apiDataLabelClick:
                                    update(this.state.tempAPIDataLabelClick.apiDataLabelClick, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.apiDataLabelClick })
                            }
                        });
                break;
            case this.state.SpreadSheetConfiguration:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.spreadSheetConfiguration) {
                    this.setState(
                        {
                            tempSpreadSheetConfiguration: {
                                ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                    update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.spreadSheetConfiguration })
                            }
                        });
                }
                break;
            case this.state.Download:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.download) {
                    this.setState(
                        {
                            tempDownload: {
                                ...this.state.tempDownload, download:
                                    update(this.state.tempDownload.download, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.download })
                            }
                        });
                }
                break;
            case this.state.Upload:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.upload) {
                    this.setState(
                        {
                            tempUpload: {
                                ...this.state.tempUpload, upload:
                                    update(this.state.tempUpload.upload, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.upload })
                            }
                        });
                }
                break;
            case this.state.TextFormatter:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.textFormatter)
                    this.setState(
                        {
                            tempTextFormatter: {
                                ...this.state.tempTextFormatter, textFormatter:
                                    update(this.state.tempTextFormatter.textFormatter, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.textFormatter })
                            }
                        });
                break;
            case this.state.APIRequest:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.apiRequest)
                    this.setState(
                        {
                            tempAPIRequest: {
                                ...this.state.tempAPIRequest, apiRequest:
                                    update(this.state.tempAPIRequest.apiRequest, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.apiRequest })
                            }
                        });
                break;
            case this.state.DynamicStepCreator:
                if (this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.dynamicStepCreator)
                    this.setState(
                        {
                            tempDynamicStepCreator: {
                                ...this.state.tempDynamicStepCreator, dynamicStepCreator:
                                    update(this.state.tempDynamicStepCreator.dynamicStepCreator, { $set: this.state.htmlData.htmlControls[index].controls[i].htmlElementControl.dynamicStepCreator })
                            }
                        });
                break;
        }
    }

    showModal(which, show) {
        switch (which) {
            case this.state.GetValueFromOtherElement:
                this.setState({ addGetValueFromElementModalShow: show })
                break;
            case this.state.ModalCloser:
                this.setState({ addModalCloserModalShow: show })
                break;
            case this.state.CaptchaSolver:
                this.setState({ addCaptchaSolverModalShow: show })
                break;
            case this.state.IFrameSwitcher:
                this.setState({ iFrameSwitcherModalShow: show })
                break;
            case this.state.InfiniteScroll:
                this.setState({ infiniteScrollModalShow: show })
                break;
            case this.state.ListSelectorCondition:
                this.setState({ listSelectorConditionModalShow: show })
                break;
            case this.state.ElementCondition:
                this.setState({ elementConditionModalShow: show })
                break;
            case this.state.TimeoutWait:
                this.setState({ addTimeoutWaitModalShow: show })
                break;
            case this.state.AddToCalendar:
                this.setState({ addDaysToCalendarModalShow: show })
                break;
            case this.state.StepTargetStartTime:
                this.setState({ stepTargetUtcStartTimeModalShow: show })
                break;
            case this.state.LurkBefore:
                this.setState({ lurkBeforeModalShow: show })
                break;
            case this.state.LurkUntil:
                this.setState({ lurkUntilModalShow: show })
                break;
            case this.state.ClickAndLurk:
                this.setState({ clickAndLurkModalShow: show })
                break;
            case this.state.GoRepeatStep:
                this.setState({ goRepeatStepModalShow: show })
                break;
            case this.state.PauseBefore:
                this.setState({ pauseBeforeActionModalShow: show })
                break;
            case this.state.PauseAfter:
                this.setState({ pauseAfterActionModalShow: show })
                break;
            case this.state.ScrollToBottomScript:
                this.setState({ scrollToBottomScriptModalShow: show })
                break;
            case this.state.SetDataDateOnCalendar:
                this.setState({ setDataDateOnCalendarModalShow: show })
                break;
            case this.state.ModalEmptyListLabelXpath:
                this.setState({ emptyListLabelXpathModalShow: show })
                break;
            case this.state.RedirectAfter:
                this.setState({ redirectAfterUrlModalShow: show })
                break;
            case this.state.ShadowHostXpath:
                this.setState({ addShadowHostModalShow: show })
                break;
            case this.state.RandomValueGeneratorOption:
                this.setState({ randomValueGeneratorModalShow: show })
                break;
            case this.state.TwoClickSelect:
                this.setState({ twoClickSelectorModalShow: show })
                break;
            case this.state.TabSplit:
                this.setState({ tabSplitModalShow: show })
                break;
            case this.state.TwoFormAuthentication:
                this.setState({ twoFormAuthenticationModalShow: show })
                break;
            case this.state.ClickLabelHelper:
                this.setState({ clickLabelHelperModalShow: show })
                break;
            case this.state.ClickActionMultiplierCount:
                this.setState({ clickActionMultiplierCountModalShow: show })
                break;
            case this.state.APIDataLabelClick:
                this.setState({ apiDataLabelClickModalShow: show })
                break;
            case this.state.SpreadSheetConfiguration:
                this.setState({ spreadSheetConfigurationModalShow: show })
                break;
            case this.state.TextFormatter:
                this.setState({ textFormatterModalShow: show })
                break;
            case this.state.Download:
                this.setState({ downloadModalShow: show })
                break;
            case this.state.Upload:
                this.setState({ uploadModalShow: show })
                break;
            case this.state.APIRequest:
                this.setState({ apiRequestModalShow: show })
                break;
            case this.state.DynamicStepCreator:
                this.setState({ dynamicStepCreatorModalShow: show })
                break;
        }
    }
    onDatePickerSelect = (value) => {
        //Todo on select resync bot to match date picker type and save selection. On load auto select type. On save ensure date picker is saved to each html element 
        if (window.confirm("Are you sure you want to change this Bots Date Picker type to " + value)) {
            this.setState({ selectedDatePicker: value }, () => {
                ApiClient.post('bothtml/calendar/type/change/' + value + '/' + this.state.botId, {}).then((response) => {
                    window.location.reload();
                }).catch((error) => { });
            })

        }
    }

    onDataChange = (property, index, innerIndex) => (e) => {
        switch (property) {
            case this.state.GetValueFromOtherElementOtherElementXpath:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { otherElementXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.GetValueFromOtherElementAlternativeElementXpath:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { alternativeElementXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.GetValueFromOtherElementIncreaseValueBy:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { increaseValueBy: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.GetValueFromOtherElementIncreaseValueByAlternative:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { increaseValueByAlternative: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.GetValueFromOtherElementElementType:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { elementType: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.GetValueFromOtherElementIsTimeValue:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { isTimeValue: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.GetValueFromOtherElementIsAmPmFormat:
                this.setState
                    ({
                        tempGetValueFromOtherElementData: {
                            ...this.state.tempGetValueFromOtherElementData, getValueFromOtherElement:
                                update(this.state.tempGetValueFromOtherElementData.getValueFromOtherElement, { isAmPmFormat: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ModalCloserElementId:
                this.setState
                    ({
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { elementId: { $set: e.target.value }, class: { $set: "" }, xPath: { $set: "" } })
                        }
                    });
                break;
            case this.state.ModalCloserClass:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { elementId: { $set: "" }, class: { $set: e.target.value }, xPath: { $set: "" } })
                        }
                    });
                break;
            case this.state.ModalCloserXpath:
                this.setState({
                    tempModalCloserData: {
                        ...this.state.tempModalCloserData, modalCloser:
                            update(this.state.tempModalCloserData.modalCloser, { elementId: { $set: "" }, Class: { $set: "" }, xPath: { $set: e.target.value } })
                    }
                });
                break;
            case this.state.ModalCloserCloseBefore:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { closeBefore: { $set: e.target.checked }, closeAfter: { $set: false } })
                        }
                    });
                break;
            case this.state.ModalCloserCloseAfter:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { closeBefore: { $set: false }, closeAfter: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ModalCloserWaitUntilNotVisible:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { waitUntilNotVisible: { $set: e.target.checked }, clickDismiss: { $set: false } })
                        }
                    });
                break;
            case this.state.ModalCloserIsPresent:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { isPresent: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ModalCloserWaitUntilNotVisibleTimeLength:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { waitUntilNotVisibleTimeLength: { $set: e.target.value }, waitUntilNotVisible: { $set: true }, clickDismiss: { $set: false } })
                        }
                    });
                break;
            case this.state.ModalCloserRemoveWaitTimeLength:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { removeWaitTimeLength: { $set: e.target.value }, waitUntilNotVisible: { $set: false }, clickDismiss: { $set: false } })
                        }
                    });
                break;
            case this.state.ModalCloserThenScript:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { thenScript: { $set: e.target.value }, waitUntilNotVisible: { $set: false } })
                        }
                    });
                break;
            case this.state.ModalCloserClickDismiss:
                this.setState(
                    {
                        tempModalCloserData: {
                            ...this.state.tempModalCloserData, modalCloser:
                                update(this.state.tempModalCloserData.modalCloser, { clickDismiss: { $set: e.target.checked }, waitUntilNotVisibleTimeLength: { $set: "" }, removeWaitTimeLength: { $set: "" }, waitUntilNotVisible: { $set: false } })
                        }
                    });
                break;

            case this.state.CaptchaSolverVersion:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { version: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverType:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { captchaType: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverRunPoseCallback:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { callBack: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverIsHCaptcha:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { isHCaptcha: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverUseCaptchaExtension:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { useCaptchaExtension: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverUseCaptchaExtensionThenClickButton:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { useCaptchaExtensionThenClickButton: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverCaptchaExtensionLoadTimeDelay:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { captchaExtensionLoadTimeDelay: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverForeteesCaptchaSolver:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { foreteesCaptchaSolver: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverScriptKey:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { isScriptKey: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverGeeTest:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { geeTest: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverRunHardPostCallback:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { hardCallBack: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverCheckIfCaptchaExist:
                this.setState({
                    tempCaptchaSolverData: {
                        ...this.state.tempCaptchaSolverData, captchaSolver:
                            update(this.state.tempCaptchaSolverData.captchaSolver, { captchaExistCheck: { $set: e.target.checked } })
                    }
                });
                break;
            case this.state.CaptchaSolverExtractKeyFromSrcUrl:
                this.setState({
                    tempCaptchaSolverData: {
                        ...this.state.tempCaptchaSolverData, captchaSolver:
                            update(this.state.tempCaptchaSolverData.captchaSolver, { extractKeyFromSrcUrl: { $set: e.target.checked } })
                    }
                });
                break;
            case this.state.CaptchaSolverOverrideCallback:
                this.setState({
                    tempCaptchaSolverData: {
                        ...this.state.tempCaptchaSolverData, captchaSolver:
                            update(this.state.tempCaptchaSolverData.captchaSolver, { overrideCallback: { $set: e.target.checked } })
                    }
                });
                break;
            case this.state.CaptchaSolverV1Base64Image:
                this.setState({
                    tempCaptchaSolverData: {
                        ...this.state.tempCaptchaSolverData, captchaSolver:
                            update(this.state.tempCaptchaSolverData.captchaSolver, { v1Base64Image: { $set: e.target.checked } })
                    }
                });
                break;
            case this.state.CaptchaSolverTokenFunction:
                this.setState({
                    tempCaptchaSolverData: {
                        ...this.state.tempCaptchaSolverData, captchaSolver:
                            update(this.state.tempCaptchaSolverData.captchaSolver, { tokenFunction: { $set: e.target.value } })
                    }
                });
                break;
            case this.state.CaptchaSolverXpathSiteKey:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { xPathCaptchaSiteKey: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverResponseXpath:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { captchaResponseXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverV1ElementId:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { v1CaptchaElementId: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverV1ElementInputFieldXpath:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { v1ElementInputFieldXPath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverV1VerifySkipCount:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { v1VerifySkipCount: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.CaptchaSolverVerifyCaptcha:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { verifyCaptcha: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverRefreshBeforeSolving:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { refreshBeforeSolving: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.CaptchaSolverCaptchaExtensionXpath:
                this.setState(
                    {
                        tempCaptchaSolverData: {
                            ...this.state.tempCaptchaSolverData, captchaSolver:
                                update(this.state.tempCaptchaSolverData.captchaSolver, { captchaExtensionXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherElementId:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { elementId: { $set: e.target.value }, class: { $set: "" }, xPath: { $set: "" } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherClass:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { elementId: { $set: "" }, class: { $set: e.target.value }, xPath: { $set: "" } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherXpath:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { elementId: { $set: "" }, class: { $set: "" }, xPath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherWhichWindow:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { whichWindow: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherPauseBeforeSwitch:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { pauseBeforeSwitch: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherClickAndSwitch:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { clickSwitch: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherSkipSwitchBack:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { skipIFrameSwitchBack: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherIncludeDynamicStepCount:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { includeDynamicStepCount: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherExcludeExtraWindowCount:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { excludeExtraWindowCount: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.IFrameSwitcherIgnoreFail:
                this.setState(
                    {
                        tempIframeSwitcher: {
                            ...this.state.tempIframeSwitcher, frameSwitch:
                                update(this.state.tempIframeSwitcher.frameSwitch, { ignoreFail: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.InfiniteScrollScrollScript:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { scrollScript: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollScrollSection:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { scrollSection: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollScrollToTopScript:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { scrollToTopScript: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollWaitTimeBetweenScroll:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { waitTimeBetweenScroll: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollWaitUntilNotVisible:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { waitUntilNotVisible: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollItemsToHandlePerPage:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { itemsToHandlePerPage: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollSkipFirstItems:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { skipFirstItems: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollOpenItemsInTab:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { openItemsInTab: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.InfiniteScrollPrefillNewTabUrl:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { preFillNewTabUrl: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.InfiniteScrollSectionHeightScript:
                this.setState(
                    {
                        tempInfiniteScroll: {
                            ...this.state.tempInfiniteScroll, infiniteScroll:
                                update(this.state.tempInfiniteScroll.infiniteScroll, { sectionHeightScript: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionItemCounterGreaterThan:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { itemCounterGreaterThan: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionGoRepeatStep:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { goRepeatStep: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionTextContentsContains:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { textContentsContains: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionUserListedItemsMatchXpath:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { userListedItemsMatchXpath: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionCombineMultipleTextElement:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { combineMultipleTextElement: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionTextContentsDoesNotContains:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { textContentsDoesNotContains: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionWaitUntilElementNotPresentXpath:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { waitUntilElementNotPresentXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ListSelectorConditionWaitUntilElementNotPresentTimeout:
                this.setState(
                    {
                        tempListSelectorCondition: {
                            ...this.state.tempListSelectorCondition, listSelectorCondition:
                                update(this.state.tempListSelectorCondition.listSelectorCondition, { waitUntilElementNotPresentTimeout: { $set: e.target.value ? parseInt(e.target.value) : null } })
                        }
                    });
                break;
            case this.state.ElementConditionUrlContains:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { urlContains: { $set: e.target.value }, xpathElementExist: { $set: null }, apiDataValues: { $set: null }, valueDoesNotMatch: { $set: null }, previousValueDoesNotMatch: { $set: null } })
                        }
                    });
                break;
            case this.state.ElementConditionXpathElementExist:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { xpathElementExist: { $set: e.target.value }, urlContains: { $set: null }, valueDoesNotMatch: { $set: null }, previousValueDoesNotMatch: { $set: null } })
                        }
                    });
                break;
            case this.state.ElementConditionValueDoesNotMatch:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { valueDoesNotMatch: { $set: e.target.value }, urlContains: { $set: null }, xpathElementExist: { $set: null }, previousValueDoesNotMatch: { $set: null } })
                        }
                    });
                break;
            case this.state.ElementConditionPreviousValueDoesNotMatch:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { previousValueDoesNotMatch: { $set: e.target.value }, urlContains: { $set: null }, xpathElementExist: { $set: null }, valueDoesNotMatch: { $set: null } })
                        }
                    });
                break;
            case this.state.ElementConditionAPIDataValues:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { apiDataValues: { $set: e.target.value }, urlContains: { $set: null } })
                        }
                    });
                break;
            case this.state.ElementConditionScripts:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { scripts: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ElementConditionSkipSteps:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { skipSteps: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ElementConditionRepeatStepIfFail:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { repeatStepIfFail: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.ElementConditionSkipDataLibraryItem:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { skipDataLibraryItem: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ElementConditionRemoveDynamicStep:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { removeDynamicStep: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ElementConditionContinueIfElementChanged:
                this.setState(
                    {
                        tempElementCondition: {
                            ...this.state.tempElementCondition, elementCondition:
                                update(this.state.tempElementCondition.elementCondition, { continueIfElementChanged: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.AddTimeoutWaitTime:
                this.setState({ tempTimeoutWaitTime: e.target.value })

                break;
            case this.state.AddDaysToCalendar:
                this.setState({ tempAddDaysToCalendar: e.target.value })

                break;
            case this.state.StepTargetUTCStartTime:
                this.setState({ tempStepTargetStartTime: e.target.value })
                break;
            case this.state.LurkBefore:
                this.setState({ tempLurkBefore: e.target.value })
                break;
            case this.state.LurkUntil:
                this.setState({ tempLurkUntil: e.target.value })
                break;
            case this.state.ClickAndLurk:
                this.setState({ tempClickAndLurk: e.target.value })
                break;
            case this.state.GoRepeatStep:
                this.setState({ tempGoRepeatStep: e.target.value })
                break;
            case this.state.PauseBeforeAction:
                this.setState({ tempPauseBeforeAction: e.target.value })
                break;
            case this.state.PauseAfterAction:
                this.setState({ tempPauseAfterAction: e.target.value })
            case this.state.ScrollToBottomScript:
                this.setState({ tempScrollToBottomScript: e.target.value })
            case this.state.SetDataDateOnCalendar:
                this.setState({ tempSetDataDateOnCalendar: e.target.value })
            case this.state.EmptyListLabelXpath:
                this.setState({ tempEmptyListLabelXpath: e.target.value })
                break;
            case this.state.RedirectAfterUrl:
                this.setState({ tempRedirectAfterUrl: e.target.value })
                break;
            case this.state.ShadowHostXpath:
                this.setState({ tempShadowHostXpath: e.target.value })
                break;
            case this.state.TwoClickSelector:
                this.setState(
                    {
                        tempTwoClickSelector: {
                            ...this.state.tempTwoClickSelector, twoClickSelector:
                                update(this.state.tempTwoClickSelector.twoClickSelector, { xPath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TabSplit:
                this.setState(
                    {
                        tempTabSplit: {
                            ...this.state.tempTabSplit, tabSplit:
                                update(this.state.tempTabSplit.tabSplit, { numberOfTabsTextXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TwoFormAuthenticationType:
                this.setState(
                    {
                        tempTwoFormAuthentication: {
                            ...this.state.tempTwoFormAuthentication, twoFormAuthentication:
                                update(this.state.tempTwoFormAuthentication.twoFormAuthentication, { authenticationType: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TwoFormAuthenticationEmailConfirmationInstruction:
                this.setState(
                    {
                        tempTwoFormAuthentication: {
                            ...this.state.tempTwoFormAuthentication, twoFormAuthentication:
                                update(this.state.tempTwoFormAuthentication.twoFormAuthentication, { emailConfirmationInstruction: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TwoFormAuthenticationAcceptPath:
                this.setState(
                    {
                        tempTwoFormAuthentication: {
                            ...this.state.tempTwoFormAuthentication, twoFormAuthentication:
                                update(this.state.tempTwoFormAuthentication.twoFormAuthentication, { acceptXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TwoFormAuthenticationRequireAcceptClick:
                this.setState(
                    {
                        tempTwoFormAuthentication: {
                            ...this.state.tempTwoFormAuthentication, twoFormAuthentication:
                                update(this.state.tempTwoFormAuthentication.twoFormAuthentication, { requireAcceptClick: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.ClickLabelHelper:
                this.setState(
                    {
                        tempClickLabelHelper: {
                            ...this.state.tempClickLabelHelper, clickLabelHelper:
                                update(this.state.tempClickLabelHelper.clickLabelHelper, { $set: e.target.value })
                        }
                    });
                break;
            case this.state.ClickActionMultiplierCount:
                this.setState(
                    {
                        tempClickActionMultiplierCount: {
                            ...this.state.tempClickActionMultiplierCount, clickActionMultiplierCount:
                                update(this.state.tempClickActionMultiplierCount.clickActionMultiplierCount, { $set: e.target.value })
                        }
                    });
                break;
            case this.state.APIDataLabelClick:
                this.setState(
                    {
                        tempAPIDataLabelClick: {
                            ...this.state.tempAPIDataLabelClick, apiDataLabelClick:
                                update(this.state.tempAPIDataLabelClick.apiDataLabelClick, { $set: e.target.value })
                        }
                    });
                break;
            case this.state.ApplyJSClick:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { javaScriptClick: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyDateRangeAdditionalClick:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { dateRangeAdditionalClick: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyTwoDayCalendarClick:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { twoDayCalendarClick: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyRepeatStep:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { repeatStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyDragAndDrop:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { dragAndDrop: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySubStringInput:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { subStringInput: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyIgnoreFail:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { ignoreFail: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyUserLabelClick:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { userLabelClick: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyRefreshAction:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { refreshAction: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySubmitForm:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { submitForm: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyTroubleShootStep:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { troubleShootStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyAfterLoginStep:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { afterLoginStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyShowViewerStatusStep:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { showBotViewerStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyApplyToAllElements:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { applyToAllElements: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyBackRepeatStep:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { backRepeatStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyTrackAction:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { trackAction: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyFailTracker:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { failTracker: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySwapAPIPayloadData:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { swapAPIPayloadData: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyStoreAPIResponseValues:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { storeAPIResponseValues: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyRandomizeElementSelection:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { randomizeElementSelection: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyScrollToElement:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { scrollToElement: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySelectOptionValue:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { selectOptionValue: { $set: e.target.checked }, selectOptionIndex: { $set: false } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySelectOptionIndex:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { selectOptionIndex: { $set: e.target.checked }, selectOptionValue: { $set: false } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyConfirmStepSuccess:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { confirmStepSuccess: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySelectAll:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { selectAll: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplySelectAllAndPrint:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { selectAllAndPrint: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyHrefNav:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { hrefNav: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyGroupAction:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { groupAction: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyNodeKeyboardType:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { nodeKeyboardType: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyNodeMoveToElement:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { nodeMoveToElement: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyNodeClick:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { nodeClick: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyUserCoordinatesClick:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { userCoordinatesClick: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyScrollToBottom:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { scrollToBottom: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyChildActionSelectOption:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { childActionSelectOption: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyIncludeLabelInSearch:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { includeLabelInSearchStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyIncludeBasicDateInSearch:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { includeBasicDateInSearchStep: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyUtcStartTimeSearch:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { utcStartTimeSearch: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyDetachedRowAction:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { detachedRowAction: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyWaitUntilPresentListSelector:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { waitUntilPresentListSelector: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyDoubleClickListItem:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { doubleClickListItem: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyRefreshAndGo:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { refreshAndGo: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyDoubleClickElement:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { doubleClickElement: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyMainRowAction:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { mainRowAction: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.ApplyInnerRowAction:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { innerRowAction: { $set: e.target.checked } } } } }
                            })
                    }
                });
                break;
            case this.state.MainElementControlID:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { elementId: { $set: e.target.value }, class: { $set: "" }, xPath: { $set: "" } } } } }
                            })
                    }
                });
                break;
            case this.state.MainElementControlClass:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { elementId: { $set: "" }, class: { $set: e.target.value }, xPath: { $set: "" } } } } }
                            })
                    }
                });
                break;
            case this.state.MainElementControlXPath:
                this.setState({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [index]:
                                    { controls: { [innerIndex]: { htmlElementControl: { elementId: { $set: "" }, class: { $set: "" }, xPath: { $set: e.target.value } } } } }
                            })
                    }
                });
                break;
            case this.state.SpreadSheetConfigurationSpreadSheetType:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { spreadSheetType: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEType:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeType: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationHtmlColumnReaderXpath:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { htmlColumnReaderXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationHtmlAllRowsXpath:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { htmlAllRowsXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationHtmlRowColumnValue:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { htmlRowColumnValue: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationSkipRows:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { skipRows: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationMergeColumns:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { mergeColumns: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationMergeColumnsWithFormat:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { mergeColumnsWithFormat: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationSetDelimiter:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { setDelimiter: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationColumnDelimiter:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { columnDelimiter: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationLocalHtmlHtmlPath:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { localHtmlFilePath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationAcceptRowColumns:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { acceptRowColumns: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationAcceptRowWithColumnValues:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { acceptRowWithColumnValues: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationRefreshPageAfterRecord:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { refreshPageAfterRecord: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationDistinctBy:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { distinctBy: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationEmptyColumns:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { emptyColumns: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOECellNavigatorXpath:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeCellNavigatorXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEColumnSearchSkip:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeColumnSearchSkip: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEFormulaInputXpath:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeFormulaInputXpath: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEStartingCell:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeStartingCell: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEColumnValue:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeColumnValue: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOERowValue:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeRowValue: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOECellValues:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeCellValues: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOERepeatSpreadSheetEntry:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeRepeatSpreadSheetEntry: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOECellValueMustBeEmpty:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeCellValueMustBeEmpty: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEStartWithColumn:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeStartWithColumn: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOERowCellSearchValueStartsWith:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeRowCellSearchValueStartsWith: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEColumnCellSearchValueStartsWith:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oeColumnCellSearchValueStartsWith: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationOEPreventDuplicateEntry:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { oePreventDuplicateEntry: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.SpreadSheetConfigurationEmptyColumnValues:
                this.setState(
                    {
                        tempSpreadSheetConfiguration: {
                            ...this.state.tempSpreadSheetConfiguration, spreadSheetConfiguration:
                                update(this.state.tempSpreadSheetConfiguration.spreadSheetConfiguration, { emptyColumnValues: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterFindText:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { findText: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterReplaceText:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { replaceText: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterReplaceTextRegularExpression:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { replaceTextRegularExpression: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterPlaceBrackets:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { placeBrackets: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterPlaceParentheses:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { placeParentheses: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterConvertToClosesTuesday:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { convertToClosesTuesday: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterCalculateByCompleteDays:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { calculateByCompleteDays: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterDateConverter:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { dateConverter: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterCapitalizeAll:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { capitalizeAll: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterLowercaseAll:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { lowercaseAll: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterPostFill:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { postFill: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterSplitWithDelimiter:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { splitWithDelimiter: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterAddDaysToCalendar:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { addDaysToCalendar: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterTruncate:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { truncate: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterPrefill:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { prefill: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterPrefillWithExceptions:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { prefillWithExceptions: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterPreFillExceptionAction:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { preFillExceptionAction: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.TextFormatterRemoveMiddleValue:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { removeMiddleValue: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterSwapWithBusinessEliteInterpreters:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { swapWithBusinessEliteInterpreters: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterWeekOfMonth:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { weekOfMonth: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterYearOnly:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { yearOnly: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.TextFormatterSkipBusinessEliteInterpreters:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { skipBusinessEliteInterpreters: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterDateDifference:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { dateDifference: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterDivideValues:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { divideValues: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.TextFormatterAddDataValuesFromDateDifference:
                this.setState(
                    {
                        tempTextFormatter: {
                            ...this.state.tempTextFormatter, textFormatter:
                                update(this.state.tempTextFormatter.textFormatter, { addDataValuesFromDateDifference: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadDownloadType:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { downloadType: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.DownloadStore:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { store: { $set: e.target.checked }, extractStore: { $set: false } })
                        }
                    });
                break;
            case this.state.DownloadExtractStore:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractStore: { $set: e.target.checked }, store: { $set: false } })
                        }
                    });
                break;
            case this.state.DownloadExtractLink:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractLink: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.DownloadExtractLinkStartsWith:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractLinkStartsWith: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadExtractLinkEndsWith:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractLinkEndsWith: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadExtractFileName:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractFileName: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.DownloadExtractFileNameStartsWith:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractFileNameStartsWith: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadExtractFileNameEndsWith:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { extractFileNameEndsWith: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadDirectory:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { directory: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadWaitTime:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { waitTime: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DownloadFileType:
                this.setState(
                    {
                        tempDownload: {
                            ...this.state.tempDownload, download:
                                update(this.state.tempDownload.download, { fileType: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.UploadUploadStoredFiles:
                this.setState(
                    {
                        tempUpload: {
                            ...this.state.tempUpload, upload:
                                update(this.state.tempUpload.upload, { uploadStoredFiles: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.UploadWaitTime:
                this.setState(
                    {
                        tempUpload: {
                            ...this.state.tempUpload, upload:
                                update(this.state.tempUpload.upload, { waitTime: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DynamicStepCreatorPlacementStep:
                this.setState(
                    {
                        tempDynamicStepCreator: {
                            ...this.state.tempDynamicStepCreator, dynamicStepCreator:
                                update(this.state.tempDynamicStepCreator.dynamicStepCreator, { placementStep: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DynamicStepCreatorStartRange:
                this.setState(
                    {
                        tempDynamicStepCreator: {
                            ...this.state.tempDynamicStepCreator, dynamicStepCreator:
                                update(this.state.tempDynamicStepCreator.dynamicStepCreator, { startRange: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DynamicStepCreatorFinishRange:
                this.setState(
                    {
                        tempDynamicStepCreator: {
                            ...this.state.tempDynamicStepCreator, dynamicStepCreator:
                                update(this.state.tempDynamicStepCreator.dynamicStepCreator, { finishRange: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DynamicStepCreatorRules:
                this.setState(
                    {
                        tempDynamicStepCreator: {
                            ...this.state.tempDynamicStepCreator, dynamicStepCreator:
                                update(this.state.tempDynamicStepCreator.dynamicStepCreator, { rules: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.DynamicStepCreatorDataCreationIterator:
                this.setState(
                    {
                        tempDynamicStepCreator: {
                            ...this.state.tempDynamicStepCreator, dynamicStepCreator:
                                update(this.state.tempDynamicStepCreator.dynamicStepCreator, { dataCreationIterator: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestAPIHttpRequestType:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { apiHttpRequestType: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.APIRequestAPIHttpResponseType:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { apiHttpResponseType: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.APIRequestAPIExternalAPIType:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { externalAPIType: { $set: e.target.text } })
                        }
                    });
                break;
            case this.state.APIRequestEndPoint:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { endPoint: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestPayloadData:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { payloadData: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestStoreResponseValues:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { storeResponseValues: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestMergeResults:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { mergeResults: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestGetArrayFromRootObject:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { getArrayFromRootObject: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestMergePropertiesWithFormat:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { mergePropertiesWithFormat: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestSetDelimiter:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { setDelimiter: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestPropertyDelimiter:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { propertyDelimiter: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestRootIgnores:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { rootIgnores: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestCatchAllItems:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { catchAllItems: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestIgnoreAllItems:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { ignoreAllItems: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestGroupResultsBy:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { groupResultsBy: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestGroupByTargetProperty:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { groupByTargetProperty: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestGroupMergeName:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { groupMergeName: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestGroupMergeValue:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { groupMergeValue: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestChangeStep:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { changeStep: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestSkipToStep:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { skipToStep: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestGoToStepAfterComplete:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { goToStepAfterComplete: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestReplaceRequestKeys:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { replaceRequestKeys: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestReplaceRequestValues:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { replaceRequestValues: { $set: e.target.value } })
                        }
                    });
                break;
            case this.state.APIRequestRequestContainsTimeStamp:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { requestContainsTimeStamp: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.APIRequestEncodeRequestNameValue:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { encodeRequestNameValue: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.APIRequestEncodeRequestURLValue:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { encodeRequestURLValue: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.APIRequestRemoveDomainProtocols:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { removeDomainProtocols: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.APIRequestRequestNewResultsByID:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { newResultsByID: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.APIRequestRequestReverseResults:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { reverseResults: { $set: e.target.checked } })
                        }
                    });
                break;
            case this.state.APIRequestMessageAllAPIResponses:
                this.setState(
                    {
                        tempAPIRequest: {
                            ...this.state.tempAPIRequest, apiRequest:
                                update(this.state.tempAPIRequest.apiRequest, { messageAllAPIResponses: { $set: e.target.checked } })
                        }
                    });
                break;
        }
    }
    deleteElement = (which) => () => {
        if (window.confirm("Are you sure you want to delete this " + which + "?")) {
            switch (which) {
                case this.state.GetValueFromOtherElement:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { getValueFromOtherElement: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempGetValueFromOtherElementData: initialGetValueFromOtherElement }, () => { this.showModal(this.state.GetValueFromOtherElement, false) }) });
                    break;
                case this.state.ModalCloser:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { modalCloser: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempModalCloserData: initialModalCloserState }, () => { this.showModal(this.state.ModalCloser, false) }) });
                    break;
                case this.state.CaptchaSolver:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { captchaSolver: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempCaptchaSolverData: initialCaptchaSolverState }, () => { this.showModal(this.state.CaptchaSolver, false) }) });
                    break;
                case this.state.IFrameSwitcher:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { frameSwitch: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempIframeSwitcher: initialIFrameSwitcher }, () => { this.showModal(this.state.IFrameSwitcher, false) }) });
                    break;
                case this.state.InfiniteScroll:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { infiniteScroll: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempInfiniteScroll: initialInfiniteScroll }, () => { this.showModal(this.state.InfiniteScroll, false) }) });
                    break;
                case this.state.ListSelectorCondition:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { listSelectorCondition: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempListSelectorCondition: initialListSelectorCondition }, () => { this.showModal(this.state.ListSelectorCondition, false) }) });
                    break;
                case this.state.ElementCondition:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { elementCondition: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempElementCondition: initialElementCondition }, () => { this.showModal(this.state.ElementCondition, false) }) });
                    break;
                case this.state.StepTargetStartTime:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { stepTargetUtcStartTime: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempStepTargetStartTime: '' }, () => { this.showModal(this.state.StepTargetStartTime, false) }) });
                    break;
                case this.state.ModalEmptyListLabelXpath:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { emptyListLabelXpath: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempEmptyListLabelXpath: '' }, () => { this.showModal(this.state.ModalEmptyListLabelXpath, false) }) });
                    break;
                case this.state.RedirectAfter:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { redirectAfterUrl: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempRedirectAfterUrl: '' }, () => { this.showModal(this.state.RedirectAfter, false) }) });
                    break;
                case this.state.ShadowHostXpath:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { shadowRootHost: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempShadowHostXpath: '' }, () => { this.showModal(this.state.ShadowHostXpath, false) }) });
                    break;
                case this.state.RandomValueGeneratorOption:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { randomValueGenerator: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ randomValueOption: null }, () => { this.showModal(this.state.RandomValueGeneratorOption, false) }) });
                    break;
                case this.state.SpreadSheetConfiguration:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { spreadSheetConfiguration: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempSpreadSheetConfiguration: initialSpreadSheetConfiguration }, () => { this.showModal(this.state.SpreadSheetConfiguration, false) }) });
                    break;
                case this.state.TextFormatter:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { textFormatter: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempTextFormatter: initialTextFormatter }, () => { this.showModal(this.state.TextFormatter, false) }) });
                    break;
                case this.state.TwoClickSelect:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { twoClickSelector: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempTwoClickSelector: initialITwoClickSelector }, () => { this.showModal(this.state.TwoClickSelect, false) }) });
                    break;
                case this.state.TabSplit:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { tabSplit: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempTabSplit: initialITabSplit }, () => { this.showModal(this.state.TabSplit, false) }) });
                    break;
                case this.state.TwoFormAuthentication:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { twoFormAuthentication: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempTwoFormAuthentication: initialITwoFormAuthentication }, () => { this.showModal(this.state.TwoFormAuthentication, false) }) });
                    break;
                case this.state.ClickLabelHelper:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { clickLabelHelper: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempClickLabelHelper: initialIClickLabelHelper }, () => { this.showModal(this.state.ClickLabelHelper, false) }) });
                    break;
                case this.state.Download:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { download: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempDownload: initialDownload }, () => { this.showModal(this.state.Download, false) }) });
                    break;
                case this.state.Upload:
                    this.setState(prevState => ({
                        htmlData: {
                            ...this.state.htmlData, htmlControls:
                                update(this.state.htmlData.htmlControls, {
                                    [this.state.currentHtmlControlIndex]:
                                        { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { upload: { $set: null } } } } }
                                })
                        }
                    }), () => { this.setState({ tempUpload: initialUpload }, () => { this.showModal(this.state.Upload, false) }) });
                    break;
            }
        }
    }

    onSaveModalData = (which) => () => {
        switch (which) {
            case this.state.GetValueFromOtherElement:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { getValueFromOtherElement: { $set: prevState.tempGetValueFromOtherElementData.getValueFromOtherElement } } } } }
                            })
                    }
                }), () => { this.setState({ tempGetValueFromOtherElementData: initialGetValueFromOtherElement }, () => { this.showModal(this.state.GetValueFromOtherElement, false) }) });
                break;
            case this.state.ModalCloser:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { modalCloser: { $set: prevState.tempModalCloserData.modalCloser } } } } }
                            })
                    }
                }), () => { this.setState({ tempModalCloserData: initialModalCloserState }, () => { this.showModal(this.state.ModalCloser, false) }) });
                break;
            case this.state.CaptchaSolver:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { captchaSolver: { $set: prevState.tempCaptchaSolverData.captchaSolver } } } } }
                            })
                    }
                }), () => { this.setState({ tempCaptchaSolverData: initialCaptchaSolverState }, () => { this.showModal(this.state.CaptchaSolver, false) }) });
                break;
            case this.state.IFrameSwitcher:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { frameSwitch: { $set: prevState.tempIframeSwitcher.frameSwitch } } } } }
                            })
                    }
                }), () => { this.setState({ tempIframeSwitcher: initialIFrameSwitcher }, () => { this.showModal(this.state.IFrameSwitcher, false) }) });
                break;
            case this.state.InfiniteScroll:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { infiniteScroll: { $set: prevState.tempInfiniteScroll.infiniteScroll } } } } }
                            })
                    }
                }), () => { this.setState({ tempInfiniteScroll: initialInfiniteScroll }, () => { this.showModal(this.state.InfiniteScroll, false) }) });
                break;
            case this.state.ListSelectorCondition:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { listSelectorCondition: { $set: prevState.tempListSelectorCondition.listSelectorCondition } } } } }
                            })
                    }
                }), () => { this.setState({ tempListSelectorCondition: initialListSelectorCondition }, () => { this.showModal(this.state.ListSelectorCondition, false) }) });
                break;
            case this.state.ElementCondition:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { elementCondition: { $set: prevState.tempElementCondition.elementCondition } } } } }
                            })
                    }
                }), () => { this.setState({ tempElementCondition: initialElementCondition }, () => { this.showModal(this.state.ElementCondition, false) }) });
                break;
            case this.state.TimeoutWait:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { timeoutWaitTime: { $set: prevState.tempTimeoutWaitTime } } } } }
                            })
                    }
                }), () => { this.setState({ tempTimeoutWaitTime: '' }, () => { this.showModal(this.state.TimeoutWait, false) }) });
                break;
            case this.state.AddToCalendar:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { addDaysToCalendar: { $set: prevState.tempAddDaysToCalendar } } } } }
                            })
                    }
                }), () => { this.setState({ tempAddDaysToCalendar: '' }, () => { this.showModal(this.state.AddToCalendar, false) }) });
                break;
            case this.state.StepTargetStartTime:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { stepTargetUtcStartTime: { $set: prevState.tempStepTargetStartTime } } } } }
                            })
                    }
                }), () => { this.setState({ tempStepTargetStartTime: '' }, () => { this.showModal(this.state.StepTargetStartTime, false) }) });
                break;
            case this.state.LurkBefore:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { lurkBefore: { $set: prevState.tempLurkBefore } } } } }
                            })
                    }
                }), () => { this.setState({ tempLurkBefore: '' }, () => { this.showModal(this.state.LurkBefore, false) }) });
                break;
            case this.state.LurkUntil:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { lurkUntil: { $set: prevState.tempLurkUntil } } } } }
                            })
                    }
                }), () => { this.setState({ tempLurkUntil: '' }, () => { this.showModal(this.state.LurkUntil, false) }) });
                break;
            case this.state.ClickAndLurk:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { clickAndLurk: { $set: prevState.tempClickAndLurk } } } } }
                            })
                    }
                }), () => { this.setState({ tempClickAndLurk: '' }, () => { this.showModal(this.state.ClickAndLurk, false) }) });
                break;
            case this.state.GoRepeatStep:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { goRepeatStep: { $set: prevState.tempGoRepeatStep } } } } }
                            })
                    }
                }), () => { this.setState({ tempGoRepeatStep: '' }, () => { this.showModal(this.state.GoRepeatStep, false) }) });
                break;
            case this.state.PauseBefore:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { pauseBeforeAction: { $set: prevState.tempPauseBeforeAction } } } } }
                            })
                    }
                }), () => { this.setState({ tempPauseBeforeAction: '' }, () => { this.showModal(this.state.PauseBefore, false) }) });
                break;
            case this.state.PauseAfter:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { pauseAfterAction: { $set: prevState.tempPauseAfterAction } } } } }
                            })
                    }
                }), () => { this.setState({ tempPauseAfterAction: '' }, () => { this.showModal(this.state.PauseAfter, false) }) });
                break;
            case this.state.ScrollToBottomScript:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { scrollToBottomScript: { $set: prevState.tempScrollToBottomScript } } } } }
                            })
                    }
                }), () => { this.setState({ tempScrollToBottomScript: '' }, () => { this.showModal(this.state.ScrollToBottomScript, false) }) });
                break;
            case this.state.SetDataDateOnCalendar:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { setDataDateOnCalendar: { $set: prevState.tempSetDataDateOnCalendar } } } } }
                            })
                    }
                }), () => { this.setState({ tempSetDataDateOnCalendar: '' }, () => { this.showModal(this.state.SetDataDateOnCalendar, false) }) });
                break;
            case this.state.ModalEmptyListLabelXpath:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { emptyListLabelXpath: { $set: prevState.tempEmptyListLabelXpath } } } } }
                            })
                    }
                }), () => { this.setState({ tempEmptyListLabelXpath: '' }, () => { this.showModal(this.state.ModalEmptyListLabelXpath, false) }) });
                break;
            case this.state.RedirectAfter:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { redirectAfterUrl: { $set: prevState.tempRedirectAfterUrl } } } } }
                            })
                    }
                }), () => { this.setState({ tempRedirectAfterUrl: '' }, () => { this.showModal(this.state.RedirectAfter, false) }) });
                break;
            case this.state.ShadowHostXpath:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { shadowRootHost: { $set: prevState.tempShadowHostXpath } } } } }
                            })
                    }
                }), () => { this.setState({ tempShadowHostXpath: '' }, () => { this.showModal(this.state.ShadowHostXpath, false) }) });
                break;
            case this.state.RandomValueGeneratorOption:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { randomValueGenerator: { $set: prevState.randomValueOption } } } } }
                            })
                    }
                }), () => { this.setState({ randomValueOption: '' }, () => { this.showModal(this.state.RandomValueGeneratorOption, false) }) });
                break;
            case this.state.TwoClickSelect:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { twoClickSelector: { $set: prevState.tempTwoClickSelector.twoClickSelector } } } } }
                            })
                    }
                }), () => { this.setState({ tempTwoClickSelector: initialITwoClickSelector }, () => { this.showModal(this.state.TwoClickSelect, false) }) });
                break;
            case this.state.TabSplit:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { tabSplit: { $set: prevState.tempTabSplit.tabSplit } } } } }
                            })
                    }
                }), () => { this.setState({ tempTabSplit: initialITabSplit }, () => { this.showModal(this.state.TabSplit, false) }) });
                break;
            case this.state.TwoFormAuthentication:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { twoFormAuthentication: { $set: prevState.tempTwoFormAuthentication.twoFormAuthentication } } } } }
                            })
                    }
                }), () => { this.setState({ tempTwoFormAuthentication: initialITwoFormAuthentication }, () => { this.showModal(this.state.TwoFormAuthentication, false) }) });
                break;
            case this.state.ClickLabelHelper:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { clickLabelHelper: { $set: prevState.tempClickLabelHelper.clickLabelHelper } } } } }
                            })
                    }
                }), () => { this.setState({ tempClickLabelHelper: initialIClickLabelHelper }, () => { this.showModal(this.state.ClickLabelHelper, false) }) });
                break;
            case this.state.ClickActionMultiplierCount:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { clickActionMultiplierCount: { $set: prevState.tempClickActionMultiplierCount.clickActionMultiplierCount } } } } }
                            })
                    }
                }), () => { this.setState({ tempClickActionMultiplierCount: initialIClickActionMultiplierCount }, () => { this.showModal(this.state.ClickActionMultiplierCount, false) }) });
                break;
            case this.state.APIDataLabelClick:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { apiDataLabelClick: { $set: prevState.tempAPIDataLabelClick.apiDataLabelClick } } } } }
                            })
                    }
                }), () => { this.setState({ tempAPIDataLabelClick: initialAPIDataLabelClick }, () => { this.showModal(this.state.APIDataLabelClick, false) }) });
                break;
            case this.state.SpreadSheetConfiguration:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { spreadSheetConfiguration: { $set: prevState.tempSpreadSheetConfiguration.spreadSheetConfiguration } } } } }
                            })
                    }
                }), () => { this.setState({ tempSpreadSheetConfiguration: initialSpreadSheetConfiguration }, () => { this.showModal(this.state.SpreadSheetConfiguration, false) }) });
                break;
            case this.state.TextFormatter:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { textFormatter: { $set: prevState.tempTextFormatter.textFormatter } } } } }
                            })
                    }
                }), () => { this.setState({ tempTextFormatter: initialTextFormatter }, () => { this.showModal(this.state.TextFormatter, false) }) });
                break;
            case this.state.Download:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { download: { $set: prevState.tempDownload.download } } } } }
                            })
                    }
                }), () => { this.setState({ tempDownload: initialDownload }, () => { this.showModal(this.state.Download, false) }) });
                break;
            case this.state.Upload:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { upload: { $set: prevState.tempUpload.upload } } } } }
                            })
                    }
                }), () => { this.setState({ tempUpload: initialUpload }, () => { this.showModal(this.state.Upload, false) }) });
                break;
            case this.state.APIRequest:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { apiRequest: { $set: prevState.tempAPIRequest.apiRequest } } } } }
                            })
                    }
                }), () => { this.setState({ tempAPIRequest: initialAPIRequest }, () => { this.showModal(this.state.APIRequest, false) }) });
                break;
            case this.state.DynamicStepCreator:
                this.setState(prevState => ({
                    htmlData: {
                        ...this.state.htmlData, htmlControls:
                            update(this.state.htmlData.htmlControls, {
                                [this.state.currentHtmlControlIndex]:
                                    { controls: { [this.state.currenInnerHtmlControl]: { htmlElementControl: { dynamicStepCreator: { $set: prevState.tempDynamicStepCreator.dynamicStepCreator } } } } }
                            })
                    }
                }), () => { this.setState({ tempDynamicStepCreator: initialDynamicStepCreator }, () => { this.showModal(this.state.DynamicStepCreator, false) }) });
                break;
        }
    }


    updateHtmlControlID = (i, i2) => () => {
        var data = {
            id: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.postId,
            elementId: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.elementId,
            javaScriptClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.javaScriptClick,
            dateRangeAdditionalClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dateRangeAdditionalClick,
            twoDayCalendarClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoDayCalendarClick,
            repeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.repeatStep,
            dragAndDrop: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dragAndDrop,
            subStringInput: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.subStringInput,
            userLabelClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.userLabelClick,
            getValueFromOtherElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.getValueFromOtherElement,
            modalCloser: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.modalCloser,
            infiniteScroll: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.infiniteScroll,
            listSelectorCondition: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.listSelectorCondition,
            elementCondition: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.elementCondition,
            captchaSolver: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.captchaSolver,
            twoClickSelector: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoClickSelector,
            tabSplit: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.tabSplit,
            twoFormAuthentication: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoFormAuthentication,
            spreadSheetConfiguration: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.spreadSheetConfiguration,
            textFormatter: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.textFormatter,
            download: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.download,
            upload: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.upload,
            apiRequest: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.apiRequest,
            clickLabelHelper: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickLabelHelper,
            clickActionMultiplierCount: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickActionMultiplierCount,
            apiDataLabelClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.apiDataLabelClick,
            redirectAfterUrl: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.redirectAfterUrl,
            shadowRootHost: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.shadowRootHost,
            randomValueGenerator: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.randomValueGenerator,
            lurkBefore: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.lurkBefore,
            lurkUntil: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.lurkUntil,
            clickAndLurk: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickAndLurk,
            goRepeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.goRepeatStep,
            scrollToBottomScript: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToBottomScript,
            pauseBeforeAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseBeforeAction ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseBeforeAction) : 0,
            addDaysToCalendar: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.addDaysToCalendar ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.addDaysToCalendar) : null,
            frameSwitch: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.frameSwitch,
            pauseAfterAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseAfterAction ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseAfterAction) : 0,
            setDataDateOnCalendar: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.setDataDateOnCalendar,
            emptyListLabelXpath: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.emptyListLabelXpath,
            timeoutWaitTime: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.timeoutWaitTime,
            stepTargetUtcStartTime: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.stepTargetUtcStartTime,
            ignoreFail: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.ignoreFail,
            refreshAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.refreshAction,
            submitForm: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.submitForm,
            troubleShootStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.troubleShootStep,
            afterLoginStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.afterLoginStep,
            showBotViewerStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.showBotViewerStep,
            applyToAllElements: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.applyToAllElements,
            backRepeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.backRepeatStep,
            trackAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.trackAction,
            failTracker: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.failTracker,
            swapAPIPayloadData: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.swapAPIPayloadData,
            storeAPIResponseValues: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.storeAPIResponseValues,
            randomizeElementSelection: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.randomizeElementSelection,
            scrollToElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToElement,
            selectOptionValue: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectOptionValue,
            selectOptionIndex: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectOptionIndex,
            confirmStepSuccess: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.confirmStepSuccess,
            selectAll: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectAll,
            selectAllAndPrint: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectAllAndPrint,
            hrefNav: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.hrefNav,
            groupAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.groupAction,
            nodeKeyboardType: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeKeyboardType,
            nodeMoveToElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeMoveToElement,
            nodeClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeClick,
            userCoordinatesClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.userCoordinatesClick,
            scrollToBottom: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToBottom,
            childActionSelectOption: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.childActionSelectOption,
            detachedRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.detachedRowAction,
            includeLabelInSearchStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.includeLabelInSearchStep,
            includeBasicDateInSearchStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.includeBasicDateInSearchStep,
            utcStartTimeSearch: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.utcStartTimeSearch,
            waitUntilPresentListSelector: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.waitUntilPresentListSelector,
            doubleClickListItem: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.doubleClickListItem,
            refreshAndGo: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.refreshAndGo,
            doubleClickElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.doubleClickElement,
            mainRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.mainRowAction,
            innerRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.innerRowAction,
            dynamicStepCreator: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dynamicStepCreator
        };
        ApiClient.post('bothtml/update/element/id', data).then(() => {
            alert('Updated Successfully');
        }).catch((error) => { });
    }
    updateHtmlControlClassName = (i, i2) => () => {
        var data = {
            id: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.postId,
            className: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.class,
            javaScriptClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.javaScriptClick,
            dateRangeAdditionalClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dateRangeAdditionalClick,
            twoDayCalendarClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoDayCalendarClick,
            repeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.repeatStep,
            dragAndDrop: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dragAndDrop,
            subStringInput: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.subStringInput,
            userLabelClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.userLabelClick,
            getValueFromOtherElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.getValueFromOtherElement,
            modalCloser: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.modalCloser,
            infiniteScroll: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.infiniteScroll,
            listSelectorCondition: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.listSelectorCondition,
            elementCondition: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.elementCondition,
            captchaSolver: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.captchaSolver,
            twoClickSelector: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoClickSelector,
            tabSplit: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.tabSplit,
            twoFormAuthentication: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoFormAuthentication,
            spreadSheetConfiguration: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.spreadSheetConfiguration,
            textFormatter: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.textFormatter,
            download: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.download,
            upload: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.upload,
            apiRequest: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.apiRequest,
            clickLabelHelper: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickLabelHelper,
            clickActionMultiplierCount: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickActionMultiplierCount,
            apiDataLabelClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.apiDataLabelClick,
            redirectAfterUrl: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.redirectAfterUrl,
            shadowRootHost: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.shadowRootHost,
            randomValueGenerator: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.randomValueGenerator,
            lurkBefore: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.lurkBefore,
            lurkUntil: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.lurkUntil,
            clickAndLurk: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickAndLurk,
            goRepeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.goRepeatStep,
            scrollToBottomScript: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToBottomScript,
            pauseBeforeAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseBeforeAction ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseBeforeAction) : 0,
            addDaysToCalendar: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.addDaysToCalendar ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.addDaysToCalendar) : null,
            frameSwitch: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.frameSwitch,
            pauseAfterAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseAfterAction ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseAfterAction) : 0,
            setDataDateOnCalendar: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.setDataDateOnCalendar,
            emptyListLabelXpath: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.emptyListLabelXpath,
            timeoutWaitTime: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.timeoutWaitTime,
            stepTargetUtcStartTime: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.stepTargetUtcStartTime,
            ignoreFail: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.ignoreFail,
            refreshAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.refreshAction,
            submitForm: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.submitForm,
            troubleShootStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.troubleShootStep,
            afterLoginStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.afterLoginStep,
            showBotViewerStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.showBotViewerStep,
            applyToAllElements: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.applyToAllElements,
            backRepeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.backRepeatStep,
            trackAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.trackAction,
            failTracker: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.failTracker,
            swapAPIPayloadData: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.swapAPIPayloadData,
            storeAPIResponseValues: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.storeAPIResponseValues,
            randomizeElementSelection: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.randomizeElementSelection,
            scrollToElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToElement,
            selectOptionValue: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectOptionValue,
            selectOptionIndex: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectOptionIndex,
            confirmStepSuccess: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.confirmStepSuccess,
            selectAll: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectAll,
            selectAllAndPrint: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectAllAndPrint,
            hrefNav: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.hrefNav,
            groupAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.groupAction,
            nodeKeyboardType: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeKeyboardType,
            nodeMoveToElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeMoveToElement,
            nodeClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeClick,
            userCoordinatesClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.userCoordinatesClick,
            scrollToBottom: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToBottom,
            childActionSelectOption: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.childActionSelectOption,
            detachedRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.detachedRowAction,
            includeLabelInSearchStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.includeLabelInSearchStep,
            includeBasicDateInSearchStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.includeBasicDateInSearchStep,
            utcStartTimeSearch: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.utcStartTimeSearch,
            waitUntilPresentListSelector: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.waitUntilPresentListSelector,
            doubleClickListItem: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.doubleClickListItem,
            refreshAndGo: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.refreshAndGo,
            doubleClickElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.doubleClickElement,
            mainRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.mainRowAction,
            innerRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.innerRowAction,
            dynamicStepCreator: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dynamicStepCreator
        };
        ApiClient.post('bothtml/update/element/class', data).then(() => {
            alert('Updated Successfully');
        }).catch((error) => { });
    }
    updateHtmlControlXpath = (i, i2) => () => {
        debugger
        var data = {
            id: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.postId,
            xPath: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.xPath,
            javaScriptClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.javaScriptClick,
            dateRangeAdditionalClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dateRangeAdditionalClick,
            twoDayCalendarClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoDayCalendarClick,
            repeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.repeatStep,
            dragAndDrop: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dragAndDrop,
            subStringInput: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.subStringInput,
            userLabelClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.userLabelClick,
            getValueFromOtherElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.getValueFromOtherElement,
            modalCloser: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.modalCloser,
            infiniteScroll: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.infiniteScroll,
            listSelectorCondition: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.listSelectorCondition,
            elementCondition: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.elementCondition,
            captchaSolver: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.captchaSolver,
            twoClickSelector: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoClickSelector,
            tabSplit: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.tabSplit,
            twoFormAuthentication: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.twoFormAuthentication,
            spreadSheetConfiguration: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.spreadSheetConfiguration,
            textFormatter: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.textFormatter,
            download: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.download,
            upload: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.upload,
            apiRequest: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.apiRequest,
            clickLabelHelper: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickLabelHelper,
            clickActionMultiplierCount: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickActionMultiplierCount,
            apiDataLabelClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.apiDataLabelClick,
            redirectAfterUrl: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.redirectAfterUrl,
            shadowRootHost: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.shadowRootHost,
            randomValueGenerator: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.randomValueGenerator,
            lurkBefore: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.lurkBefore,
            lurkUntil: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.lurkUntil,
            clickAndLurk: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.clickAndLurk,
            goRepeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.goRepeatStep,
            scrollToBottomScript: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToBottomScript,
            pauseBeforeAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseBeforeAction ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseBeforeAction) : 0,
            addDaysToCalendar: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.addDaysToCalendar ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.addDaysToCalendar) : null,
            frameSwitch: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.frameSwitch,
            pauseAfterAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseAfterAction ? parseInt(this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.pauseAfterAction) : 0,
            setDataDateOnCalendar: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.setDataDateOnCalendar,
            emptyListLabelXpath: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.emptyListLabelXpath,
            timeoutWaitTime: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.timeoutWaitTime,
            stepTargetUtcStartTime: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.stepTargetUtcStartTime,
            ignoreFail: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.ignoreFail,
            refreshAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.refreshAction,
            submitForm: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.submitForm,
            troubleShootStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.troubleShootStep,
            afterLoginStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.afterLoginStep,
            showBotViewerStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.showBotViewerStep,
            applyToAllElements: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.applyToAllElements,
            backRepeatStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.backRepeatStep,
            trackAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.trackAction,
            failTracker: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.failTracker,
            swapAPIPayloadData: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.swapAPIPayloadData,
            storeAPIResponseValues: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.storeAPIResponseValues,
            randomizeElementSelection: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.randomizeElementSelection,
            scrollToElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToElement,
            selectOptionValue: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectOptionValue,
            selectOptionIndex: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectOptionIndex,
            confirmStepSuccess: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.confirmStepSuccess,
            selectAll: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectAll,
            selectAllAndPrint: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.selectAllAndPrint,
            hrefNav: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.hrefNav,
            groupAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.groupAction,
            nodeKeyboardType: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeKeyboardType,
            nodeMoveToElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeMoveToElement,
            nodeClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.nodeClick,
            userCoordinatesClick: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.userCoordinatesClick,
            scrollToBottom: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.scrollToBottom,
            childActionSelectOption: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.childActionSelectOption,
            detachedRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.detachedRowAction,
            includeLabelInSearchStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.includeLabelInSearchStep,
            includeBasicDateInSearchStep: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.includeBasicDateInSearchStep,
            utcStartTimeSearch: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.utcStartTimeSearch,
            waitUntilPresentListSelector: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.waitUntilPresentListSelector,
            doubleClickListItem: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.doubleClickListItem,
            refreshAndGo: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.refreshAndGo,
            doubleClickElement: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.doubleClickElement,
            mainRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.mainRowAction,
            innerRowAction: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.innerRowAction,
            dynamicStepCreator: this.state.htmlData.htmlControls[i].controls[i2].htmlElementControl.dynamicStepCreator
        };
        ApiClient.post('bothtml/update/element/xpath', data).then(() => {
            alert('Updated Successfully');
        }).catch((error) => { });
    }
    onSuspensionSelection = (value) => {
        this.setState({ suspensionReasonsSelection: value })
    }
    onSuspensionMessageChange = (e) => {
        this.setState({ suspensionMessage: e.target.value })
    }
    suspendBot = () => {
        if (!this.state.suspensionReasonsSelection && !this.state.suspensionMessage) {
            alert('Please select a suspension reason and enter a detailed message for the customer.');
            return;
        }
        if (window.confirm("Are you sure you want to suspend setup of this Bot?")) {
            ApiClient.post('bot/service/suspend/', {
                id: this.state.botId,
                message: this.state.suspensionMessage,
                botName: this.state.htmlData.botName,
                reason: this.state.suspensionReasonsSelection
            }).then((response) => {
                window.location = "/?start=builder"
            }).catch((error) => { });
        }
    }

    saveAllHtml = () => {
        this.setState({ isSyncButtonDisabled: true, syncButtonText: "Syncing...", liveFeedMessages: '' }, () => {
            this.generateSaveAllHtmldData();
        })
    }
    bypassAllHtml = () => {
        this.setState({ superSync: true, isSyncButtonDisabled: true, syncButtonText: "Syncing...", liveFeedMessages: '' }, () => {
            this.generateSaveAllHtmldData();
        })
    }

    generateSaveAllHtmldData = async () => {
        var saveAll = [];
        var earlyExit = false;
        var data = this.state.htmlData.htmlControls.map((item) => {
            if (item && item.controls) {
                item.controls.map((html) => {
                    if (earlyExit) return;
                    if (html.htmlElementControl && !html.htmlElementControl.elementId && !html.htmlElementControl.class && !html.htmlElementControl.xPath) {
                        earlyExit = true;
                        return;
                    }
                    else {
                        if (html.htmlElementControl)
                            saveAll.push({
                                botId: this.state.htmlData.botId,
                                url: this.state.htmlData.siteUrl,
                                elementId: html.htmlElementControl.elementId,
                                className: html.htmlElementControl.class,
                                xPath: html.htmlElementControl.xPath,
                                javaScriptClick: html.htmlElementControl.javaScriptClick,
                                dateRangeAdditionalClick: html.htmlElementControl.dateRangeAdditionalClick,
                                twoDayCalendarClick: html.htmlElementControl.twoDayCalendarClick,
                                repeatStep: html.htmlElementControl.repeatStep,
                                dragAndDrop: html.htmlElementControl.dragAndDrop,
                                subStringInput: html.htmlElementControl.subStringInput,
                                ignoreFail: html.htmlElementControl.ignoreFail,
                                userLabelClick: html.htmlElementControl.userLabelClick,
                                htmlElementControlId: html.htmlElementControl.postId,
                                getValueFromOtherElement: html.htmlElementControl.getValueFromOtherElement,
                                modalCloser: html.htmlElementControl.modalCloser,
                                infiniteScroll: html.htmlElementControl.infiniteScroll,
                                listSelectorCondition: html.htmlElementControl.listSelectorCondition,
                                elementCondition: html.htmlElementControl.elementCondition,
                                frameSwitch: html.htmlElementControl.frameSwitch,
                                captchaSolver: html.htmlElementControl.captchaSolver,
                                twoClickSelector: html.htmlElementControl.twoClickSelector,
                                tabSplit: html.htmlElementControl.tabSplit,
                                twoFormAuthentication: html.htmlElementControl.twoFormAuthentication,
                                spreadSheetConfiguration: html.htmlElementControl.spreadSheetConfiguration,
                                textFormatter: html.htmlElementControl.textFormatter,
                                download: html.htmlElementControl.download,
                                upload: html.htmlElementControl.upload,
                                apiRequest: html.htmlElementControl.apiRequest,
                                clickLabelHelper: html.htmlElementControl.clickLabelHelper,
                                clickActionMultiplierCount: html.htmlElementControl.clickActionMultiplierCount,
                                apiDataLabelClick: html.htmlElementControl.apiDataLabelClick,
                                redirectAfterUrl: html.htmlElementControl.redirectAfterUrl,
                                emptyListLabelXpath: html.htmlElementControl.emptyListLabelXpath,
                                shadowRootHost: html.htmlElementControl.shadowRootHost,
                                randomValueGenerator: html.htmlElementControl.randomValueGenerator,
                                scrollToBottomScript: html.htmlElementControl.scrollToBottomScript,
                                setDataDateOnCalendar: html.htmlElementControl.setDataDateOnCalendar,
                                lurkBefore: html.htmlElementControl.lurkBefore ? parseInt(html.htmlElementControl.lurkBefore) : 0,
                                lurkUntil: html.htmlElementControl.lurkUntil ? parseInt(html.htmlElementControl.lurkUntil) : 0,
                                clickAndLurk: html.htmlElementControl.clickAndLurk ? parseInt(html.htmlElementControl.clickAndLurk) : 0,
                                goRepeatStep: html.htmlElementControl.goRepeatStep ? parseInt(html.htmlElementControl.goRepeatStep) : null,
                                pauseBeforeAction: html.htmlElementControl.pauseBeforeAction ? parseInt(html.htmlElementControl.pauseBeforeAction) : 0,
                                addDaysToCalendar: html.htmlElementControl.addDaysToCalendar ? parseInt(html.htmlElementControl.addDaysToCalendar) : null,
                                pauseAfterAction: html.htmlElementControl.pauseAfterAction ? parseInt(html.htmlElementControl.pauseAfterAction) : 0,
                                datePickerType: this.state.htmlData.containsDatePicker ? this.state.selectedDatePicker ? this.state.selectedDatePicker : null : null,
                                isChild: html.isChild,
                                timeoutWaitTime: html.htmlElementControl.timeoutWaitTime,
                                submitForm: html.htmlElementControl.submitForm,
                                stepTargetUtcStartTime: html.htmlElementControl.stepTargetUtcStartTime,
                                refreshAction: html.htmlElementControl.refreshAction,
                                backRepeatStep: html.htmlElementControl.backRepeatStep,
                                trackAction: html.htmlElementControl.trackAction,
                                failTracker: html.htmlElementControl.failTracker,
                                swapAPIPayloadData: html.htmlElementControl.swapAPIPayloadData,
                                storeAPIResponseValues: html.htmlElementControl.storeAPIResponseValues,
                                randomizeElementSelection: html.htmlElementControl.randomizeElementSelection,
                                troubleShootStep: html.htmlElementControl.troubleShootStep,
                                afterLoginStep: html.htmlElementControl.afterLoginStep,
                                showBotViewerStep: html.htmlElementControl.showBotViewerStep,
                                applyToAllElements: html.htmlElementControl.applyToAllElements,
                                scrollToElement: html.htmlElementControl.scrollToElement,
                                selectOptionValue: html.htmlElementControl.selectOptionValue,
                                selectOptionIndex: html.htmlElementControl.selectOptionIndex,
                                confirmStepSuccess: html.htmlElementControl.confirmStepSuccess,
                                selectAll: html.htmlElementControl.selectAll,
                                selectAllAndPrint: html.htmlElementControl.selectAllAndPrint,
                                hrefNav: html.htmlElementControl.hrefNav,
                                groupAction: html.htmlElementControl.groupAction,
                                nodeKeyboardType: html.htmlElementControl.nodeKeyboardType,
                                nodeMoveToElement: html.htmlElementControl.nodeMoveToElement,
                                nodeClick: html.htmlElementControl.nodeClick,
                                userCoordinatesClick: html.htmlElementControl.userCoordinatesClick,
                                scrollToBottom: html.htmlElementControl.scrollToBottom,
                                childActionSelectOption: html.htmlElementControl.childActionSelectOption,
                                detachedRowAction: html.htmlElementControl.detachedRowAction,
                                includeLabelInSearchStep: html.htmlElementControl.includeLabelInSearchStep,
                                includeBasicDateInSearchStep: html.htmlElementControl.includeBasicDateInSearchStep,
                                utcStartTimeSearch: html.htmlElementControl.utcStartTimeSearch,
                                waitUntilPresentListSelector: html.htmlElementControl.waitUntilPresentListSelector,
                                doubleClickListItem: html.htmlElementControl.doubleClickListItem,
                                refreshAndGo: html.htmlElementControl.refreshAndGo,
                                doubleClickElement: html.htmlElementControl.doubleClickElement,
                                mainRowAction: html.htmlElementControl.mainRowAction,
                                innerRowAction: html.htmlElementControl.innerRowAction,
                                dynamicStepCreator: html.htmlElementControl.dynamicStepCreator
                            });
                    }
                });
            }

        });
        await Promise.all(data).then(() => {
            if (earlyExit) {
                alert("All fields are not complete. Please fill in all fields to start sync.");
                this.setState({ isSyncButtonDisabled: false, syncButtonText: "Sync All HTML" })
                return;
            }
            this.setState({ allSavedData: saveAll })
            ApiClient.post(this.state.superSync ? 'bot/that/passive/verify/all/html' : 'bot/that/verify/all/html', this.state.allSavedData,
            ).then(() => {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(config.api + this.state.verifyUrl + this.state.botId)
                    .withAutomaticReconnect()
                    .build();
                newConnection.start()
                    .then(() => {
                        this.setState({ liveFeedStatus: "Connected!" }, () => {
                            newConnection.on('ReceiveMessage', message => {
                                if (this.state.liveFeedMessages) {
                                    this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n" + message.message })
                                }
                                else
                                    this.setState({ liveFeedMessages: "\n" + message.message })

                                switch (message.hubMessageType) {
                                    case "SESSION_ID":
                                        this.setState({ runningBotUrl: message.message }, () => this.onShowLiveBotViewModal(true))
                                        break;
                                    case "VERIFYCOMPLETE":
                                        this.allSuccessfullyVerified();
                                        newConnection.stop();
                                        this.setState({ liveFeedStatus: "(Disconnected)" });
                                        break;
                                    case "VERIFYFAILED":
                                    case "NO_ID":
                                        newConnection.stop();
                                        this.setState({ allSavedData: [], liveFeedStatus: "(Disconnected)", syncButtonText: "Sync All HTML", isSyncButtonDisabled: false });
                                        break;
                                }
                            });
                        })

                    })
                    .catch(e => console.log('Connection failed: ', e));
            }).catch((error) => { this.setState({ allSavedData: [], liveFeedStatus: "(Disconnected)", syncButtonText: "Sync All HTML", isSyncButtonDisabled: false }); });
        });
    }
    allSuccessfullyVerified = () => {
        this.state.allSavedData.map((item, i) => {
            ApiClient.post('bothtml/update/element/all', item)
                .then((response) => {
                    if (!response) {
                        alert('error saving element data!');
                        return;
                    }
                    ApiClient.post('bothtml/element/link/' + item.htmlElementControlId, {})
                        .then((res) => {
                            if (!res) {
                                alert('error linking element!');
                                return;
                            }
                            if (i == this.state.allSavedData.length - 1) {
                                ApiClient.get('bothtml/islinked/all/' + this.state.botId)
                                    .then((r) => {
                                        if (r) {
                                            ApiClient.post('bot/service/activate/htmllink/' + this.state.botId, {})
                                                .then((resp) => {
                                                    if (!resp) {
                                                        alert('error activating html linking!');
                                                        return;
                                                    }
                                                    ApiClient.post('bot/service/link/all/' + item.htmlElementControlId, {})
                                                        .then((o) => {
                                                            if (!o) {
                                                                alert('error linking all similar bots!');
                                                                return;
                                                            }
                                                            ApiClient.post('bot/service/activate/all/' + item.htmlElementControlId, {})
                                                                .then((p) => {
                                                                    if (!p) {
                                                                        alert('error activating all similar bots!');
                                                                        return;
                                                                    }
                                                                    ApiClient.post('bot/service/schedule/all/' + item.htmlElementControlId, {})
                                                                        .then((r) => {

                                                                            if (!r) {
                                                                                alert('error scheduling all similar bots!');
                                                                                return;
                                                                            }
                                                                            ApiClient.post('bot/service/close/task/' + this.state.botId, {})
                                                                                .then((r) => {
                                                                                    if (!r) {
                                                                                        alert('error closing task!');
                                                                                        return;
                                                                                    }
                                                                                    window.location = "/?start=builder"
                                                                                })
                                                                                .catch((error) => { alert('error scheduling all similar bots!'); });
                                                                            window.location = "/?start=builder"
                                                                        })
                                                                        .catch((error) => { alert('error scheduling all similar bots!'); });
                                                                })
                                                                .catch((error) => { alert('error activating all similar bots!'); });
                                                        })
                                                        .catch((error) => { alert('error linking all similar bots!'); });
                                                })
                                                .catch((error) => { alert('error activating html linking!'); });
                                        } else {
                                            alert('All items are not linked');
                                        }

                                    })
                                    .catch((error) => { alert('error activating html linking!'); });
                            }

                        })
                        .catch((error) => { alert('error linking element!'); });
                })
                .catch((error) => { alert('error saving element data!'); });
        });
    }
    ezCopy = (htmlControlId) => (e) => {
        ApiClient.get('bothtml/ez/copy/' + this.state.botId + '/' + htmlControlId).then((ez) => {
            if (ez) {
                const el = document.createElement('textarea');
                el.value = ez.data;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            }
        }).catch((error) => { });
    }
    ezListCopy = (htmlControlId) => (e) => {
        ApiClient.get('bothtml/ez/copy/list/' + this.state.botId + '/' + htmlControlId).then((ez) => {
            if (ez) {
                const el = document.createElement('textarea');
                el.value = ez.data;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            }
        }).catch((error) => { });
    }
    onShareBotMenuClick = () => {
        this.setState({ mainBotDuplicateName: this.state.htmlData.botName, masterBotId: this.state.botId }, () => {
            this.onShowShareBotModal(true);
        });
    }
    onShowShareBotModal = (show) => {
        this.setState({ showSharedBotModal: show })
    }
    onShowConfirmDuplicateModal = (show) => {
        this.setState({ showConfirmDuplicateModal: show })
    }
    onShowAssignBotModal = (show) => {
        this.setState({ showAssignBotModal: show })
    }
    createDuplicate = () => {
        this.setState({ mainBotDuplicateName: this.state.htmlData.botName })
        this.onShowConfirmDuplicateModal(true);
    }
    pauseBot = (e) => {
        ApiClient.post('bot/pause/' + this.state.botId).then((response) => {
            window.location.reload();
        }).catch((error) => {
        });
    }
    resumeBot = (e) => {
        ApiClient.post('bot/unpause/' + this.state.botId).then((response) => {
            window.location.reload();
        }).catch((error) => {
        });
    }
    activateBot = async (e) => {
        var activateBotEarlyExit = false;
        var data = this.state.htmlData.htmlControls.map((item) => {
            if (item && item.controls) {
                item.controls.map((html) => {

                    if (activateBotEarlyExit) return;
                    if (html.htmlElementControl && !html.htmlElementControl.elementId && !html.htmlElementControl.class && !html.htmlElementControl.xPath) {
                        activateBotEarlyExit = true;
                        return;
                    }
                });
            }
        });
        await Promise.all(data).then(() => {
            if (activateBotEarlyExit) {
                alert("Cannot activate Bot that has not been HTML synced yet.");
                this.setState({ isSyncButtonDisabled: false, syncButtonText: "Sync All HTML" })
                return;
            }
            else {
                ApiClient.post('bot/service/activate/' + this.state.botId).then((response) => {
                    window.location = '/builder?bid=' + this.state.botId + "&linked=true";
                }).catch((error) => {
                });
            }
        });
    }
    disableBot = (e) => {
        if (window.confirm("Are you sure you want to disable this Bot?")) {
            ApiClient.post('bot/service/deactivate/' + this.state.botId).then((response) => {
                window.location = '/builder?bid=' + this.state.botId;
            }).catch((error) => {
            });
        }
    }
    deleteBot = (e) => {
        if (window.confirm("Are you sure you want to delete this Bot?")) {
            ApiClient.post('bot/delete/' + this.state.botId).then((response) => {
                window.location = '/'
            }).catch((error) => {
            });
        }
    }
    runBotNow = () => {
        ApiClient.post('bot/that/now/' + this.state.botId).then((response) => {
            this.setState({
                liveFeedMessage: ''
            }, () => {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(config.api + 'run/stats?botId=' + this.state.botId)
                    .withAutomaticReconnect()
                    .build();
                newConnection.start()
                    .then(result => {
                        window.scrollTo(0, document.body.scrollHeight);
                        this.setState({ liveFeedStatus: "Connected!" }, () => {
                            newConnection.on('ReceiveMessage', message => {
                                switch (message.hubRunMessageType) {
                                    case "RUNSTARTED":
                                        break;
                                    case "SESSION_ID":
                                        if (this.state.liveFeedMessages) {
                                            this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n - Session ID:" + message.message, runningBotUrl: message.message }, () => this.onShowLiveBotViewModal(true))
                                        }
                                        else
                                            this.setState({ liveFeedMessages: "\n - Session ID:" + message.message, runningBotUrl: message.message }, () => this.onShowLiveBotViewModal(true))
                                        break;
                                    case "RUNSTEP":
                                        if (this.state.liveFeedMessages) {
                                            this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n" + message.message })
                                        }
                                        else
                                            this.setState({ liveFeedMessages: "\n" + message.message })
                                        break;
                                    case "RUNCOMPLETE":
                                    case "RUNFAILED":
                                        this.onShowLiveBotViewModal(false);
                                        this.setState({ liveFeedStatus: "(Disconnected)", runningBotUrl: '' });
                                        newConnection.stop();
                                        break;
                                }
                            });
                        });
                    }).catch(e => console.log('Connection failed: ', e));
            });
        }).catch((error) => { });
    }
    runBotNowLocally = () => {
        ApiClient.postExternal('https://hornet-up-gratefully.ngrok-free.app/bot/that/local/now/' + this.state.botId).then((response) => {
            this.setState({
                liveFeedMessage: ''
            }, () => {
                const newConnection = new HubConnectionBuilder()
                    .withUrl('https://hornet-up-gratefully.ngrok-free.app/run/stats?botId=' + this.state.botId)
                    .withAutomaticReconnect()
                    .build();
                newConnection.start()
                    .then(result => {
                        window.scrollTo(0, document.body.scrollHeight);
                        this.setState({ liveFeedStatus: "Connected!" }, () => {
                            newConnection.on('ReceiveMessage', message => {
                                switch (message.hubRunMessageType) {
                                    case "RUNSTARTED":
                                        break;
                                    case "SESSION_ID":
                                        if (this.state.liveFeedMessages) {
                                            this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n - Session ID:" + message.message, runningBotUrl: message.message }, () => this.onShowLiveBotViewModal(true))
                                        }
                                        else
                                            this.setState({ liveFeedMessages: "\n - Session ID:" + message.message, runningBotUrl: message.message }, () => this.onShowLiveBotViewModal(true))
                                        break;
                                    case "RUNSTEP":
                                        if (this.state.liveFeedMessages) {
                                            this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n" + message.message })
                                        }
                                        else
                                            this.setState({ liveFeedMessages: "\n" + message.message })
                                        break;
                                    case "RUNCOMPLETE":
                                    case "RUNFAILED":
                                        this.onShowLiveBotViewModal(false);
                                        this.setState({ liveFeedStatus: "(Disconnected)", runningBotUrl: '' });
                                        newConnection.stop();
                                        break;
                                }
                            });
                        });
                    }).catch(e => console.log('Connection failed: ', e));
            });
        }).catch((error) => { });
    }
    runInfiniteBots = () => {
        ApiClient.post('bot/that/infinite/all').then((response) => {
            this.setState({
                liveFeedMessage: ''
            }, () => {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(config.api + 'run/stats?botId=' + this.state.botId)
                    .withAutomaticReconnect()
                    .build();
                newConnection.start()
                    .then(result => {
                        window.scrollTo(0, document.body.scrollHeight);
                        this.setState({ liveFeedStatus: "Connected!" }, () => {
                            newConnection.on('ReceiveMessage', message => {
                                switch (message.hubRunMessageType) {
                                    case "RUNSTARTED":
                                        break;
                                    case "RUNSTEP":
                                        if (this.state.liveFeedMessages) {
                                            this.setState({ liveFeedMessages: this.state.liveFeedMessages + "\n" + message.message })
                                        }
                                        else
                                            this.setState({ liveFeedMessages: "\n" + message.message })
                                        break;
                                    case "RUNCOMPLETE":
                                    case "RUNFAILED":
                                        this.setState({ liveFeedStatus: "(Disconnected)" });
                                        newConnection.stop();
                                        break;
                                }
                            });
                        });
                    }).catch(e => console.log('Connection failed: ', e));
            });
        }).catch((error) => { });
    }
    onAssignBotMessageChange = (e) => {
        this.setState({ assignMessage: e.target.value })
    }
    onAssignBotDueDate = (e) => {
        this.setState({ assignDueDate: e.target.value })
    }
    assignToClick = (item) => {
        this.setState({ selectedEngineerUserId: item })
    }
    onAssignItemClick = (e) => {
        this.setState({ selectedEngineer: e.target.text })
    }
    botTierClick = (item) => {
        this.setState({ selectedTier: item })
    }
    onSaveAssignTask = () => {
        this.setState({ disableSaveButton: true }, () => {
            ApiClient.post('bot/service/assign/task', { assignedTo: this.state.selectedEngineerUserId, botId: this.state.botId, message: this.state.assignMessage, tier: this.state.selectedTier, dueDate: this.state.assignDueDate }).then((response) => {
                if (response.status == 200) this.setState({ hideSuccessLabel: false }, () => {
                    setTimeout(() => {
                        this.onShowAssignBotModal(false);
                        this.setState({ assignMessage: '', selectedEngineer: '', selectedTier: '', disableSaveButton: false, selectedEngineerUserId: '', hideSuccessLabel: true, assignDueDate: '' })
                    }, 1500);
                })
            }).catch((error) => {
                this.setState({ disableSaveButton: false });
            });
        })

    }
    onPublicShareBotMenuClick = () => {
        ApiClient.get('bot/share/url/' + this.state.botId).then((response) => {
            this.setState({ publicShareUrl: response.data.url }, () => {
                this.onShowPublicShareModal(true);
            });
        }).catch((error) => {
        });
    }
    onShowPublicShareModal = (show) => {
        this.setState({ publicShareModalShow: show })
    }
    onCopyButtonClick = () => {
        navigator.clipboard.writeText(this.state.publicShareUrl)
        this.setState({ publicShareCopyButtonText: 'Copied!' }, () => {
            setTimeout(
                () => this.setState({ publicShareCopyButtonText: 'Copy' }),
                3000
            );
        });
    }
    onShowCopyConfigModal = (show) => {
        this.setState({ copyConfigModalShow: show })
    }
    copyConfigOriginalBotIdChange = (e) => {
        this.setState({ configOriginalBotId: e.target.value })
    }
    startMigration = (e) => {
        this.setState({ isMigrating: true }, () => {
            ApiClient.post('bot/service/shell/config', {
                copyConfigFromBotId: this.state.configOriginalBotId, newConfigBotId: this.state.botId, addMiniBot: this.state.createMiniBot,
                miniBotName: this.state.miniBotName, miniBotType: this.state.miniSearchType, miniBotNotes: this.state.miniBotNotes
            }).then((response) => {
                window.location = "/builder?bid=" + this.state.botId + "&linked=true";
            }).catch((error) => {
            });
        })
    }

    onShowLiveBotViewModal = (show) => {
        this.setState({ liveBotViewModalShow: show });
    }
    render() {

        const rootStore = this.props.rootStore;
        fakeIndexCount = 1;
        return (
            <Container fluid>
                {this.state.htmlData ? (<div>
                    <Row>
                        <Col>
                            <h3>{this.state.htmlData.botName}</h3>
                            <span><a href={"mailto:" + this.state.htmlData.emailAddress}>{this.state.htmlData.fullName}</a></span>
                        </Col>
                        <Col><Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item disabled={this.state.htmlData.isPaused || !this.state.htmlData.isActive} onClick={!this.state.htmlData.infiniteRun ? this.state.htmlData.runLocally ? this.runBotNowLocally : this.runBotNow : this.runInfiniteBots}>Run Now</Dropdown.Item>
                                {access > 7 && <Dropdown.Item onClick={() => this.onShowAssignBotModal(true)}>Assign Bot</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item disabled={!this.state.htmlData.isActive} onClick={this.onPublicShareBotMenuClick}>Get Share Link</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item disabled={!this.state.htmlData.isActive} onClick={this.onShareBotMenuClick}>Send</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item disabled={!this.state.htmlData.isActive} onClick={this.createDuplicate}>Copy</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item onClick={this.pauseBot} disabled={this.state.htmlData.isPaused || !this.state.htmlData.isActive}>Pause</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item onClick={this.resumeBot} disabled={!this.state.htmlData.isPaused}>Resume</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item onClick={() => this.onShowCopyConfigModal(true)} >Config Blueprint</Dropdown.Item>}
                                {access > 7 && <Dropdown.Item onClick={this.activateBot} disabled={this.state.htmlData.isActive}>Activate</Dropdown.Item>}
                                {access > 7 && <Dropdown.Item onClick={this.disableBot} disabled={!this.state.htmlData.isActive}>Disable</Dropdown.Item>}
                                {access > 3 && <Dropdown.Item onClick={this.deleteBot}>Delete</Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown></Col>
                    </Row>
                    <Row>
                        <Col>
                            <a href={"/?start=usersBots&uid=" + this.state.htmlData.userId} target="_blank"><span>{!this.state.bzE ? "Subscription (" + (this.state.htmlData.subscriptionStatus.subscriptionState == 0 ? "Active" : "Inactive") + "): " + this.state.htmlData.subscriptionStatus.subscriptionName : "Business Elite Tier: " + this.state.bzeTier}</span></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <a href={"https://app.botit.com/create?bid=" + this.state.htmlData.botId} target="_blank">{this.state.htmlData.botId}</a> {access > 7 && <a target={"_blank"} href={"/?start=botHistory&bid=" + this.state.htmlData.botId}><img src={historyScroll} width="16" height="16" /></a>}
                        </Col>
                    </Row>
                    {this.state.assignedEngineer &&
                        <div>
                            <Row>
                                <Col>
                                    <span>Bot Engineer: {this.state.assignedEngineer}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span>Due Date: {this.state.assignedBotDueDate}</span>
                                </Col>
                            </Row>
                        </div>
                    }

                    <Row>
                        <Col>
                            <a target="_blank" href={this.state.htmlData.siteUrl}>{this.state.htmlData.siteUrl}</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.htmlData && this.state.htmlData.containsDatePicker &&
                                <Dropdown onSelect={this.onDatePickerSelect} style={{ marginBottom: '16px' }}>
                                    <Dropdown.Toggle variant="success" id="datePickerType">
                                        {this.state.htmlData.datePickerType ? this.state.htmlData.datePickerType : "Select Date Picker"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.state.datePickers.map((picker) => {
                                            return (<Dropdown.Item eventKey={picker}>{picker}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            {this.state.htmlData && this.state.htmlData.htmlControls && this.state.htmlData.htmlControls.length > 0 && this.state.htmlData.htmlControls.map((item, index) => {
                                return (
                                    <div>
                                        <Row>
                                            <Col>
                                                <h3>{item.controlType}</h3>
                                            </Col>
                                        </Row>
                                        {item.controls && item.controls.map((it, i) => {
                                            {
                                                return (it.htmlElementControl &&
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                                <h4>{(fakeIndexCount++) + ". " + it.labelHelper + (it.isChild ? " (Child)" : "")}{it.step == "Text Input" && it.labelHelper && it.labelHelper.startsWith("Login") && it.htmlElementControl ? (<Button onClick={this.ezCopy(it.htmlElementControl.postId)} style={{ marginLeft: "20px" }} variant="primary">EZ Clip</Button>) : it.step == "Read, Compare, Action Multiple" && it.htmlElementControl.xPath && it.htmlElementControl.xPath.includes("%BOS%") && it.htmlElementControl ? (<Button onClick={this.ezListCopy(it.htmlElementControl.postId)} style={{ marginLeft: "20px" }} variant="primary">EZ Clip</Button>) : ("")} {it.htmlElementControl && it.htmlElementControl.lastUpdated ? (<span style={{ fontSize: '13px', fontStyle: "italic" }}>last updated: {new Date(it.htmlElementControl.lastUpdated).toDateString()}</span>) : ""}</h4>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="controlID">
                                                                    <Form.Label>ID</Form.Label>
                                                                    <Form.Control as="textarea" onChange={this.onDataChange(this.state.MainElementControlID, index, i)} rows={2} value={it.htmlElementControl.elementId} />
                                                                </Form.Group>
                                                                {it.htmlElementControl.isLinked &&
                                                                    <Button onClick={this.updateHtmlControlID(index, i)} variant="success">Update</Button>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="controlClass">
                                                                    <Form.Label>Class</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} onChange={this.onDataChange(this.state.MainElementControlClass, index, i)} value={it.htmlElementControl.class} />
                                                                </Form.Group>
                                                                {it.htmlElementControl.isLinked &&
                                                                    <Button onClick={this.updateHtmlControlClassName(index, i)} variant="success">Update</Button>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="controlXpath">
                                                                    <Form.Label>Xpath</Form.Label>
                                                                    <Form.Control as="textarea" rows={2} onChange={this.onDataChange(this.state.MainElementControlXPath, index, i)} value={it.htmlElementControl.xPath} />
                                                                </Form.Group>
                                                                {it.htmlElementControl.isLinked &&
                                                                    <Button onClick={this.updateHtmlControlXpath(index, i)} variant="success">Update</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.ModalCloser, index, i, it.htmlElementControl.modalCloser)} variant="link">{!it.htmlElementControl.modalCloser ? "Add Modal Closer" : "Edit Modal Closer"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.CaptchaSolver, index, i, it.htmlElementControl.captchaSolver)} variant="link">{!it.htmlElementControl.captchaSolver ? "Add Captcha Solver" : "Edit Captcha Solver"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.IFrameSwitcher, index, i, it.htmlElementControl.frameSwitch)} variant="link">{!it.htmlElementControl.frameSwitch ? "Add IFrame Switcher" : "Edit Iframe Switcher"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.TimeoutWait, index, i)} variant="link">{!it.htmlElementControl.timeoutWaitTime ? "Add Timeout Wait Time" : "Edit Timeout Wait Time"}</Button></div>
                                                            </Col>
                                                            {it.step == "Read, Compare, Action" &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.AddToCalendar, index, i)} variant="link">{!it.htmlElementControl.addDaysToCalendar ? "Add Days to Calendar" : "Edit Add Days to Calendar"}</Button></div>
                                                                </Col>
                                                            }
                                                            <Col hidden={true}>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.LurkBefore, index, i)} variant="link">{!it.htmlElementControl.lurkBefore ? "Add Lurk Before Timeout" : "Edit Lurk Before Timeout"}</Button></div>
                                                            </Col>
                                                            <Col hidden={true}>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.LurkUntil, index, i)} variant="link">{!it.htmlElementControl.lurkUntil ? "Add Lurk Until" : "Edit Lurk Until"}</Button></div>
                                                            </Col>
                                                            <Col hidden={true}>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.ClickAndLurk, index, i)} variant="link">{!it.htmlElementControl.clickAndLurk ? "Add Lurk & Click Timeout" : "Edit Lurk & Click Timeout"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.GoRepeatStep, index, i)} variant="link">{!it.htmlElementControl.goRepeatStep ? "Add Go Repeat Step" : "Edit Go Repeat Step"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.PauseBefore, index, i)} variant="link">{!it.htmlElementControl.pauseBeforeAction ? "Add Pause Before Timeout" : "Edit Pause Before Timeout"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.PauseAfter, index, i)} variant="link">{!it.htmlElementControl.pauseAfterAction ? "Add Pause After Timeout" : "Edit Pause After Timeout"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.ModalEmptyListLabelXpath, index, i, it.htmlElementControl.emptyListLabelXpath)} variant="link">{!it.htmlElementControl.emptyListLabelXpath ? "Add Empty List Xpath Checker" : "Edit Empty List Xpath Checker"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.StepTargetStartTime, index, i, it.htmlElementControl.emptyListLabelXpath)} variant="link">{!it.htmlElementControl.stepTargetUtcStartTime ? "Add Start Step UTC Time" : "Edit Start Step UTC Time"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.RedirectAfter, index, i, it.htmlElementControl.redirectAfterUrl)} variant="link">{!it.htmlElementControl.redirectAfterUrl ? "Add Redirect Url" : "Edit Redirect Url"}</Button></div>
                                                            </Col>
                                                            {it.step == "Select Option" &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.TwoClickSelect, index, i, it.htmlElementControl.twoClickSelector)} variant="link">{!it.htmlElementControl.twoClickSelector ? "Add Two Click Selector" : "Edit Two Click Selector"}</Button></div>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.TabSplit, index, i, it.htmlElementControl.tabSplit)} variant="link">{!it.htmlElementControl.tabSplit ? "Add Tab Split" : "Edit Tab Split"}</Button></div>
                                                            </Col>
                                                            {it.isChild && it.htmlElementControl.childActionSelectOption &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.TwoClickSelect, index, i)} variant="link">{!it.htmlElementControl.twoClickSelector ? "Add Two Click Selector" : "Edit Two Click Selector"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input" || it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.TwoFormAuthentication, index, i, it.htmlElementControl.twoFormAuthentication)} variant="link">{!it.htmlElementControl.twoFormAuthentication ? "Add Two Form Auth" : "Edit Two Form Auth"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input" || it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.SetDataDateOnCalendar, index, i)} variant="link">{!it.htmlElementControl.setDataDateOnCalendar ? "Add Set Data Date" : "Edit Set Data Date"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Read, Compare, Action Multiple") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.SpreadSheetConfiguration, index, i, it.htmlElementControl.spreadSheetConfiguration)} variant="link">{!it.htmlElementControl.spreadSheetConfiguration ? "Add Spreadsheet Config" : "Edit Spreadsheet Config"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Select Option" || it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.TextFormatter, index, i, it.htmlElementControl.textFormatter)} variant="link">{!it.htmlElementControl.textFormatter ? "Add Text Formatter" : "Edit Text Formatter"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.GetValueFromOtherElement, index, i, it.htmlElementControl.getValueFromOtherElement)} variant="link">{!it.htmlElementControl.getValueFromOtherElement ? "Add Extract Value" : "Edit Extract Value"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.ClickActionMultiplierCount, index, i)} variant="link">{!it.htmlElementControl.clickActionMultiplierCount ? "Click Multiplier" : "Edit Click Multiplier"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input" || it.step == "Text Input" || it.step == "Select Option") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.APIDataLabelClick, index, i)} variant="link">{!it.htmlElementControl.apiDataLabelClick ? "Add API Data Click" : "Edit API Data Click"}</Button></div>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.ClickLabelHelper, index, i, it.htmlElementControl.clickLabelHelper)} variant="link">{!it.htmlElementControl.clickLabelHelper ? "Add List Label Helper" : "Edit List Label Helper"}</Button></div>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.InfiniteScroll, index, i, it.htmlElementControl.infiniteScroll)} variant="link">{!it.htmlElementControl.infiniteScroll ? "Add Infinite Scroll" : "Edit Infinite Scroll"}</Button></div>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.ListSelectorCondition, index, i, it.htmlElementControl.listSelectorCondition)} variant="link">{!it.htmlElementControl.listSelectorCondition ? "Add Conditional List Selector" : "Edit Conditional List Selector"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input" || it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.ElementCondition, index, i, it.htmlElementControl.elementCondition)} variant="link">{!it.htmlElementControl.elementCondition ? "Add Conditional Element" : "Edit Conditional Element"}</Button></div>
                                                                </Col>
                                                            }
                                                            {it.step == "Text Input" &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.RandomValueGeneratorOption, index, i, it.htmlElementControl.randomValueGenerator != null)} variant="link">{it.htmlElementControl.randomValueGenerator != null ? "Edit Random Generator" : "Add Random Generator"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.Download, index, i, it.htmlElementControl.download)} variant="link">{!it.htmlElementControl.download ? "Download Files" : "Edit Download Files"}</Button></div>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input" || it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.Upload, index, i, it.htmlElementControl.upload)} variant="link">{!it.htmlElementControl.upload ? "Upload Files" : "Edit Upload Files"}</Button></div>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.ShadowHostXpath, index, i, it.htmlElementControl.shadowRootHost)} variant="link">{!it.htmlElementControl.shadowRootHost ? "Add Shadow Host" : "Edit Shadow Host"}</Button></div>
                                                            </Col>
                                                            {(it.step == "Text Input") &&
                                                                <Col>
                                                                    <div><Button onClick={this.onShowModalFactory(true, this.state.APIRequest, index, i, it.htmlElementControl.apiRequest)} variant="link">{!it.htmlElementControl.apiRequest ? "API Request" : "Edit API Request"}</Button></div>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.DynamicStepCreator, index, i)} variant="link">{!it.htmlElementControl.dynamicStepCreator ? "Add Dynamic Steps" : "Edit Dynamic Steps"}</Button></div>
                                                            </Col>
                                                            <Col>
                                                                <div><Button onClick={this.onShowModalFactory(true, this.state.ScrollToBottomScript, index, i)} variant="link">{!it.htmlElementControl.scrollToBottomScript ? "Add Scroll Bottom Script" : "Edit Scroll Bottom Script"}</Button></div>
                                                            </Col>

                                                            {it.step == "Read, Compare, Action" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="dateRangeAdditionalClick">
                                                                        <Form.Check type="checkbox" label="Date Range Click" onChange={this.onDataChange(this.state.ApplyDateRangeAdditionalClick, index, i)} checked={it.htmlElementControl.dateRangeAdditionalClick} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="twoDayCalendarClick">
                                                                        <Form.Check type="checkbox" label="2 Day Click" onChange={this.onDataChange(this.state.ApplyTwoDayCalendarClick, index, i)} checked={it.htmlElementControl.twoDayCalendarClick} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {(it.step == "Button Input" || it.step == "Text Input") &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="repeatStep">
                                                                        <Form.Check type="checkbox" label="Repeat Step" onChange={this.onDataChange(this.state.ApplyRepeatStep, index, i)} checked={it.htmlElementControl.repeatStep} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="jsClick">
                                                                    <Form.Check type="checkbox" label="JS Click" onChange={this.onDataChange(this.state.ApplyJSClick, index, i)} checked={it.htmlElementControl.javaScriptClick} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="ignoreFail">
                                                                    <Form.Check type="checkbox" label="Ignore Fail" onChange={this.onDataChange(this.state.ApplyIgnoreFail, index, i)} checked={it.htmlElementControl.ignoreFail} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="userLabelClick">
                                                                    <Form.Check type="checkbox" label="User Label Click" onChange={this.onDataChange(this.state.ApplyUserLabelClick, index, i)} checked={it.htmlElementControl.userLabelClick} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="refreshAction">
                                                                    <Form.Check type="checkbox" label="Refresh Action" onChange={this.onDataChange(this.state.ApplyRefreshAction, index, i)} checked={it.htmlElementControl.refreshAction} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="submitForm">
                                                                    <Form.Check type="checkbox" label="Submit Form" onChange={this.onDataChange(this.state.ApplySubmitForm, index, i)} checked={it.htmlElementControl.submitForm} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="troubleShootStep">
                                                                    <Form.Check type="checkbox" label="Troubleshoot Step" onChange={this.onDataChange(this.state.ApplyTroubleShootStep, index, i)} checked={it.htmlElementControl.troubleShootStep} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="afterLoginStep">
                                                                    <Form.Check type="checkbox" label="Post Login Step" onChange={this.onDataChange(this.state.ApplyAfterLoginStep, index, i)} checked={it.htmlElementControl.afterLoginStep} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="showBotViewerStep">
                                                                    <Form.Check type="checkbox" label="View Step" onChange={this.onDataChange(this.state.ApplyShowViewerStatusStep, index, i)} checked={it.htmlElementControl.showBotViewerStep} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="applyToAllElements">
                                                                    <Form.Check type="checkbox" label="Apply to All" onChange={this.onDataChange(this.state.ApplyApplyToAllElements, index, i)} checked={it.htmlElementControl.applyToAllElements} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="backRepeatStep">
                                                                    <Form.Check type="checkbox" label="Back Repeat Step" onChange={this.onDataChange(this.state.ApplyBackRepeatStep, index, i)} checked={it.htmlElementControl.backRepeatStep} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="trackAction">
                                                                    <Form.Check type="checkbox" label="Track" onChange={this.onDataChange(this.state.ApplyTrackAction, index, i)} checked={it.htmlElementControl.trackAction} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="failTracker">
                                                                    <Form.Check type="checkbox" label="Fail Tracker" onChange={this.onDataChange(this.state.ApplyFailTracker, index, i)} checked={it.htmlElementControl.failTracker} />
                                                                </Form.Group>
                                                            </Col>
                                                            {(it.step == "Button Input" || it.step == "Text Input" || it.step == "Select Option") &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="swapAPIPayloadData">
                                                                        <Form.Check type="checkbox" label="Swap Api Payload" onChange={this.onDataChange(this.state.ApplySwapAPIPayloadData, index, i)} checked={it.htmlElementControl.swapAPIPayloadData} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {(it.step == "Text Input") &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="storeAPIResponseValues">
                                                                        <Form.Check type="checkbox" label="Store API Value" onChange={this.onDataChange(this.state.ApplyStoreAPIResponseValues, index, i)} checked={it.htmlElementControl.storeAPIResponseValues} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="randomizeElementSelection">
                                                                    <Form.Check type="checkbox" label="Randomize" onChange={this.onDataChange(this.state.ApplyRandomizeElementSelection, index, i)} checked={it.htmlElementControl.randomizeElementSelection} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="scrollToElement">
                                                                    <Form.Check type="checkbox" label="Scroll To" onChange={this.onDataChange(this.state.ApplyScrollToElement, index, i)} checked={it.htmlElementControl.scrollToElement} />
                                                                </Form.Group>
                                                            </Col>
                                                            {it.step == "Select Option" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="selectOptionValue">
                                                                        <Form.Check type="checkbox" label="Select Option Value" onChange={this.onDataChange(this.state.ApplySelectOptionValue, index, i)} checked={it.htmlElementControl.selectOptionValue} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Select Option" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="selectOptionIndex">
                                                                        <Form.Check type="checkbox" label="Select Option Index" onChange={this.onDataChange(this.state.ApplySelectOptionIndex, index, i)} checked={it.htmlElementControl.selectOptionIndex} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="confirmStepSuccess">
                                                                    <Form.Check type="checkbox" label="Confirm Step" onChange={this.onDataChange(this.state.ApplyConfirmStepSuccess, index, i)} checked={it.htmlElementControl.confirmStepSuccess} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="groupAction">
                                                                    <Form.Check type="checkbox" label="Group Action" onChange={this.onDataChange(this.state.ApplyGroupAction, index, i)} checked={it.htmlElementControl.groupAction} />
                                                                </Form.Group>
                                                            </Col>
                                                            {it.step == "Button Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="nodeClick">
                                                                        <Form.Check type="checkbox" label="Node Click" onChange={this.onDataChange(this.state.ApplyNodeClick, index, i)} checked={it.htmlElementControl.nodeClick} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Button Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="userCoordinatesClick">
                                                                        <Form.Check type="checkbox" label="Coordinates Click" onChange={this.onDataChange(this.state.ApplyUserCoordinatesClick, index, i)} checked={it.htmlElementControl.userCoordinatesClick} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Text Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="nodeKeyboardType">
                                                                        <Form.Check type="checkbox" label="Node Keyboard" onChange={this.onDataChange(this.state.ApplyNodeKeyboardType, index, i)} checked={it.htmlElementControl.nodeKeyboardType} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="nodeMoveToElement">
                                                                    <Form.Check type="checkbox" label="Node Move To" onChange={this.onDataChange(this.state.ApplyNodeMoveToElement, index, i)} checked={it.htmlElementControl.nodeMoveToElement} />
                                                                </Form.Group>
                                                            </Col>
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="selectAll">
                                                                        <Form.Check type="checkbox" label="Select All" onChange={this.onDataChange(this.state.ApplySelectAll, index, i)} checked={it.htmlElementControl.selectAll} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Button Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="selectAllAndPrint">
                                                                        <Form.Check type="checkbox" label="S&P" onChange={this.onDataChange(this.state.ApplySelectAllAndPrint, index, i)} checked={it.htmlElementControl.selectAllAndPrint} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Button Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="HrefNav">
                                                                        <Form.Check type="checkbox" label="Href Nav" onChange={this.onDataChange(this.state.ApplyHrefNav, index, i)} checked={it.htmlElementControl.hrefNav} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="waitUntilPresentListSelector">
                                                                        <Form.Check type="checkbox" label="Wait Until Present" onChange={this.onDataChange(this.state.ApplyWaitUntilPresentListSelector, index, i)} checked={it.htmlElementControl.waitUntilPresentListSelector} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="doubleClickListItem">
                                                                        <Form.Check type="checkbox" label="Try Again Click" onChange={this.onDataChange(this.state.ApplyDoubleClickListItem, index, i)} checked={it.htmlElementControl.doubleClickListItem} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="dragAndDrop">
                                                                        <Form.Check type="checkbox" label="Drag & Drop" onChange={this.onDataChange(this.state.ApplyDragAndDrop, index, i)} checked={it.htmlElementControl.dragAndDrop} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Text Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="subStringInput">
                                                                        <Form.Check type="checkbox" label="Substring Input" onChange={this.onDataChange(this.state.ApplySubStringInput, index, i)} checked={it.htmlElementControl.subStringInput} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="utcStartTimeSearch">
                                                                        <Form.Check type="checkbox" label="UTC List Click" onChange={this.onDataChange(this.state.ApplyUtcStartTimeSearch, index, i)} checked={it.htmlElementControl.utcStartTimeSearch} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.isChild &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="childActionSelectOption">
                                                                        <Form.Check type="checkbox" label="Select Action" onChange={this.onDataChange(this.state.ApplyChildActionSelectOption, index, i)} checked={it.htmlElementControl.childActionSelectOption} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.isChild &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="detachedRowAction">
                                                                        <Form.Check type="checkbox" label="Detach Action" onChange={this.onDataChange(this.state.ApplyDetachedRowAction, index, i)} checked={it.htmlElementControl.detachedRowAction} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            {it.step == "Button Input" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="mainRowAction">
                                                                        <Form.Check type="checkbox" label="Main Row Action" onChange={this.onDataChange(this.state.ApplyMainRowAction, index, i)} checked={it.htmlElementControl.mainRowAction} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="innerRowAction">
                                                                    <Form.Check type="checkbox" label="Inner Row Action" onChange={this.onDataChange(this.state.ApplyInnerRowAction, index, i)} checked={it.htmlElementControl.innerRowAction} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="includeLabelInSearchStep">
                                                                    <Form.Check type="checkbox" label="LISS" onChange={this.onDataChange(this.state.ApplyIncludeLabelInSearch, index, i)} checked={it.htmlElementControl.includeLabelInSearchStep} />
                                                                </Form.Group>
                                                            </Col>
                                                            {it.step == "Read, Compare, Action Multiple" &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="includeBasicDateInSearchStep">
                                                                        <Form.Check type="checkbox" label="DISS" onChange={this.onDataChange(this.state.ApplyIncludeBasicDateInSearch, index, i)} checked={it.htmlElementControl.includeBasicDateInSearchStep} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="scrollToBottom">
                                                                    <Form.Check type="checkbox" label="Scroll to Bottom" onChange={this.onDataChange(this.state.ApplyScrollToBottom, index, i)} checked={it.htmlElementControl.scrollToBottom} />
                                                                </Form.Group>
                                                            </Col>
                                                            {(it.step == "Button Input" || it.step == "Read, Compare, Action Multiple") &&
                                                                <Col>
                                                                    <Form.Group className="mb-3" controlId="doubleClickElement">
                                                                        <Form.Check type="checkbox" label="Double Click" onChange={this.onDataChange(this.state.ApplyDoubleClickElement, index, i)} checked={it.htmlElementControl.doubleClickElement} />
                                                                    </Form.Group>
                                                                </Col>
                                                            }
                                                            <Col>
                                                                <Form.Group className="mb-3" controlId="refreshAndGo">
                                                                    <Form.Check type="checkbox" label="Refresh & Go" onChange={this.onDataChange(this.state.ApplyRefreshAndGo, index, i)} checked={it.htmlElementControl.refreshAndGo} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Col>
                                                            {it.htmlElementControl.isLinked &&
                                                                <div>
                                                                    <span className="badge alert-info badge-pill">
                                                                        Successfully Linked
                                                                    </span></div>
                                                            }
                                                        </Col>
                                                    </div>
                                                )
                                            }

                                        })}
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                    <Row className="row d-flex justify-content-center">
                        <Button hidden={this.state.htmlData.isActive} onClick={this.saveAllHtml} variant="success">{this.state.syncButtonText}</Button>
                    </Row>
                    <Row className="row d-flex justify-content-center">
                        <Button hidden={!this.state.showAllowBypassHtmlButton || this.state.htmlData.isActive} style={{ marginTop: '23px' }} onClick={this.bypassAllHtml} variant="danger">{"SUPER: " + this.state.syncButtonText}</Button>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="liveFeed">
                                <Form.Label>Html Verify Live Feed <span>{this.state.liveFeedStatus}</span></Form.Label>
                                <div style={{ backgroundColor: 'white', margin: '30px 0', whiteSpace: 'pre-wrap', height: '150px', width: '470', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
                                    <span style={{ color: 'black' }}>{this.state.liveFeedMessages}</span>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '30px', border: '4px solid red!important' }}>
                        <Row>
                            <Col>
                                <h4>Suspend This Bot</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Dropdown onSelect={this.onSuspensionSelection} style={{ marginBottom: '16px' }}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                        {this.state.suspensionReasonsSelection ? this.state.suspensionReasonsSelection : "Select Suspension Reason"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.state.suspensionReasons.map((reason) => {
                                            return (<Dropdown.Item eventKey={reason}>{reason}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="suspensionMessage">
                                    <Form.Label>Suspension Message</Form.Label>
                                    <Form.Control as="textarea" value={this.state.suspensionMessage} onChange={this.onSuspensionMessageChange} rows={7} readOnly={false} disabled={false} style={{ width: '100%' }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="row d-flex justify-content-center">
                            <Button onClick={this.suspendBot} variant="danger">Suspend Bot</Button>
                        </Row>
                    </div>
                    <AddGetValueFromOtherElementModal
                        show={this.state.addGetValueFromElementModalShow}
                        onHide={this.onShowModalFactory(false, this.state.GetValueFromOtherElement)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        GetValueFromOtherElementOtherElementXpath={this.state.GetValueFromOtherElementOtherElementXpath}
                        GetValueFromOtherElementAlternativeElementXpath={this.state.GetValueFromOtherElementAlternativeElementXpath}
                        GetValueFromOtherElementIncreaseValueBy={this.state.GetValueFromOtherElementIncreaseValueBy}
                        GetValueFromOtherElementIncreaseValueByAlternative={this.state.GetValueFromOtherElementIncreaseValueByAlternative}
                        GetValueFromOtherElementElementType={this.state.GetValueFromOtherElementElementType}
                        GetValueFromOtherElementIsTimeValue={this.state.GetValueFromOtherElementIsTimeValue}
                        GetValueFromOtherElementIsAmPmFormat={this.state.GetValueFromOtherElementIsAmPmFormat}
                        tempGetValueFromOtherElementData={this.state.tempGetValueFromOtherElementData}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.GetValueFromOtherElement}
                    />
                    <AddModalCloserModal
                        show={this.state.addModalCloserModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ModalCloser)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        ModalCloserElementId={this.state.ModalCloserElementId}
                        ModalCloserClass={this.state.ModalCloserClass}
                        ModalCloserXpath={this.state.ModalCloserXpath}
                        ModalCloserCloseBefore={this.state.ModalCloserCloseBefore}
                        ModalCloserCloseAfter={this.state.ModalCloserCloseAfter}
                        ModalCloserWaitUntilNotVisible={this.state.ModalCloserWaitUntilNotVisible}
                        ModalCloserWaitUntilNotVisibleTimeLength={this.state.ModalCloserWaitUntilNotVisibleTimeLength}
                        ModalCloserRemoveWaitTimeLength={this.state.ModalCloserRemoveWaitTimeLength}
                        ModalCloserClickDismiss={this.state.ModalCloserClickDismiss}
                        ModalCloserIsPresent={this.state.ModalCloserIsPresent}
                        ModalCloserThenScript={this.state.ModalCloserThenScript}
                        tempModalCloserData={this.state.tempModalCloserData}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ModalCloser}
                    />
                    <AddCaptchaSolverModal
                        show={this.state.addCaptchaSolverModalShow}
                        onHide={this.onShowModalFactory(false, this.state.CaptchaSolver)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        CaptchaSolverType={this.state.CaptchaSolverType}
                        CaptchaSolverVersion={this.state.CaptchaSolverVersion}
                        CaptchaSolverRunPoseCallback={this.state.CaptchaSolverRunPoseCallback}
                        CaptchaSolverIsHCaptcha={this.state.CaptchaSolverIsHCaptcha}
                        CaptchaSolverUseCaptchaExtension={this.state.CaptchaSolverUseCaptchaExtension}
                        CaptchaSolverUseCaptchaExtensionThenClickButton={this.state.CaptchaSolverUseCaptchaExtensionThenClickButton}
                        CaptchaSolverCaptchaExtensionLoadTimeDelay={this.state.CaptchaSolverCaptchaExtensionLoadTimeDelay}
                        CaptchaSolverForeteesCaptchaSolver={this.state.CaptchaSolverForeteesCaptchaSolver}
                        CaptchaSolverScriptKey={this.state.CaptchaSolverScriptKey}
                        CaptchaSolverGeeTest={this.state.CaptchaSolverGeeTest}
                        CaptchaSolverRunHardPostCallback={this.state.CaptchaSolverRunHardPostCallback}
                        CaptchaSolverCheckIfCaptchaExist={this.state.CaptchaSolverCheckIfCaptchaExist}
                        CaptchaSolverExtractKeyFromSrcUrl={this.state.CaptchaSolverExtractKeyFromSrcUrl}
                        CaptchaSolverOverrideCallback={this.state.CaptchaSolverOverrideCallback}
                        CaptchaSolverV1Base64Image={this.state.CaptchaSolverV1Base64Image}
                        CaptchaSolverTokenFunction={this.state.CaptchaSolverTokenFunction}
                        CaptchaSolverXpathSiteKey={this.state.CaptchaSolverXpathSiteKey}
                        CaptchaSolverResponseXpath={this.state.CaptchaSolverResponseXpath}
                        CaptchaSolverV1ElementId={this.state.CaptchaSolverV1ElementId}
                        CaptchaSolverV1ElementInputFieldXpath={this.state.CaptchaSolverV1ElementInputFieldXpath}
                        CaptchaSolverV1VerifySkipCount={this.state.CaptchaSolverV1VerifySkipCount}
                        CaptchaSolverVerifyCaptcha={this.state.CaptchaSolverVerifyCaptcha}
                        CaptchaSolverRefreshBeforeSolving={this.state.CaptchaSolverRefreshBeforeSolving}
                        CaptchaSolverCaptchaExtensionXpath={this.state.CaptchaSolverCaptchaExtensionXpath}
                        tempCaptchaSolverData={this.state.tempCaptchaSolverData}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.CaptchaSolver}
                    />
                    <IFrameSwitcherModal
                        show={this.state.iFrameSwitcherModalShow}
                        onHide={this.onShowModalFactory(false, this.state.IFrameSwitcher)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        IFrameSwitcherElementId={this.state.IFrameSwitcherElementId}
                        IFrameSwitcherClass={this.state.IFrameSwitcherClass}
                        IFrameSwitcherXpath={this.state.IFrameSwitcherXpath}
                        IFrameSwitcherWhichWindow={this.state.IFrameSwitcherWhichWindow}
                        IFrameSwitcherPauseBeforeSwitch={this.state.IFrameSwitcherPauseBeforeSwitch}
                        IFrameSwitcherClickAndSwitch={this.state.IFrameSwitcherClickAndSwitch}
                        IFrameSwitcherSkipSwitchBack={this.state.IFrameSwitcherSkipSwitchBack}
                        IFrameSwitcherIncludeDynamicStepCount={this.state.IFrameSwitcherIncludeDynamicStepCount}
                        IFrameSwitcherExcludeExtraWindowCount={this.state.IFrameSwitcherExcludeExtraWindowCount}
                        IFrameSwitcherIgnoreFail={this.state.IFrameSwitcherIgnoreFail}
                        tempIframeSwitcher={this.state.tempIframeSwitcher}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.IFrameSwitcher}
                    />
                    <InfiniteScrollModal
                        show={this.state.infiniteScrollModalShow}
                        onHide={this.onShowModalFactory(false, this.state.InfiniteScroll)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        InfiniteScrollScrollScript={this.state.InfiniteScrollScrollScript}
                        InfiniteScrollScrollSection={this.state.InfiniteScrollScrollSection}
                        InfiniteScrollScrollToTopScript={this.state.InfiniteScrollScrollToTopScript}
                        InfiniteScrollWaitTimeBetweenScroll={this.state.InfiniteScrollWaitTimeBetweenScroll}
                        InfiniteScrollWaitUntilNotVisible={this.state.InfiniteScrollWaitUntilNotVisible}
                        InfiniteScrollItemsToHandlePerPage={this.state.InfiniteScrollItemsToHandlePerPage}
                        InfiniteScrollSkipFirstItems={this.state.InfiniteScrollSkipFirstItems}
                        InfiniteScrollOpenItemsInTab={this.state.InfiniteScrollOpenItemsInTab}
                        InfiniteScrollPrefillNewTabUrl={this.state.InfiniteScrollPrefillNewTabUrl}
                        InfiniteScrollSectionHeightScript={this.state.InfiniteScrollSectionHeightScript}
                        tempInfiniteScroll={this.state.tempInfiniteScroll}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.InfiniteScroll}
                    />
                    <ListSelectorConditionModal
                        show={this.state.listSelectorConditionModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ListSelectorCondition)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        ListSelectorConditionItemCounterGreaterThan={this.state.ListSelectorConditionItemCounterGreaterThan}
                        ListSelectorConditionGoRepeatStep={this.state.ListSelectorConditionGoRepeatStep}
                        ListSelectorConditionTextContentsContains={this.state.ListSelectorConditionTextContentsContains}
                        ListSelectorConditionTextContentsDoesNotContains={this.state.ListSelectorConditionTextContentsDoesNotContains}
                        ListSelectorConditionWaitUntilElementNotPresentXpath={this.state.ListSelectorConditionWaitUntilElementNotPresentXpath}
                        ListSelectorConditionWaitUntilElementNotPresentTimeout={this.state.ListSelectorConditionWaitUntilElementNotPresentTimeout}
                        ListSelectorConditionUserListedItemsMatchXpath={this.state.ListSelectorConditionUserListedItemsMatchXpath}
                        ListSelectorConditionCombineMultipleTextElement={this.state.ListSelectorConditionCombineMultipleTextElement}
                        tempListSelectorCondition={this.state.tempListSelectorCondition}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ListSelectorCondition}
                    />
                    <ElementConditionModal
                        show={this.state.elementConditionModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ElementCondition)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        ElementConditionUrlContains={this.state.ElementConditionUrlContains}
                        ElementConditionXpathElementExist={this.state.ElementConditionXpathElementExist}
                        ElementConditionValueDoesNotMatch={this.state.ElementConditionValueDoesNotMatch}
                        ElementConditionPreviousValueDoesNotMatch={this.state.ElementConditionPreviousValueDoesNotMatch}
                        ElementConditionAPIDataValues={this.state.ElementConditionAPIDataValues}
                        ElementConditionScripts={this.state.ElementConditionScripts}
                        ElementConditionSkipSteps={this.state.ElementConditionSkipSteps}
                        ElementConditionRepeatStepIfFail={this.state.ElementConditionRepeatStepIfFail}
                        ElementConditionSkipDataLibraryItem={this.state.ElementConditionSkipDataLibraryItem}
                        ElementConditionRemoveDynamicStep={this.state.ElementConditionRemoveDynamicStep}
                        ElementConditionContinueIfElementChanged={this.state.ElementConditionContinueIfElementChanged}
                        tempElementCondition={this.state.tempElementCondition}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ElementCondition}
                    />
                    <AddTimeoutWaitTimeModal
                        show={this.state.addTimeoutWaitModalShow}
                        onHide={this.onShowModalFactory(false, this.state.TimeoutWait)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        AddTimeoutWaitTime={this.state.AddTimeoutWaitTime}
                        tempTimeoutWaitTime={this.state.tempTimeoutWaitTime}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.TimeoutWait}
                    />
                    <AddDaysToCalendarModal
                        show={this.state.addDaysToCalendarModalShow}
                        onHide={this.onShowModalFactory(false, this.state.AddToCalendar)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        AddDaysToCalendar={this.state.AddDaysToCalendar}
                        tempAddDaysToCalendar={this.state.tempAddDaysToCalendar}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.AddToCalendar}
                    />
                    <StepTargetUTCStartTimeModal
                        show={this.state.stepTargetUtcStartTimeModalShow}
                        onHide={this.onShowModalFactory(false, this.state.StepTargetStartTime)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        StepTargetUTCStartTime={this.state.StepTargetUTCStartTime}
                        tempStepTargetStartTime={this.state.tempStepTargetStartTime}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.StepTargetStartTime}
                    />
                    <LurkBeforeModal
                        show={this.state.lurkBeforeModalShow}
                        onHide={this.onShowModalFactory(false, this.state.LurkBefore)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        LurkBefore={this.state.LurkBefore}
                        tempLurkBefore={this.state.tempLurkBefore}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.LurkBefore}
                    />
                    <LurkUntilModal
                        show={this.state.lurkUntilModalShow}
                        onHide={this.onShowModalFactory(false, this.state.LurkUntil)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        LurkUntil={this.state.LurkUntil}
                        tempLurkUntil={this.state.tempLurkUntil}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.LurkUntil}
                    />
                    <ClickAndLurkModal
                        show={this.state.clickAndLurkModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ClickAndLurk)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        ClickAndLurk={this.state.ClickAndLurk}
                        tempClickAndLurk={this.state.tempClickAndLurk}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ClickAndLurk}
                    />
                    <GoRepeatStepModal
                        show={this.state.goRepeatStepModalShow}
                        onHide={this.onShowModalFactory(false, this.state.GoRepeatStep)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        GoRepeatStep={this.state.GoRepeatStep}
                        tempGoRepeatStep={this.state.tempGoRepeatStep}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.GoRepeatStep}
                    />
                    <PauseBeforeActionModal
                        show={this.state.pauseBeforeActionModalShow}
                        onHide={this.onShowModalFactory(false, this.state.PauseBefore)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        PauseBeforeAction={this.state.PauseBeforeAction}
                        tempPauseBeforeAction={this.state.tempPauseBeforeAction}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.PauseBefore}
                    />
                    <PauseAfterActionModal
                        show={this.state.pauseAfterActionModalShow}
                        onHide={this.onShowModalFactory(false, this.state.PauseAfter)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        PauseAfterAction={this.state.PauseAfterAction}
                        tempPauseAfterAction={this.state.tempPauseAfterAction}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.PauseAfter}
                    />
                    <ScrollToBottomScriptModal
                        show={this.state.scrollToBottomScriptModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ScrollToBottomScript)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        ScrollToBottomScript={this.state.ScrollToBottomScript}
                        tempScrollToBottomScript={this.state.tempScrollToBottomScript}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ScrollToBottomScript}
                    />
                    <SetDataDateOnCalendarModal
                        show={this.state.setDataDateOnCalendarModalShow}
                        onHide={this.onShowModalFactory(false, this.state.SetDataDateOnCalendar)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        SetDataDateOnCalendar={this.state.SetDataDateOnCalendar}
                        tempSetDataDateOnCalendar={this.state.tempSetDataDateOnCalendar}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.SetDataDateOnCalendar}
                    />
                    <EmptyListLabelXpathModal
                        show={this.state.emptyListLabelXpathModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ModalEmptyListLabelXpath)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        EmptyListLabelXpath={this.state.EmptyListLabelXpath}
                        tempEmptyListLabelXpath={this.state.tempEmptyListLabelXpath}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ModalEmptyListLabelXpath}
                    />
                    <RedirectAfterUrlModal
                        show={this.state.redirectAfterUrlModalShow}
                        onHide={this.onShowModalFactory(false, this.state.RedirectAfter)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        RedirectAfterUrl={this.state.RedirectAfterUrl}
                        tempRedirectAfterUrl={this.state.tempRedirectAfterUrl}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.RedirectAfter}
                    />
                    <AddShadowHost
                        show={this.state.addShadowHostModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ShadowHostXpath)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        ShadowHostXpath={this.state.ShadowHostXpath}
                        tempShadowHostXpath={this.state.tempShadowHostXpath}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ShadowHostXpath}
                    />
                    <AddRandomValueOption
                        show={this.state.randomValueGeneratorModalShow}
                        onHide={this.onShowModalFactory(false, this.state.RandomValueGeneratorOption)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        RandomValueGeneratorOption={this.state.RandomValueGeneratorOption}
                        randomGeneratorOptions={this.state.randomGeneratorOptions}
                        randomValueOptionChange={this.randomValueOptionChange}
                        randomValueOption={this.state.randomValueOption}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.RandomValueGeneratorOption}
                    />
                    <SpreadSheetConfigurationModal
                        show={this.state.spreadSheetConfigurationModalShow}
                        onHide={this.onShowModalFactory(false, this.state.SpreadSheetConfiguration)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        SpreadSheetConfigurationSpreadSheetType={this.state.SpreadSheetConfigurationSpreadSheetType}
                        SpreadSheetConfigurationHtmlColumnReaderXpath={this.state.SpreadSheetConfigurationHtmlColumnReaderXpath}
                        SpreadSheetConfigurationHtmlAllRowsXpath={this.state.SpreadSheetConfigurationHtmlAllRowsXpath}
                        SpreadSheetConfigurationHtmlRowColumnValue={this.state.SpreadSheetConfigurationHtmlRowColumnValue}
                        SpreadSheetConfigurationSkipRows={this.state.SpreadSheetConfigurationSkipRows}
                        SpreadSheetConfigurationMergeColumns={this.state.SpreadSheetConfigurationMergeColumns}
                        SpreadSheetConfigurationMergeColumnsWithFormat={this.state.SpreadSheetConfigurationMergeColumnsWithFormat}
                        SpreadSheetConfigurationSetDelimiter={this.state.SpreadSheetConfigurationSetDelimiter}
                        SpreadSheetConfigurationColumnDelimiter={this.state.SpreadSheetConfigurationColumnDelimiter}
                        SpreadSheetConfigurationLocalHtmlHtmlPath={this.state.SpreadSheetConfigurationLocalHtmlHtmlPath}
                        SpreadSheetConfigurationAcceptRowColumns={this.state.SpreadSheetConfigurationAcceptRowColumns}
                        SpreadSheetConfigurationAcceptRowWithColumnValues={this.state.SpreadSheetConfigurationAcceptRowWithColumnValues}
                        SpreadSheetConfigurationRefreshPageAfterRecord={this.state.SpreadSheetConfigurationRefreshPageAfterRecord}
                        SpreadSheetConfigurationDistinctBy={this.state.SpreadSheetConfigurationDistinctBy}
                        SpreadSheetConfigurationEmptyColumns={this.state.SpreadSheetConfigurationEmptyColumns}
                        SpreadSheetConfigurationEmptyColumnValues={this.state.SpreadSheetConfigurationEmptyColumnValues}
                        SpreadSheetConfigurationOECellNavigatorXpath={this.state.SpreadSheetConfigurationOECellNavigatorXpath}
                        SpreadSheetConfigurationOEColumnSearchSkip={this.state.SpreadSheetConfigurationOEColumnSearchSkip}
                        SpreadSheetConfigurationOEFormulaInputXpath={this.state.SpreadSheetConfigurationOEFormulaInputXpath}
                        SpreadSheetConfigurationOEStartingCell={this.state.SpreadSheetConfigurationOEStartingCell}
                        SpreadSheetConfigurationOEColumnValue={this.state.SpreadSheetConfigurationOEColumnValue}
                        SpreadSheetConfigurationOERowValue={this.state.SpreadSheetConfigurationOERowValue}
                        SpreadSheetConfigurationOECellValues={this.state.SpreadSheetConfigurationOECellValues}
                        SpreadSheetConfigurationOECellValueMustBeEmpty={this.state.SpreadSheetConfigurationOECellValueMustBeEmpty}
                        SpreadSheetConfigurationOEStartWithColumn={this.state.SpreadSheetConfigurationOEStartWithColumn}
                        SpreadSheetConfigurationOERowCellSearchValueStartsWith={this.state.SpreadSheetConfigurationOERowCellSearchValueStartsWith}
                        SpreadSheetConfigurationOEColumnCellSearchValueStartsWith={this.state.SpreadSheetConfigurationOEColumnCellSearchValueStartsWith}
                        SpreadSheetConfigurationOEPreventDuplicateEntry={this.state.SpreadSheetConfigurationOEPreventDuplicateEntry}
                        SpreadSheetConfigurationOERepeatSpreadSheetEntry={this.state.SpreadSheetConfigurationOERepeatSpreadSheetEntry}
                        SpreadSheetConfigurationOEType={this.state.SpreadSheetConfigurationOEType}
                        spreadSheetTypeOptions={this.state.spreadSheetTypeOptions}
                        oeTypeOptions={this.state.oeTypeOptions}
                        tempSpreadSheetConfiguration={this.state.tempSpreadSheetConfiguration}
                        randomValueOption={this.state.randomValueOption}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.SpreadSheetConfiguration}
                    />
                    <TextFormatterModal
                        show={this.state.textFormatterModalShow}
                        onHide={this.onShowModalFactory(false, this.state.TextFormatter)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        TextFormatterFindText={this.state.TextFormatterFindText}
                        TextFormatterReplaceText={this.state.TextFormatterReplaceText}
                        TextFormatterReplaceTextRegularExpression={this.state.TextFormatterReplaceTextRegularExpression}
                        TextFormatterPlaceBrackets={this.state.TextFormatterPlaceBrackets}
                        TextFormatterConvertToClosesTuesday={this.state.TextFormatterConvertToClosesTuesday}
                        TextFormatterCalculateByCompleteDays={this.state.TextFormatterCalculateByCompleteDays}
                        TextFormatterPlaceParentheses={this.state.TextFormatterPlaceParentheses}
                        TextFormatterDateConverter={this.state.TextFormatterDateConverter}
                        TextFormatterCapitalizeAll={this.state.TextFormatterCapitalizeAll}
                        TextFormatterLowercaseAll={this.state.TextFormatterLowercaseAll}
                        TextFormatterSplitWithDelimiter={this.state.TextFormatterSplitWithDelimiter}
                        TextFormatterAddDaysToCalendar={this.state.TextFormatterAddDaysToCalendar}
                        TextFormatterTruncate={this.state.TextFormatterTruncate}
                        TextFormatterPostFill={this.state.TextFormatterPostFill}
                        TextFormatterPrefill={this.state.TextFormatterPrefill}
                        TextFormatterPrefillWithExceptions={this.state.TextFormatterPrefillWithExceptions}
                        TextFormatterPreFillExceptionAction={this.state.TextFormatterPreFillExceptionAction}
                        textFormatterPreFillExceptionActionOptions={this.state.textFormatterPreFillExceptionActionOptions}
                        TextFormatterRemoveMiddleValue={this.state.TextFormatterRemoveMiddleValue}
                        TextFormatterSwapWithBusinessEliteInterpreters={this.state.TextFormatterSwapWithBusinessEliteInterpreters}
                        TextFormatterWeekOfMonth={this.state.TextFormatterWeekOfMonth}
                        TextFormatterYearOnly={this.state.TextFormatterYearOnly}
                        TextFormatterSkipBusinessEliteInterpreters={this.state.TextFormatterSkipBusinessEliteInterpreters}
                        TextFormatterDateDifference={this.state.TextFormatterDateDifference}
                        TextFormatterDivideValues={this.state.TextFormatterDivideValues}
                        TextFormatterAddDataValuesFromDateDifference={this.state.TextFormatterAddDataValuesFromDateDifference}
                        tempTextFormatter={this.state.tempTextFormatter}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.TextFormatter}
                    />
                    <DownloadModal
                        show={this.state.downloadModalShow}
                        onHide={this.onShowModalFactory(false, this.state.Download)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        DownloadDownloadType={this.state.DownloadDownloadType}
                        DownloadStore={this.state.DownloadStore}
                        DownloadExtractStore={this.state.DownloadExtractStore}
                        DownloadExtractLink={this.state.DownloadExtractLink}
                        DownloadExtractLinkStartsWith={this.state.DownloadExtractLinkStartsWith}
                        DownloadExtractLinkEndsWith={this.state.DownloadExtractLinkEndsWith}
                        DownloadExtractFileName={this.state.DownloadExtractFileName}
                        DownloadExtractFileNameStartsWith={this.state.DownloadExtractFileNameStartsWith}
                        DownloadExtractFileNameEndsWith={this.state.DownloadExtractFileNameEndsWith}
                        DownloadDirectory={this.state.DownloadDirectory}
                        DownloadWaitTime={this.state.DownloadWaitTime}
                        DownloadFileType={this.state.DownloadFileType}
                        downloadTypeOptions={this.state.downloadTypeOptions}
                        downloadTypeOptionChange={this.downloadTypeOptionChange}
                        tempDownload={this.state.tempDownload}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.Download}
                    />
                    <UploadModal
                        show={this.state.uploadModalShow}
                        onHide={this.onShowModalFactory(false, this.state.Upload)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        UploadUploadStoredFiles={this.state.UploadUploadStoredFiles}
                        UploadWaitTime={this.state.UploadWaitTime}
                        tempUpload={this.state.tempUpload}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.Upload}
                    />
                    <DynamicStepCreatorModal
                        show={this.state.dynamicStepCreatorModalShow}
                        onHide={this.onShowModalFactory(false, this.state.DynamicStepCreator)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        DynamicStepCreatorPlacementStep={this.state.DynamicStepCreatorPlacementStep}
                        DynamicStepCreatorStartRange={this.state.DynamicStepCreatorStartRange}
                        DynamicStepCreatorFinishRange={this.state.DynamicStepCreatorFinishRange}
                        DynamicStepCreatorRules={this.state.DynamicStepCreatorRules}
                        DynamicStepCreatorDataCreationIterator={this.state.DynamicStepCreatorDataCreationIterator}
                        tempDynamicStepCreator={this.state.tempDynamicStepCreator}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.DynamicStepCreator}
                    />
                    <APIRequestModal
                        show={this.state.apiRequestModalShow}
                        onHide={this.onShowModalFactory(false, this.state.APIRequest)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        APIRequestAPIHttpRequestType={this.state.APIRequestAPIHttpRequestType}
                        APIRequestAPIHttpResponseType={this.state.APIRequestAPIHttpResponseType}
                        APIRequestAPIExternalAPIType={this.state.APIRequestAPIExternalAPIType}
                        APIRequestEndPoint={this.state.APIRequestEndPoint}
                        APIRequestPayloadData={this.state.APIRequestPayloadData}
                        APIRequestReplaceRequestKeys={this.state.APIRequestReplaceRequestKeys}
                        APIRequestReplaceRequestValues={this.state.APIRequestReplaceRequestValues}
                        APIRequestRequestContainsTimeStamp={this.state.APIRequestRequestContainsTimeStamp}
                        APIRequestEncodeRequestNameValue={this.state.APIRequestEncodeRequestNameValue}
                        APIRequestEncodeRequestURLValue={this.state.APIRequestEncodeRequestURLValue}
                        APIRequestRemoveDomainProtocols={this.state.APIRequestRemoveDomainProtocols}
                        APIRequestRequestNewResultsByID={this.state.APIRequestRequestNewResultsByID}
                        APIRequestRequestReverseResults={this.state.APIRequestRequestReverseResults}
                        APIRequestMessageAllAPIResponses={this.state.APIRequestMessageAllAPIResponses}
                        APIRequestStoreResponseValues={this.state.APIRequestStoreResponseValues}
                        APIRequestMergeResults={this.state.APIRequestMergeResults}
                        APIRequestGetArrayFromRootObject={this.state.APIRequestGetArrayFromRootObject}
                        APIRequestMergePropertiesWithFormat={this.state.APIRequestMergePropertiesWithFormat}
                        APIRequestSetDelimiter={this.state.APIRequestSetDelimiter}
                        APIRequestPropertyDelimiter={this.state.APIRequestPropertyDelimiter}
                        APIRequestRootIgnores={this.state.APIRequestRootIgnores}
                        APIRequestCatchAllItems={this.state.APIRequestCatchAllItems}
                        APIRequestIgnoreAllItems={this.state.APIRequestIgnoreAllItems}
                        APIRequestGroupResultsBy={this.state.APIRequestGroupResultsBy}
                        APIRequestGroupByTargetProperty={this.state.APIRequestGroupByTargetProperty}
                        APIRequestGroupMergeName={this.state.APIRequestGroupMergeName}
                        APIRequestGroupMergeValue={this.state.APIRequestGroupMergeValue}
                        APIRequestChangeStep={this.state.APIRequestChangeStep}
                        APIRequestSkipToStep={this.state.APIRequestSkipToStep}
                        APIRequestGoToStepAfterComplete={this.state.APIRequestGoToStepAfterComplete}
                        apiHttpRequestTypeOptions={this.state.apiHttpRequestTypeOptions}
                        apiHttpResponseTypeOptions={this.state.apiHttpResponseTypeOptions}
                        externalAPITypeOptions={this.state.externalAPITypeOptions}
                        tempAPIRequest={this.state.tempAPIRequest}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.APIRequest}
                    />
                    <TwoClickSelectorModal
                        show={this.state.twoClickSelectorModalShow}
                        onHide={this.onShowModalFactory(false, this.state.TwoClickSelect)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        TwoClickSelector={this.state.TwoClickSelector}
                        tempTwoClickSelector={this.state.tempTwoClickSelector}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.TwoClickSelect}
                    />
                    <TabSplitModal
                        show={this.state.tabSplitModalShow}
                        onHide={this.onShowModalFactory(false, this.state.TabSplit)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        TabSplit={this.state.TabSplit}
                        tempTabSplit={this.state.tempTabSplit}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.TabSplit}
                    />
                    <TwoFormAuthenticationModal
                        show={this.state.twoFormAuthenticationModalShow}
                        onHide={this.onShowModalFactory(false, this.state.TwoFormAuthentication)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        TwoFormAuthenticationType={this.state.TwoFormAuthenticationType}
                        TwoFormAuthenticationAcceptPath={this.state.TwoFormAuthenticationAcceptPath}
                        TwoFormAuthenticationEmailConfirmationInstruction={this.state.TwoFormAuthenticationEmailConfirmationInstruction}
                        TwoFormAuthenticationRequireAcceptClick={this.state.TwoFormAuthenticationRequireAcceptClick}
                        tempTwoFormAuthentication={this.state.tempTwoFormAuthentication}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.TwoFormAuthentication}
                    />
                    <ClickLabelHelperModal
                        show={this.state.clickLabelHelperModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ClickLabelHelper)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        showDeleteElementButton={this.state.showDeleteElementButton}
                        deleteElement={this.deleteElement}
                        ClickLabelHelper={this.state.ClickLabelHelper}
                        tempClickLabelHelper={this.state.tempClickLabelHelper}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ClickLabelHelper}
                    />
                    <ClickActionMultiplierCountModal
                        show={this.state.clickActionMultiplierCountModalShow}
                        onHide={this.onShowModalFactory(false, this.state.ClickActionMultiplierCount)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        ClickActionMultiplierCount={this.state.ClickActionMultiplierCount}
                        tempClickActionMultiplierCount={this.state.tempClickActionMultiplierCount}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.ClickActionMultiplierCount}
                    />
                    <APIDataLabelClickModal
                        show={this.state.apiDataLabelClickModalShow}
                        onHide={this.onShowModalFactory(false, this.state.APIDataLabelClick)}
                        currentHtmlControlIndex={this.state.currentHtmlControlIndex}
                        currenInnerHtmlControl={this.state.currenInnerHtmlControl}
                        htmlData={this.state.htmlData}
                        APIDataLabelClick={this.state.APIDataLabelClick}
                        tempAPIDataLabelClick={this.state.tempAPIDataLabelClick}
                        onDataChange={this.onDataChange}
                        onSaveModalData={this.onSaveModalData}
                        modalName={this.state.APIDataLabelClick}
                    />
                    <ShareBotModal
                        show={this.state.showSharedBotModal}
                        onHide={() => this.onShowShareBotModal(false)}
                        masterBotId={this.state.masterBotId}
                        mainBotDuplicateName={this.state.mainBotDuplicateName}
                    />
                    <PublicShareBotModal
                        show={this.state.publicShareModalShow}
                        onHide={() => this.onShowPublicShareModal(false)}
                        botName={this.state.htmlData.botName}
                        shareUrl={this.state.publicShareUrl}
                        copyButtonText={this.state.publicShareCopyButtonText}
                        onCopyButtonClick={this.onCopyButtonClick}
                    />
                    <CreateDuplicateBot
                        show={this.state.showConfirmDuplicateModal}
                        onHide={() => this.onShowConfirmDuplicateModal(false)}
                        oGId={this.state.botId}
                        userId={this.state.htmlData.userId}
                        updateBotDataFromDuplicate={this.updateBotDataFromDuplicate}
                        mainBotDuplicateName={this.state.mainBotDuplicateName}
                    />
                    <AssignBotModal
                        show={this.state.showAssignBotModal}
                        onHide={() => this.onShowAssignBotModal(false)}
                        engineers={this.state.engineers}
                        tiers={this.state.tiers}
                        selectedEngineer={this.state.selectedEngineer}
                        assignMessage={this.state.assignMessage}
                        assignDueDate={this.state.assignDueDate}
                        selectedTier={this.state.selectedTier}
                        onSaveAssignTask={this.onSaveAssignTask}
                        onAssignBotMessageChange={this.onAssignBotMessageChange}
                        onAssignBotDueDate={this.onAssignBotDueDate}
                        assignToClick={this.assignToClick}
                        botTierClick={this.botTierClick}
                        disableSaveButton={this.state.disableSaveButton}
                        hideSuccessLabel={this.state.hideSuccessLabel}
                        onAssignItemClick={this.onAssignItemClick}
                    />
                    <CopyConfigModal
                        show={this.state.copyConfigModalShow}
                        onHide={() => this.onShowCopyConfigModal(false)}
                        isMigrating={this.state.isMigrating}
                        copyConfigOriginalBotIdChange={this.copyConfigOriginalBotIdChange}
                        configOriginalBotId={this.state.configOriginalBotId}
                        startMigration={this.startMigration}
                        miniBotListChange={this.miniBotListChange}
                        createMiniBotNameChange={this.createMiniBotNameChange}
                        miniBotNotesChange={this.miniBotNotesChange}
                        changeMiniBotName={this.changeMiniBotName}
                        miniBotTypes={this.state.miniBotTypes}
                        createMiniBot={this.state.createMiniBot}
                        miniBotNotes={this.state.miniBotNotes}
                        miniSearchType={this.state.miniSearchType}
                    />
                    <LiveBotRunView
                        show={this.state.liveBotViewModalShow}
                        onHide={() => this.onShowLiveBotViewModal(false)}
                        botName={this.state.htmlData.botName}
                        fullscreen={true}
                        runningBotUrl={this.state.runningBotUrl}
                    />
                </div>) : (<div>
                    <h3>Loading...</h3>
                </div>)
                }
            </Container>
        )
    }
}