import axios from 'axios';
import config from '../config';

var qs = require('qs');

class ApiClient {

    get(url, params) {
        return axios.get(config.api + url, {
            params: params,
            headers: { 'Authorization': "Bearer " + (localStorage.getItem("token")) }
        });
    }
    getGoogleUserData(token) {
        return axios.get("https://people.googleapis.com/v1/people/me?personFields=ageRanges", {
            headers: { 'Authorization': "Bearer " + token }
        });
    }
    post(url, params) {
        return axios.post(config.api + url, params, {
            headers: {
                'Content-Type': 'application/json',
                /*Eample TOKEN USE THE ONE FROM LOGIN OR REGISTER*/
                'Authorization': "Bearer " + (localStorage.getItem("token"))
            }
        });
    }
    postExternal(url, params) {
        return axios.post(url, params, {
            headers: {
            }
        });
    }

    postUrlEncoded(url, params) {
        return axios.post(config.api + url, qs.stringify(params), {
            headers: {
                'Content-Type': 'Application/x-www-form-urlencoded',
            }
        });
    }



}

const api = new ApiClient();
export default api;